import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import FaqSection from '../components/FaqSection';
import SocialMedia from '../components/SocialMedia';


const FaqPage = () => {

  const pageTitle = 'Sıkça Sorulan Sorular - Konserbilet.com';
  const pageDescription = 'Konserbilet hakkında en sık sorulan soruları bu sayfada bulabilirsiniz.';
  const canonicalUrl = 'https://konserbilet.com/SSS'; 
  const imageUrl = 'https://konserbilet.s3.eu-central-1.amazonaws.com/faq.png';
  const customFaqs = [
    {
      question: "1. Hangi şehirlerde konserler düzenleniyor?",
      answer: "Konserbilet.com, İstanbul, Ankara, İzmir, Adana, Samsun ve daha birçok şehirde düzenlenen konserlere dair bilgiler sunuyor. Şehirler arası konserleri kolayca keşfedebilirsiniz."
    },
    {
      question: "2. İstediğim sanatçının konserini nasıl bulabilirim?",
      answer: "Merak etmeyin! Sitemizde sanatçılara göre arama yapabilir ve en sevdiğiniz sanatçının yaklaşan konserlerini hızlıca bulabilirsiniz."
    },
    {
      question: "3. Sahne ayarları hakkında bilgi alabilir miyim?",
      answer: "Elbette, her konserin sahne düzeni farklı olabilir. Konser öncesinde sahne ayarları hakkında detaylı bilgiyi bulabilirsiniz."
    },
    {
      question: "4. Konser sırasında yemek ve içecek servisi var mı?",
      answer: "Evet, çoğu mekanda yemek ve içecek servisi bulunur. Lezzetli atıştırmalıklar ve serinletici içecekler sizi bekliyor olacak!"
    },
    {
      question: "5. Konser mekanına nasıl ulaşabilirim?",
      answer: "Mekana ulaşım oldukça kolay! Toplu taşıma araçlarını kullanabilir veya özel etkinlikler için servis seçeneklerinden faydalanabilirsiniz."
    },
    {
      question: "6. Engelli erişimi mevcut mu?",
      answer: "Kesinlikle! Konser mekanları engelli dostu olup, engelli bireyler için uygun erişim imkanları sunar."
    },
    {
      question: "7. Bilet iadesi nasıl yapılır?",
      answer: "Bilet iadesi ile ilgili detaylı bilgiye sitemizden veya bilet satın aldığınız platformdan ulaşabilirsiniz. Unutmayın, iade politikaları etkinlikten etkinliğe farklılık gösterebilir."
    },
    {
      question: "8. Etkinlik günü hava durumu nasıl kontrol edilir?",
      answer: "Hava durumu değişken olabilir. Etkinlik günü hava tahminlerini takip etmek için hava durumu uygulamalarını veya web sitelerini kullanabilirsiniz."
    },
    {
      question: "9. Konser sırasında ne giymeliyim?",
      answer: "Rahat giyim her zaman en iyisidir! Hava koşullarına göre giyinmeyi unutmayın ve müziğin keyfini çıkarın."
    },
    {
      question: "10. Konserbilet.com ile iletişime nasıl geçebilirim?",
      answer: "Bize 7/24 ulaşabilirsiniz! İletişim bilgilerimizi sitemizden bulabilir veya sosyal medya hesaplarımızı takip edebilirsiniz. Size yardımcı olmaktan mutluluk duyarız!"
    },
  ];


  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={imageUrl} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>
      <Header />

      <section className="bg-gradient-to-r from-purple-700 to-indigo-700 py-12">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-5xl text-white font-bold mb-4">Sıkça Sorulan Sorular</h1>
          <p className="text-lg md:text-xl text-white max-w-2xl mx-auto">
            Konserbilet hakkında en sık sorulan soruları bu sayfada bulabilirsiniz.
          </p>
        </div>
                  <FaqSection faqs={customFaqs} />
      <div>      <SocialMedia />   </div>
      </section>

      <Footer />
    </div>
  );
};

export default FaqPage;
