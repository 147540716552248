import React, { useState } from 'react';
import { motion } from 'framer-motion';


const ScenesSection = () => {
  const ctaDescription =
    'Yaşadığın şehrin konser biletlerini tek bir sayfada görüntüle. Konserbilet ile Yakınında yer alan Mekanlar hakkında bilgileri keşfet. Semtindeki konserlerden haberdar ol.';
  const ctaVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };
  const [selectedCity, setSelectedCity] = useState('');
  const [searchText, setSearchText] = useState(''); 

  const scenes = [
    {
      id: 1,
      image: "https://cdn.konserbilet.com/assets/venue/jollyjokerkartal.png.webp",
      title: "Jolly Joker Kartal Konserleri",
      description: "İstanbul'un en popüler mekanlarından Jolly Joker Kartal'da eşsiz konserlere tanık olun.",
      city: "İstanbul",
      link: "/istanbul/jolly-joker-kartal",
    },
    {
      id: 2,
      image: "https://cdn.konserbilet.com/assets/venue/harbiye.png",
      title: "Harbiye Açıkhava Konserleri",
      description: "İstanbul'un tarihi konser mekanında harika açık hava konserlerini kaçırmayın.",
      city: "İstanbul",
      link: "/istanbul/harbiye-acikhava",
    },
    {
      id: 3,
      image: "https://cdn.konserbilet.com/assets/venue/milyonperformancehall.png.webp",
      title: "Milyon Performance Hall Konserleri",
      description: "Ankara'nın kalbinde eşsiz konserlere ev sahipliği yapan mekan.",
      city: "Ankara",
      link: "/ankara/milyon-performance-hall",
    },
    {
      id: 4,
      image: "https://cdn.konserbilet.com/assets/venue/645kkkavaklidere.png.webp",
      title: "645 K Kavaklıdere Konserleri",
      description: "Ankara'da müziğin ritmine kapılacağınız eşsiz bir mekan.",
      city: "Ankara",
      link: "/ankara/645kk-kavaklidere",
    },
    {
      id: 5,
      image: "https://cdn.konserbilet.com/assets/venue/odtumdvisnelik.png.webp",
      title: "ODTÜ MD Vişnelik Konserleri",
      description: "Ankara'nın en köklü mekanlarından birinde müziğin tadını çıkarın.",
      city: "Ankara",
      link: "/ankara/odtu-md-visnelik",
    },
    {
      id: 6,
      image: "https://cdn.konserbilet.com/assets/venue/ifperformancehallkavaklidere.png.webp",
      title: "IF Armada AVM Açıkhava Konserleri",
      description: "Başkentin en popüler mekanlarında eşsiz konser deneyimleri.",
      city: "Ankara",
      link: "/ankara/if-armada-avm-acikhava",
    },
    {
      id: 7,
      image: "https://cdn.konserbilet.com/assets/venue/turkcellvadi.png.webp",
      title: "Turkcell Vadi İstanbul Açık Hava Sahnesi Konserleri",
      description: "İstanbul'un yeni ve popüler konser mekanında unutulmaz anlar.",
      city: "İstanbul",
      link: "/istanbul/turkcell-vadi",
    },
    {
      id: 8,
      image: "https://cdn.konserbilet.com/assets/venue/maksimumuniq.png",
      title: "Maksimum Uniq Açık Hava Sahnesi Konserleri",
      description: "İstanbul'da yaz aylarının vazgeçilmez mekanında eşsiz konserler.",
      city: "İstanbul",
      link: "/istanbul/maksimum-uniq",
    },
    {
      id: 9,
      image: "https://cdn.konserbilet.com/assets/venue/kurucesmeparaf.png",
      title: "Kuruçeşme Paraf Açık Hava Sahnesi Konserleri",
      description: "Boğazın kenarında müziğin büyüsüne kapılın.",
      city: "İstanbul",
      link: "/istanbul/kurucesme-paraf",
    },
    {
      id: 10,
      image: "https://cdn.konserbilet.com/assets/venue/ifperformancehallbesiktas.png",
      title: "IF Performancehall Beşiktaş Sahnesi Konserleri",
      description: "İstanbul'un kalbinde, Beşiktaş'ta unutulmaz konserler.",
      city: "İstanbul",
      link: "/istanbul/if-performance-hall-besiktas",
    },
    {
      id: 11,
      image: "https://cdn.konserbilet.com/assets/venue/zorlupsm.png.webp",
      title: "Zorlu PSM Turkcell Sahnesi Konserleri",
      description: "Modern sanatların merkezi Zorlu'da eşsiz konser deneyimleri.",
      city: "İstanbul",
      link: "/istanbul/zorlu-psm-turkcell",
    },
    {
      id: 12,
      image: "https://cdn.konserbilet.com/assets/venue/cesmeacikhavatiyatrosu.png.webp",
      title: "Çeşme Açıkhava Tiyatrosu Konserleri",
      description: "İzmir'in en güzel koylarından Çeşme'de harika konserler.",
      city: "İzmir",
      link: "/izmir/cesme-acikhava-tiyatrosu",
    },
    {
      id: 13,
      image: "https://cdn.konserbilet.com/assets/venue/jollyjokeralacati.png.webp",
      title: "Jolly Joker Alaçatı Konserleri",
      description: "Alaçatı'nın hareketli gecelerinde eşsiz konserlere tanık olun.",
      city: "İzmir",
      link: "/izmir/jolly-joker-alacati",
    },
    {
      id: 14,
      image: "https://cdn.konserbilet.com/assets/venue/manodelsolcesme.png.webp",
      title: "Mano Del Sol Çeşme Konserleri",
      description: "Çeşme'nin en gözde mekanlarından Mano Del Sol'da unutulmaz geceler.",
      city: "İzmir",
      link: "/izmir/mano-del-sol-cesme",
    },
    {
      id: 15,
      image: "https://cdn.konserbilet.com/assets/venue/izmirarena.png.webp",
      title: "İzmir Arena Konserleri",
      description: "İzmir'in en büyük konser alanında harika etkinliklere katılın.",
      city: "İzmir",
      link: "/izmir/izmir-arena",
    },
    {
      id: 16,
      image: "https://cdn.konserbilet.com/assets/venue/hayalkahvesialacati.png.webp",
      title: "Hayal Kahvesi Alaçatı Konserleri",
      description: "Alaçatı'nın bohem atmosferinde eşsiz konserler.",
      city: "İzmir",
      link: "/izmir/hayal-kahvesi-alacati",
    }
];

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const handleCityFilter = (city) => {
    setSelectedCity(city);
  };

  const filteredScenes = scenes.filter((scene) => {
    const lowerCaseSearchText = searchText.toLowerCase();
    const isCityMatch = selectedCity ? scene.city === selectedCity : true; // Şehir filtresi
    const isTitleMatch = scene.title.toLowerCase().includes(lowerCaseSearchText); // Başlık araması

    return isCityMatch && (isTitleMatch || searchText === ''); // Her iki koşulu kontrol edin
  });





return (
  <section className="py-4 bg-gray-100">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl md:text-4xl text-center font-bold mb-8">Sahneler</h2>
      <motion.p
        initial="hidden"
        animate="visible"
        variants={ctaVariants}
        className="text-gray-700 text-center mb-4"
      >
        {ctaDescription}
      </motion.p>

      <div className="flex justify-center mb-8">

            {/* Arama çubuğu */}
            <input
              type="text"
              placeholder="Sahne yeri ara..."
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              className="p-2 rounded-lg border border-gray-300"
            />
      </div>
      <div className="flex justify-center mb-8">
        
      <div className="flex flex-wrap gap-6">



        <button
  onClick={() => handleCityFilter('')}
  className={`p-2 rounded-lg transition-all duration-300 ease-in-out ${
    selectedCity === '' ? 'bg-purple-800 text-white transform scale-110' : 'bg-purple-500 text-white hover:scale-105'
  }`}
>
  Tüm Şehirler
</button>
<button
  onClick={() => handleCityFilter('İstanbul')}
  className={`p-2 rounded-lg transition-all duration-300 ease-in-out ${
    selectedCity === 'İstanbul' ? 'bg-purple-800 text-white transform scale-110' : 'bg-purple-500 text-white hover:scale-105'
  }`}
>
  İstanbul
</button>
<button
  onClick={() => handleCityFilter('Ankara')}
  className={`p-2 rounded-lg transition-all duration-300 ease-in-out ${
    selectedCity === 'Ankara' ? 'bg-purple-800 text-white transform scale-110' : 'bg-purple-500 text-white hover:scale-105'
  }`}
>
  Ankara
</button>
<button
  onClick={() => handleCityFilter('İzmir')}
  className={`p-2 rounded-lg transition-all duration-300 ease-in-out ${
    selectedCity === 'İzmir' ? 'bg-purple-800 text-white transform scale-110' : 'bg-purple-500 text-white hover:scale-105'
  }`}
>
  İzmir
</button>

<button
  onClick={() => handleCityFilter('Samsun')}
  className={`p-2 rounded-lg transition-all duration-300 ease-in-out ${
    selectedCity === 'Samsun' ? 'bg-purple-800 text-white transform scale-110' : 'bg-purple-500 text-white hover:scale-105'
  }`}
>
  Samsun
</button>

<button
  onClick={() => handleCityFilter('Edirne')}
  className={`p-2 rounded-lg transition-all duration-300 ease-in-out ${
    selectedCity === 'Edirne' ? 'bg-purple-800 text-white transform scale-110' : 'bg-purple-500 text-white hover:scale-105'
  }`}
>
  Edirne
</button>

<button
  onClick={() => handleCityFilter('Adana')}
  className={`p-2 rounded-lg transition-all duration-300 ease-in-out ${
    selectedCity === 'Adana' ? 'bg-purple-800 text-white transform scale-110' : 'bg-purple-500 text-white hover:scale-105'
  }`}
>
  Adana
</button>

<button
  onClick={() => handleCityFilter('Denizli')}
  className={`p-2 rounded-lg transition-all duration-300 ease-in-out ${
    selectedCity === 'Denizli' ? 'bg-purple-800 text-white transform scale-110' : 'bg-purple-500 text-white hover:scale-105'
  }`}
>
  Denizli
</button>



        </div>
      </div>
      <div className="grid grid-cols-2 gap-6 sm:grid-cols-2 lg:grid-cols-6">
          {filteredScenes.map((scene) => (
            <div key={scene.id} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <a href={scene.link}>
                <img
                  src={scene.image}
                  alt={scene.title}
                  className="w-full h-48 object-cover object-center"
                />
              </a>
              <div className="p-4">
                <h3 className="text-lg md:text-xl font-bold mb-2">
                  <a href={scene.link} className="text-purple-500 hover:text-purple-600">
                    {scene.title}
                  </a>
                </h3>
                <p className="text-gray-600 mb-4">{scene.description}</p>
                <a
                  href={scene.link}
                  className="bg-purple-500 hover:bg-purple-800 text-white p-2 rounded-lg transition-colors duration-300 ease-in-out"
                >
                  Daha Fazlası
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ScenesSection;