import React from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ScrollIndicator from '../../components/ScrollIndicator';
import BlogSection1 from '../../components/BlogSection1';
import FaqSection from '../../components/FaqSection';

const BlogPostPage = () => {
  const pageTitle = 'Coldplay Grubu ve Konserleri Hakkında - KonserBilet';
  const pageDescription = '';
  const canonicalUrl = 'https://konserbilet.com/blog/coldplay-grubu-ve-konserleri-hakkinda';
  const imageUrl = 'https://cdn.konserbilet.com/assets/blogs/coldplay.webp';
  const author = 'Melike Aydınsoy';
  const publishDate = '18 Eylül 2023';
  const tableOfContents = [
    { id: 'id1', title: "Bir Rüyanın Başlangıcı: Coldplay'in Doğuşu" },
    { id: 'id2', title: 'Neden grubun adı Coldplay?' },
    { id: 'id3', title: "Coldplay ve Grammy Macerası" },
    { id: 'id4', title: "İşte Coldplay Konserlerinin Büyüsü! Neden Coldplay Konserleri Bu Kadar Popüler?" },
  ];

  const customFaqs = [
    {
      question: "Coldplay'in en popüler albümü nedir?",
      answer: 'Coldplay\'in en popüler albümlerinden biri "A Rush of Blood to the Head"tir. Bu albüm, 2002 yılında piyasaya sürülmüş ve birçok ödül kazanmıştır.'
    },
    {
      question: "Coldplay'in kuruluş yılı nedir?",
      answer: "Coldplay, 1996 yılında İngiltere'de kurulmuştur."
    },
    {
      question: "Coldplay'in en hit şarkısı nedir?",
      answer: 'Coldplay\'in birçok hit şarkısı bulunmaktadır, ancak "Viva la Vida" genellikle en tanınmış ve en popüler şarkılarından biri olarak kabul edilir.'
    },
    {
      question: "Coldplay'in son albümü nedir?",
      answer: 'Coldplay\'in son albümü, bilgimin kesildiği tarihe kadar "Music of the Spheres"dir ve 2021 yılında piyasaya sürülmüştür.'
    },
    {
      question: "Coldplay'in ana vokalisti kimdir?",
      answer: "Coldplay'in ana vokalisti Chris Martin'dir."
    }
];

  const sectionTitle = "Coldplay Hakkında";


  
  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Header />
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-3xl md:text-4xl font-bold mb-4">Coldplay Konserleri Neden Bu Kadar Özel? </h1>
            <p className="text-gray-500 text-sm mb-4"> {author} tarafından {publishDate} tarihinde yayınlandı</p>
            <img src={imageUrl} alt={pageTitle} className="w-full h-auto mb-8" />

            <div className="row">
              <div className="w-5/5 pr-8">
                <div className="w-5/5">
                  <h2 className="text-lg font-bold mb-4">İçindekiler</h2>
                  <ul className="space-y-2">
                    {tableOfContents.map((item) => (
                      <li key={item.id}>
<ScrollLink to={item.id} smooth={true} duration={500} offset={-20} className="text-purple-500 hover:text-purple-600">
  {item.title}
</ScrollLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="container mx-auto py-8">
              <div className="prose max-w-none">
                <p>Selam sevgili Konserbilet.com okurları! Bugün, son zamanlarda popüler olan Coldplay grubundan bahsedeceğim: Coldplay grubu konserlerinin son zamanlarda neden bu kadar popüler olduğu hakkında konuşacağız. Evet doğru duydunuz! Neden Coldplay konserleri bu kadar popüler? Bu sorunun cevabını öğrenmek için okumaya devam edin!</p>

                <h2 className="text-2xl font-bold mt-8" id="id1">Bir Rüyanın Başlangıcı: Coldplay'in Doğuşu</h2> <br></br>
                <p>Coldplay, müziğin büyülü bir rüya olduğunun kanıtıdır. Grup, 1996 yılında İngiliz üniversitesi arkadaşları Chris Martin (vokal, piyano) ve Jonny Buckland (gitar) tarafından kuruldu ve o günden bu yana müzik dünyasında büyük bir etki yarattı.. İkinci yıl öğrencisiyken tanışan bu iki müzik tutkunu genç adam, bir müzik grubu kurma hayaliyle yola çıktı.
</p>
<br></br>
<p>Neden kuruldu, derseniz, cevap basit: Müzik sevgisi ve ifade etme arzusu. Chris Martin ve Jonny Buckland, kelimelerle ifade edemedikleri duygularını ve hayallerini müzik aracılığıyla paylaşmak istediler. Birlikte şarkı yazmaya başladılar ve bu yolculuk onları birlikte müziğin büyülü dünyasına götürdü. Daha sonra Guy Berryman (bas gitar) ve Will Champion (davul ve vokal), olarak katıldıktan sonra grubun kadrosu tamamlandı. Bu dört yetenekli müzisyen, şarkı sözleri ve müzikleriyle dünya çapında bir hayran kitlesi kazandı.
</p>

                <h2 className="text-2xl font-bold mt-8" id="id2">Neden grubun adı Coldplay?</h2> <br></br>
                <p>Coldplay'in ismi, ilk başta "Pectoralz" olarak başladı. Bu isim, göğüs kası anlamına gelirken, bir müzik grubu için pek de cazip bir seçenek gibi görünmüyor ki değiştirmek istemişler.                 </p>
<br></br>
<p>Daha sonra grup, "Starfish" adını seçti. Starfish, yani deniz yıldızı, denizlerin derinliklerinde yaşayan ve sıradışı bir güzellikle parlayan bir canlıdır. Ancak, bu isim de grup için tam olarak uygun olduğunu düşünmediler.
</p>
<br></br>
<p>İşte burada hikaye daha ilginç bir hal alır. Grup, sonunda isimlerini "Coldplay" olarak değiştirdi. Peki, neden Coldplay? İsim değişikliği, Philip Horky'nin "Child's Reflections, Cold Play" adlı şiir kitabından esinlenerek yapıldı. "Cold Play" ifadesi, lirik ve duygusal şarkılar söyleyen bir grup için oldukça uygun bir seçenekti. Soğuk, duygusal derinlik ve içsel bir yolculuğu çağrıştırırken, "play" yani "çalmak" kelimesi de müziğin merkezinde olduğunu vurgular.
</p>
<br></br>
<p>Coldplay ismi, grup üyelerinin müziğe olan bağlılığını ve lirik şarkılarının derinliğini yansıtıyor. İsim seçimi, müziğin soğuk bir büyüsü gibi etkileyici ve etkili bir seçenek oldu.
</p>
<br></br>
<figure>
  <img src="https://cdn.konserbilet.com/assets/blogs/coldplay2.jpg" alt=" Coldplay Grubu" title="Coldplay Hakkında" />
  <figcaption class="mt-2 text-sm text-gray-500 text-center ">Coldplay Üyeleri </figcaption>
</figure>

<h2 className="text-2xl font-bold mt-8" id="id3">Coldplay ve Grammy Macerası</h2> <br></br>
<p>Coldplay, müziğin en prestijli ödüllerinden biri olan Grammy Ödülleri ile sık sık ödüllendirilen bir grup olarak tanınır. Onların müziği, geniş bir dinleyici kitlesi tarafından sevilirken, Grammy jürisi de bu büyüleyici sesleri takdir etmekten geri kalmadı. İşte Coldplay'in Grammy macerasının birkaç durağı:</p>
<br></br>
<p>1. İlk Zafer - 2004<br></br>

Coldplay, 2004 yılında "Clocks" şarkısıyla En İyi Kayıt dalında Grammy kazandı. Bu zafer, grup için büyük bir dönüm noktasıydı ve onları uluslararası sahnede tanınmış bir grup haline getirdi. "Clocks," grubun benzersiz müzik tarzını ve yeteneklerini dünya çapında sergiledi.
</p>
<br></br>
<p>2. "Viva la Vida" ve Dört Grammy - 2009<br></br>

2009 yılı, Coldplay için bir zafer yılıydı. "Viva la Vida" albümü, En İyi Rock Albümü ödülünü kazanırken, aynı zamanda Yılın Şarkısı, En İyi Pop İşbirliği ve En İyi Rock Performansı dallarında ödüller kazandılar. Bu, grubun yaratıcılığını ve müziğin evrensel etkisini gösteren bir anıydı.
</p>
<br></br>
<p>3. "A Head Full of Dreams" ve En İyi Müzik Videosu - 2017<br></br>

Coldplay, 2017 yılında "A Head Full of Dreams" albümü ile En İyi Müzik Videosu dalında Grammy kazandı. Müziğin yanı sıra görsel anlamda da büyüleyici eserler yaratmaları, grup üyelerinin sanatın her yönüne olan bağlılığını gösterir.
</p>
<br></br>
<p>4. "Everyday Life" ve Sosyal Mesajlar - 2021<br></br>

Son olarak, Coldplay'in "Everyday Life" albümü, 2021 yılında En İyi Müzik Videosu dalında bir Grammy kazandı. Grup, müziğini sosyal sorumluluk mesajlarıyla birleştirerek dinleyicilere dünyayı daha iyi bir yer haline getirme gücünün farkına varmalarını sağlıyor.
</p>
<br></br>
<div className="flex flex-col items-center">
      <iframe
        src="https://open.spotify.com/embed/playlist/37i9dQZF1DXaQm3ZVg9Z2X"
        width="100%"
        height="380"
        frameBorder="0"
        allowTransparency="true"
        allow="encrypted-media"
        title="Spotify Playlist"
        loading="lazy"
      ></iframe>
      <div className="mt-4">
        <a
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
          href="https://open.spotify.com/playlist/7mD2fn36FQtwdoDVmGX9Dv"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spotify'da Çal
        </a>
      </div>
    </div>
<h2 className="text-2xl font-bold mt-8" id="id4">İşte Coldplay Konserlerinin Büyüsü! Neden Coldplay Konserleri Bu Kadar Popüler? </h2> <br></br>
<p>Coldplay, yıllardır müzik sahnesinde yer aldı ve her yaştan, her müzik zevkinden insanı bir araya getiren büyülü konserlere imza attı. Peki, Coldplay konserlerinin neden bu kadar popüler olduğunu hiç düşündünüz mü? İşte bu büyülü deneyimi açıklayan nedenler:
</p>

<br></br>
<p>1. Duygusal Bağ Kurma: <br></br>
Coldplay şarkıları, dinleyicilerle derin bir duygusal bağ kurar. İçten ve dokunaklı sözler, insanların kalplerine dokunur. Konserler, şarkıların gücünü canlı deneyimleme fırsatı sunar. Chris Martin'ın hisleriyle yüklü vokali, izleyicileri duygusal bir yolculuğa çıkarır.
</p>
<br></br>
<p>2. Enerji ve Coşku: <br></br>
Coldplay konserleri, enerji ve coşkuyla doludur. Sahnedeki grup üyeleri ve seyirciler arasındaki etkileşim, konser boyunca bir enerji patlaması yaratır. Siz konseri izlerken, herkesin aynı coşkuyla şarkıları söylediğini görmek sizi içine çeker.
</p>
<br></br>
<p>3. Görsel Şölen:<br></br>
 Sahne tasarımı, ışıklandırma ve görsel efektler, Coldplay konserlerini unutulmaz bir görsel şölene dönüştürür. Renklerin dansı, ışıkların büyüsü ve sanatsal görseller, izleyicilere büyülü bir atmosfer sunar. Sanki başka bir dünyaya adım atmış gibi hissedersiniz.
</p>
<br></br>
<p>4. Katılımcılık:<br></br>
 Coldplay, izleyicilerini konserin bir parçası haline getirir. Chris Martin, sıklıkla izleyicileri şarkı söylemeye, dans etmeye ve sahneyi paylaşmaya davet eder. Herkes bir arada, bir aile gibi hisseder.
</p>

              </div>
            </div>
            <FaqSection faqs={customFaqs} sectionTitle={sectionTitle} />

            <div className="mt-8">
              <Link to="/blog" className="text-purple-500 font-medium hover:text-purple-600">
                &larr; Blog
              </Link>
            </div>
          </div>
        </div>
      </section>
      <BlogSection1 />
      <ScrollIndicator />
      <Footer />
    </div>
  );
};

export default BlogPostPage;
