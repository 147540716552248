import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import City from '../components/City';
import { Helmet } from 'react-helmet';

const pageTitle = 'Şehrine Yaklaşmakta Olan Konserleri Keşfet - Konserbilet.com';
const pageDescription = 'Dilediğin şehri seç, yaklaşmakta olan konserleri görüntüle. Onlarca şehir arasından aradığın konseri kolayca bul.';
const canonicalUrl = 'https://konserbilet.com/sehirler'; 
const imageUrl = 'https://konserbilet.s3.eu-central-1.amazonaws.com/faq.png';

const Sehirler = () => {
  return (
    <div>
        <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={imageUrl} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>
      <Header />
      <City />
      <Footer />
    </div>
  );
};

export default Sehirler;
