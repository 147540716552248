import React from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ScrollIndicator from '../../components/ScrollIndicator';
import BlogSection1 from '../../components/BlogSection1';
import FaqSection from '../../components/FaqSection';

const BlogPostPage = () => {
  const pageTitle = "Sarı Gelin Türküsünün Hikayesi - Konserbilet.com";
  const pageDescription = "Sarı Gelin Türküsü veya Erzurum Çarşı Pazar Türküsü olarak bilinen türkü; Türkiye, Azerbaycan, Ermenistan, Gürcistan ve İran’da popüler bir türküdür.  ";
  const canonicalUrl = 'https://konserbilet.com/blog/sari-gelin-turkusunun-hikayesi';
  const imageUrl = 'https://cdn.konserbilet.com/assets/blogs/sarigelinturkusu.webp';
  const author = 'Kaan Tabak';
  const publishDate = '5 Haziran 2023';
  const tableOfContents = [
    { id: 'id1', title: 'Sarı Gelin Türküsünün Hikayesi' },
    { id: 'id2', title: 'Sarı Gelin Sözleri' },
    { id: 'id3', title: 'Erzurum Çarşı Pazar Sözleri – Erzurum Çarşı Pazar Hikayesi' },
    { id: 'id4', title: "Yavuz Bingöl – Sarı Gelin" },
    { id: 'id5', title: "Cem Adrian – Sarı Gelin" },
    { id: 'id6', title: "İbrahim Erkal – Sarı Gelin" },

  ];
  const customFaqs = [
    {
    question: "Sarı Gelin türküsü nedir?",
    answer: "Sarı Gelin, Türk halk müziğinin en ünlü ve sevilen türkülerinden biridir. Türk halk kültüründe önemli bir yere sahip olan bu türkü, Anadolu'nun dört bir yanında farklı versiyonlarıyla söylenmektedir. Sarı Gelin türküsü, aşk, özlem ve ayrılık temalarını işlemektedir."
    },
    {
    question: "Sarı Gelin türküsünün sözleri kimin tarafından yazılmıştır?",
    answer: "Sarı Gelin türküsünün sözleri anonim olarak geçmektedir, yani kim tarafından yazıldığı kesin olarak bilinmemektedir. Türküler genellikle halk arasında dilden dile aktarılarak nesilden nesile geçer ve zamanla farklı versiyonları ortaya çıkar. Sarı Gelin türküsü de bu şekilde, zaman içinde farklı topluluklar ve bölgeler tarafından yorumlanarak farklı sözlerle de söylenebilir."
    },
    {
    question: "Sarı Gelin türküsünün farklı dillerdeki sözleri nelerdir?",
    answer: "Sarı Gelin türküsü, Türk halk müziğinin yanı sıra çeşitli dillerde de söylenmektedir. Özellikle Azerice ve Ermenice gibi dillerde de farklı versiyonları bulunmaktadır. Her dildeki sözler, kültürel farklılıklar ve yerel yorumlamalarla değişiklik gösterebilir."
    },
    {
    question: "Sarı Gelin türküsü hangi müzik türüne aittir?",
    answer: "Sarı Gelin türküsü, geleneksel Türk halk müziği türüne aittir. Bu türkü, genellikle sade enstrümantal düzenlemelerle veya gitar, bağlama gibi çalgılar eşliğinde icra edilir. Halk arasında söylenen ve dilden dile aktarılan bir türküdür."
    },
    {
    question: "Sarı Gelin türküsü hangi temaları işler?",
    answer: "Sarı Gelin türküsü, aşk, özlem, ayrılık, hasret gibi temaları işler. Türküde genellikle bir gelinin hüzünlü öyküsü anlatılır. Sarı Gelin, sevdiğiyle bir araya gelemeyen bir gelinin özlemini ve ayrılık acısını dile getirir. Bu nedenle, Sarı Gelin türküsü duygusal ve içten bir anlatıma sahiptir."
    },
    // Diğer soru-cevapları buraya ekleyin
    ];

  const sectionTitle = 'Mağusa Limanı Türküsü Hakkında';


  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Header />
      <section className="py-16 bg-white">
  <div className="container mx-auto px-4">
    <div className="max-w-3xl mx-auto">

            <h1 id="id1" className="text-3xl md:text-4xl font-bold mb-4">Sarı Gelin Türküsünün Hikayesi</h1>
            <p className="text-gray-500 text-sm mb-4"> {author} tarafından {publishDate} tarihinde yayınlandı</p>
            <img src={imageUrl} alt={pageTitle} className="w-full h-auto mb-8" />

            <div className="row">
              <div className="w-5/5 pr-8">
                <div className="w-5/5">
                  <h2 className="text-lg font-bold mb-4">İçindekiler</h2>
                  <ul className="space-y-2">
                    {tableOfContents.map((item) => (
                      <li key={item.id}>
<ScrollLink to={item.id} smooth={true} duration={500} offset={-20} className="text-purple-500 hover:text-purple-600">
  {item.title}
</ScrollLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="container mx-auto py-8">
              <div className="prose max-w-none">
                <p>Sarı Gelin türküsü, Kuzeydoğu Anadolu Erzurum coğrafyasında ortaya çıkmıştır. Türklerin büyük bir kolunu teşkil eden Kıpçakların diğer adı da Kuman’dır. Diğer kavimler, Kıpçakları “sarışın” anlamına gelen “Kuman” adıyla veya bu anlama gelen başka kelimelerle anmış ve tanımışlardır.
                <br></br><br></br>
Sarı Gelin, eski çağlardan beri Çoruh ırmağı boyunda yaşayan Hıristiyan Kıpçak beyinin kızıdır. Erzurumlu bir delikanlı sarışın, Kıpçak beyinin kızına âşık olur ve Erzurumlu delikanlı ile sarışın Kıpçak kızının arasında ve Erzurum yöresinde yaşamaktadır.
<br></br><br></br>
Türk kültüründen etkilenen Ermeniler arasında birçok şifahî halk edebiyatı ürünümüzün yaşıyor olması, Sarı Gelin türküsünün, bir Ermeni türküsü olduğu iddiasının ortaya çıkmasına sebep olmuştur. Böyle bir şey yoktur. Sarı gelin türküsünde Ermenice kelime yoktur.
<br></br><br></br>
Kıpçakların, güzel, sarışın, mavi gözlü oldukları tarihte bilinmektedir. Sarışın Kıpçak kızına âşık olan delikanlıyı ailesi kız ile evlenmesine karşı çıkar. Delikanlı ise kıza deli gibi âşık olur ve aşkını şiirle mırıldanarak söyler. Kız bey kızıdır zaten bey de kızını vermez bu delikanlıya.
<br></br><br></br>
Delikanlı sarışın güzel kızı kaçırmaya karar verir ve kaçırır. Kıpçak beyinin adamları iki kaçağın peşine düşer ve uzun bir takipten sonra bulurlar ve oğlanı öldürürler. O günden beri halkımız arasında bu hikâye dilden dile dolaşır.</p>
                <h2 className="text-2xl font-bold mt-8" id="id2">Sarı Gelin Sözleri</h2> <br></br>
                <p> Sarı Gelin Türküsü veya Erzurum Çarşı Pazar Türküsü olarak bilinen türkü; Türkiye, Azerbaycan, Ermenistan, Gürcistan ve İran’da popüler bir türküdür.</p>

                <h2 className="text-2xl font-bold mt-8" id="id3">Erzurum Çarşı Pazar Sözleri – Erzurum Çarşı Pazar Hikayesi</h2> <br></br>
                <p>  Erzurum çarşı pazar, leylim aman aman şeklinde başlayan Erzurum Çarşı Pazar Türküsü TRT Repertuvarı, 2000 olarak kayıtlı olup halk arasında “Sarı Gelin Türküsü” diye bilinen türkünün Kars, Ermenistan ve Azerbaycan yöresinde söylenen biçimlerinde farklılıklar bulunmaktadır. Sözlü kültürel bir miras olan bu geleneksel türkü, anonim olduğu için yazarı veya bestecisi bilinmez.</p>
                <br></br>


                <figure>
  <img src="https://www.masallaroku.com/wp-content/uploads/2020/12/sari-gelin.jpg" alt="Sarı Gelin Türküsünün Hikayesi" title="Sarı Gelin Türküsü" />
  <figcaption class="mt-2 text-sm text-gray-500 text-center ">Sarı Gelin </figcaption>
</figure>
                <h2 className="text-2xl font-bold mt-8" id="id4">Yavuz Bingöl – Sarı Gelin</h2> <br></br>
                <p> Türkünün ilk olarak popülerleşmesini sağlayan yorumlardan biri de Yavuz Bingöl oldu. 1999 Yılında yayınlan türküye aşağıdan erişebilirsiniz.</p>

                <h2 className="text-2xl font-bold mt-8" id="id5">Cem Adrian – Sarı Gelin</h2> <br></br>
                <p> Cem Adrian’ın 2010 yılında Canlı Performans olarak 312 Bar’da seslendirdiği Sarı Gelin Türküsü en çok bilinen versiyonlardan biri. İlerleyen dönemde bir çok mekanda ve konserde türküyü seslendiren sanatçı 2019 yılında Olten Philharmonic Orchestra ile de türküyü seslendirdi. <br></br>
                <br></br>

                <h2 className="text-2xl font-bold mt-8" id="id6">İbrahim Erkal – Sarı Gelin</h2> <br></br>
                <p> TRT Arşiv’de yer alan Erzurumun yiğit adamı İbrahim Erkal’ın yorumlaması ile Sarı Gelin Türküsü aşağıda yer alıyor.<br></br>
                <br></br> </p>


</p>


  </div>
            </div>
            <FaqSection faqs={customFaqs} sectionTitle={sectionTitle} />

            <div className="mt-8">
              <Link to="/blog" className="text-purple-500 font-medium hover:text-purple-600">
                &larr; Blog
              </Link>
            </div>
          </div>
        </div>
      </section>
      <BlogSection1 />
      <ScrollIndicator />
      <Footer />
    </div>
  );
};

export default BlogPostPage;
