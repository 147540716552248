import React, {useState} from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import FaqSection from '../../components/FaqSection';
import Breadcrumb from '../../components/Breadcrumb';
import SocialMedia from '../../components/SocialMedia';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TwitterShareButton, WhatsappShareButton } from 'react-share';
import { faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom'; 

const apiKey = process.env.REACT_APP_RAPIDAPI_KEY;


const TurkcellVadi = () => {
  const [displayedConcerts, setDisplayedConcerts] = useState([]);
  const [concerts, setConcerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalConcerts, setTotalConcerts] = useState(0);  
  const ITEMS_PER_PAGE = 10;
  const [venueName] = useState('Turkcell Vadi Açıkhava');
  const [infoMessage, setInfoMessage] = useState(null);

  const seoData = {
    title: `${venueName} Yaklaşmakta Olan Konser Takvimi - KonserBilet`,
    description: ` ${venueName} konser takvimini hemen görüntüle. ${venueName} sahnesindeki konserler hakkında bilinmeyen ve merak edilen soruların yanıtlarını konserbilet'te hemen incele.`,
     ogUrl: "https://konserbilet.com/istanbul/turkcell-vadi",
    ogImage: "https://cdn.konserbilet.com/assets/venue/turkcellvadi.png.webp"
  };
  

  const breadcrumb = [
    { name: 'Ana Sayfa', link: '/' },
    { name: 'Sahneler', link: '/sahneler' },
    { name: `${venueName}`, link: null } 
  ];

  
  const formatDate = (dateStr) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    const date = new Date(dateStr);
    return date.toLocaleDateString('tr-TR', options).replace(',', ' -');
  };

  const getCurrentDateFormatted = (daysOffset = 0) => {
    const date = new Date();
    date.setDate(date.getDate() + daysOffset);
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
  const minDate = getCurrentDateFormatted(-1);
  const maxDate = getCurrentDateFormatted(240);

  const fetchConcerts = async () => {
    setLoading(true);
    setInfoMessage(null);  // bilgilendirme mesajını sıfırlayın
    const options = {
      method: 'GET',
      url: 'https://concerts-artists-events-tracker.p.rapidapi.com/venue',
      params: {
        name: 'Turkcell Vadi',
        minDate: minDate,
        maxDate: maxDate
      },
      headers: {
        'X-RapidAPI-Key': apiKey,
        'X-RapidAPI-Host': 'concerts-artists-events-tracker.p.rapidapi.com'
      }
    };
    try {
      const response = await axios.request(options);
      if (response.data.data.length === 0) {
setInfoMessage(`Maalesef, ${venueName} isimli sahnede yaklaşmakta olan konser bilgisi bulunamadı.\nLütfen başka bir sahne için deneyin veya başka bir zaman yine gelin.`);
      } else {
          setConcerts(response.data.data);
          setDisplayedConcerts(response.data.data.slice(0, ITEMS_PER_PAGE));
          setTotalConcerts(response.data.data.length);
      }
  } catch (error) {
      console.error(error);
  }

  setLoading(false);
};

  const handleLoadMore = () => {
    const newDisplayedConcerts = concerts.slice(0, displayedConcerts.length + ITEMS_PER_PAGE);
    setDisplayedConcerts(newDisplayedConcerts);
  };




  const customFaqs = [
    {
        question: `Turkcell Vadi Açıkhava Sahnesi nerede yer alır?`,
        answer: `Turkcell Vadi Açıkhava Sahnesi, İstanbul'un gözdesi Maslak'ta, nefes kesen orman manzarası eşliğinde, Vadi İstanbul içinde yer alıyor. Burası, şehrin karmaşasından uzak, müziğin ve doğanın iç içe olduğu eşsiz bir mekan! Adresi ise ; Ayazağa Mahallesi. Cendere Caddesi
        1. Apt No:109 İç Kapı No: 254 Sarıyer/ İstanbul`
    },
    {
        question: `Turkcell Vadi Açıkhava Sahnesi kapasitesi ne kadar?`,
        answer: `Turkcell Vadi Açıkhava Sahnesi, 3.000 kişiye kadar konuk kapasitesiyle İstanbul'daki en özel etkinliklere ev sahipliği yapmaktadır. Etkinlik türüne göre kapasite değişiklik gösterebilir, bu yüzden biletinizi önceden almayı unutmayın!`
    },
    {
        question: `Turkcell Vadi Açıkhava Sahnesi oturma düzeni nasıldır?`,
        answer: `Oturma düzeni etkinliğe göre değişkenlik gösterir, ancak genellikle açık hava konserlerinde olduğu gibi serbest oturma düzeni hakimdir. Erken gelin, favori yerinizi kapın ve harika orman manzarasının tadını çıkarın!`
    },
    {
        question: `Turkcell Vadi Açıkhava Sahnesi yemek ve içecek servisi var mı?`,
        answer: `Evet, var! Turkcell Vadi Açıkhava Sahnesi'nde çeşitli yiyecek ve içecek seçenekleri sunan standlar bulunmaktadır. Ancak, etkinliğe özel kurallar olabileceğini unutmayın ve dışarıdan yiyecek içecek getirmemeye özen gösterin!`
    },
    {
        question: `Turkcell Vadi Açıkhava Sahnesi nasıl gidilir?`,
        answer: `Levent ve Maslak’ın hemen yanı başındaki Vadistanbul’da yer alan Turkcell Vadi’ye toplu taşıma ile en kolay yoldan, Havaray ile ulaşabilirsiniz. Havaray ile Seyrantepe Metrosu’ndan Vadistanbul AVM’ye 3 dakikada erişim sağlanabiliyor.

        Özel aracı ile Turkcell Vadi’ye gelenler içinde TEM otoyolundan bağlanan yeni yollar, trafik telaşı yaşamadan ulaşım imkanını sunuyor. Geniş otopark kapasitesi, Turkcell Vadi’deki etkinliklere ücretsiz erişim imkanını sağlıyor.`
    },
    {
        question: `Turkcell Vadi Açıkhava Sahnesi engelli erişimi mevcut mu?`,
        answer: `Kesinlikle, evet! Turkcell Vadi Açıkhava Sahnesi, engelli bireyler için erişim kolaylıkları ve özel oturma alanları sunar, böylece herkes etkinliğin tadını çıkarabilir.`
    },
    {
        question: `Turkcell Vadi Açıkhava Sahnesi sigara içilir mi?`,
        answer: `Turkcell Vadi Açıkhava Sahnesi'nde, genel alanlarda sigara içmek yasaktır. Ancak, belirlenmiş sigara içme alanları mevcuttur. Bu alanları kullanarak hem kendinizin hem de diğer katılımcıların konforunu düşünebilirsiniz.`
    },
    {
        question: `Turkcell Vadi Açıkhava Sahnesi iletişim bilgisi nedir?`,
        answer: `Daha fazla bilgi ve sorularınız için Turkcell Vadi Açıkhava Sahnesi'ne (0212) 286 66 10 numaralı telefondan ulaşabilir veya info@uniqistanbul.com adresine e-posta gönderebilirsiniz. Unutmayın, müzik ve eğlence dolu bir deneyim sizi bekliyor!`
    },
];

    
  const sectionTitle = `${venueName} Konserleri Hakkında`;


  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
<Helmet>
  <title>{seoData.title}</title>
  <meta name="description" content={seoData.description} />
  <meta property="og:title" content={seoData.title} />
  <meta property="og:description" content={seoData.description} />
  <meta property="og:type" content="website" />
  <meta property="og:url" content={seoData.ogUrl} />
  <meta property="og:image" content={seoData.ogImage} />
  <link rel="canonical" href={seoData.ogUrl} />
</Helmet>

      <Header />

      <Breadcrumb breadcrumb={breadcrumb} />
      <div className="bg-gradient-to-r from-purple-700 to-indigo-700">
  <div className="container mx-auto px-10">
    
    <h1 className="text-center text-yellow-400 text-4xl font-extrabold mb-4 pt-4 leading-tight tracking-wider">
    <strong>{venueName}</strong> Konser Takvimi
    </h1>
    <div className="flex flex-col md:flex-row items-center justify-between mb-6 mr-5">
      <div className="md:w-1/2 mb-4 md:mb-0">
      <img 
  src={seoData.ogImage} 
  alt={`${venueName} Hakkında`} 
  title={`${venueName} Yaklaşan Konserleri`}
  className="rounded-lg shadow-lg w-full mx-auto lg:max-w-lg" 
/>

      </div>
      <div className="md:w-1/2 flex items-center">
    <p className="text-white text-lg font-medium mx-auto leading-relaxed ml-5">
        {venueName}, sadece bir konser mekanı değil, aynı zamanda unutulmaz anılar ve sıcak dostluklar kazanabileceğiniz harika bir buluşma noktası! {venueName} sahnesi, enerjisi ve kültürel zenginliğiyle, müziği ve eğlenceyi ciddiye alan herkes için bir cennet. 🎶<br></br><br></br>
        Yaklaşmakta olan {venueName} konserleri, bizi bir araya getiren ve hepimizin içindeki ritmi hissettiren muhteşem etkinlikler sunmakta! Hadi, {venueName} konser takvimi ile planlarınızı şimdiden yapın ve biletlerinizi Konserbilet üzerinden hızlıca satın alın! <br></br><br></br>
        Konserbilet, {venueName} sahnesindeki tüm yaklaşmakta olan konserleri ve etkinlikleri sizin için bir araya getiriyor. Kolayca biletlerinizi arkadaşlarınızla paylaşın ve birlikte müziğin büyüsünü yaşayın. 🎉
    </p>
</div>

</div>



<br></br>
<h2 className="text-center text-yellow-400 text-4xl font-extrabold mb-4 leading-tight tracking-wider">Yaklaşmakta Olan <strong>{venueName}</strong> Konserleri</h2>
{infoMessage && (
    <div className="text-center text-white m-4">
        {infoMessage}
    </div>
)}
{!infoMessage && (  // infoMessage null veya undefined ise bu bloğu render et
    !loading && displayedConcerts.length === 0 ? (
        <div className="flex justify-center my-4">
          <button 
              className="bg-yellow-500 m-7 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded inline-flex items-center"
              onClick={fetchConcerts}
          >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5 4a1 1 0 011.993.117L7 4v8.904l4.804-.964a1 1 0 01.521.07l.084.04 4.1 2.05a1 1 0 01.014 1.68l-.014.008L7.416 18a1.001 1.001 0 01-1.03-1.674L11 12.63V5a1 1 0 011-1 1 1 0 011 1v8.528l3.728-.764-3.516-1.76a1 1 0 01-.52-.07l-.082-.041L6 5.871V5a1 1 0 01-1-1z" clipRule="evenodd" />
              </svg>
              Konserleri Görüntüle
          </button>
        </div>
      ) : (
        <>
          {loading ? (
            <div className="flex flex-col justify-center items-center mt-10">
              <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-yellow-300 mb-4" />
              <p className="text-yellow-100 mt-3 font-bold"><strong>{venueName}</strong> Konserleri yükleniyor...</p>
            </div>
          ) : (
            <div className="">
            <p className="text-center text-white m-4 leading-tight tracking-wider">
            <strong className="text-yellow-400">{venueName}</strong> şehrinde yaklaşmakta olan <strong className="text-yellow-400">{totalConcerts} Adet</strong> konseri var! Dilediğin konseri seç, hemen arkadaşın ile paylaş!</p>
           <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 pt-9 pb-5">

            {displayedConcerts.map((concert, index) => (

  <motion.div 
    key={concert.startDate} 
    className="bg-white rounded-lg shadow-md hover:shadow-xl transition-scale duration-300 transform hover:scale-105 relative"  // 'relative' class'ını ekledim
    initial={{ opacity: 0, y: 50 }}  // Başlangıçta görünmez ve biraz aşağıda olacak
    animate={{ opacity: 1, y: 0 }}  // Animasyon sonunda görünür ve doğru konumda olacak
    transition={{ delay: index * 0.1 }}  // Her bir kartın animasyonu bir öncekinden 0.1 saniye sonra başlayacak
  >
    <div className="relative">
        <img 
            src={concert.image || 'https://cdn.konserbilet.com/assets/venue/anasayfasanatcilarmasaustu.png.webp'} 
            alt={concert.name} 
            className="w-full h-50 object-cover rounded-md mb-2 hover:scale-115 transition-scale duration-300" 
        />
        <div className="absolute top-0 left-0 w-full h-40 bg-black opacity-0  rounded-md transition-scale duration-300 "></div>
    </div>
    <h3 className="text-xl font-bold m-2">{concert.name}</h3>
    <p className="text-gray-700 m-2">{concert.location.name}</p>
    <p className="text-gray-700 m-2">{concert.location.address.addressLocality}</p>
    <p className="text-gray-500 m-2">{formatDate(concert.startDate)}</p>
    <div className="mb-2 flex space-x-6 justify-center">
  <TwitterShareButton
    url={window.location.href}
    title={`${concert.name} konseri,  - ${formatDate(concert.startDate)} - ${concert.location.name} sahnesinde! konserbilet.com ile  hemen görüntüle!`}
  >
    <div className="bg-blue-500 text-white p-1 rounded">
      <FontAwesomeIcon icon={faTwitter} />
    </div>
  </TwitterShareButton>
  <WhatsappShareButton
    url={window.location.href}
    title={`${concert.name} konseri,  - ${formatDate(concert.startDate)} - ${concert.location.name} sahnesinde! konserbilet.com ile  hemen görüntüle!`}
  >
    <div className="bg-green-500 text-white p-1 rounded">
      <FontAwesomeIcon icon={faWhatsapp} />
    </div>
  </WhatsappShareButton>
</div>


  </motion.div>
))}
                            </div>

              </div>
            )}
            {displayedConcerts.length < concerts.length && (
              <div className="flex justify-center m-4">
                <button className="bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded " onClick={handleLoadMore}>Daha Fazlası</button>
              </div>
            )}
    </> 
          )
          
    )}
    

<br></br>


    <figure>
  <img 
    className="rounded-lg shadow-lg w-full mx-auto lg:max-w-lg" 
    src="https://s3-eu-central-1.amazonaws.com/vadi-istanbul/gallery//fde5a7d6-8b7a-4855-a07d-0711deb3bc5f.jpg?1660661371265" 
    alt={venueName + " Hakkında"} // venueName değişkenini bir string ile birleştirerek kullanıyoruz
    title={venueName + " Konserleri"} // aynı şekilde burada da
    loading="lazy"
  />
  <figcaption className="mt-2 text-sm text-white text-center">{venueName}</figcaption>
</figure>

      <FaqSection faqs={customFaqs} sectionTitle={sectionTitle} />
     
      <div className="mt-8">
              <Link to="/sahneler" className="text-blue-500 font-bold font-xl hover:text-blue-600">
                &larr; Sahneler
              </Link>
            </div>
     
      <div>      <SocialMedia />   </div>
        </div>
      </div>
      <Footer />
      </motion.div>
  );
};

export default TurkcellVadi;
