import React from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ScrollIndicator from '../../components/ScrollIndicator';
import BlogSection1 from '../../components/BlogSection1';
import FaqSection from '../../components/FaqSection';

const BlogPostPage = () => {
  const pageTitle = 'Yalı Çapkını Dizi Müzikleri - KonserBilet';
  const pageDescription = 'Yalı Çapkını dizisinin müzikleri hakkında bilgileri keşfedin. Konserbilet ile en güncel dizi müziklerine ulaşın.';
  const canonicalUrl = 'https://konserbilet.com/blog/yali-capkini-dizi-muzikleri';
  const imageUrl = 'https://cdn.konserbilet.com/assets/blogs/yalicapkinidizimuzikleri.webp';
  const author = 'Kaan Tabak';
  const publishDate = 'July 10, 2023';
  const tableOfContents = [
    { id: 'id1', title: 'Yalı Çapkını Dizi Müzikleri' },
    { id: 'id2', title: "Yalı Çapkını Dizisinin Konusu Ne?" },
    { id: 'id3', title: "Yalı Çapkını Dizisi Nerede Çekiliyor?" },
    { id: 'id4', title: "Yalı Çapkını Dizisi Müziklerini Kim Yapıyor?" },
    { id: 'id5', title: "Yalı Çapkını Hikayesi Gerçek Mi?" }
  ];

  const customFaqs = [
    {
      question: 'Yalı Çapkını dizisinin tema müziği nedir?',
      answer: 'Yalı Çapkını dizisinin tema müziği "Müzik İsim" adlı şarkıdır. Bu şarkı, dizinin açılış jeneriğinde kullanılmaktadır ve müziği "Besteci İsim" tarafından bestelenmiştir.'
    },
    {
      question: 'Dizi müziği albümü çıktı mı?',
      answer: 'Evet, Yalı Çapkını dizisinin müziklerinin yer aldığı bir dizi müziği albümü çıkmıştır. Albümde toplamda 10 adet şarkı yer almaktadır ve müzikler "Besteci İsim" tarafından bestelenmiştir.'
    },
    // Diğer soru-cevapları buraya ekleyin
  ];

  const sectionTitle = "Yalı Çapkını Dizi Müzikleri Hakkında";


  
  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Header />
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-3xl md:text-4xl font-bold mb-4">Yalı Çapkını Dizi Müzikleri</h1>
            <p className="text-gray-500 text-sm mb-4"> {author} tarafından {publishDate} tarihinde yayınlandı</p>
            <img src={imageUrl} alt={pageTitle} className="w-full h-auto mb-8" />

            <div className="row">
              <div className="w-5/5 pr-8">
                <div className="w-5/5">
                  <h2 className="text-lg font-bold mb-4">İçindekiler</h2>
                  <ul className="space-y-2">
                    {tableOfContents.map((item) => (
                      <li key={item.id}>
                        <ScrollLink to={item.id} smooth={true} duration={500} className="text-purple-500 hover:text-purple-600">
                          {item.title}
                        </ScrollLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="container mx-auto py-8">
              <div className="prose max-w-none">
                <p>Yalı Çapkını dizisinin müzikleri hakkında bilgileri derledik. Bu dizi müzikleri, izleyicilere eşsiz bir deneyim sunmak için özenle seçilmiştir. Dizi müzikleri albümünde yer alan şarkılar, "Besteci İsim" tarafından bestelenmiş ve dizi sahnelerine uyum sağlamak için özel olarak düzenlenmiştir.</p>

                <h2 className="text-2xl font-bold mt-8" id="id1">Yalı Çapkını Dizi Müzikleri</h2> <br></br>
                <p>Yönetmen koltuğunda Burcu Alptekin’in oturduğu Yalı Çapkını dizisinin ilk bölümü 23 Eylül tarihinde yayınlandı. Senaryosunu Mehmet Barış Günger’in kaleme aldığı dram ve romantik türdeki dizinin müzikleri izleyiciler tarafından merak edildi. Konserbilet Blog ile bu yazımızda Yalı Çapkını Dizi Müzikleri hakkında Konserbilet Blog’ta bilgileri derledik.
Başrollerini Afra Saraçoğlu, Mert Ramazan Demir ve Çetin Tekindor paylaşmakta olduğu Yalı Çapkını Dizisinde çalan müzikler her Cuma günü izleyicileri ile buluşuyor.
</p>

                <h2 className="text-2xl font-bold mt-8" id="id2">Yalı Çapkını Dizisinin Konusu Ne?</h2> <br></br>
                <p>Yalı Çapkını, Gaziantepli Korhan Ailesi’nin direği Halis Ağa’nın (Çetin Tekindor) el bebek gül bebek yetiştirilmiş torunu Ferit’in (Mert Ramazan Demir) evlenmesine karar vermesiyle gelişecek olayları konu alıyor. Kazım ve Esme’nin küçük kızı. Ablası gibi Antep’in parmakla gösterilen genç kızlarındandır

Sorumsuzluklarıyla ailesini en çok da dedesi Halis Ağa’yı kızdıran Ferit’in Gaziantep’ten uygun görülecek bir kızla evlendirilmesine kararı verilir. 
Ferit, evlenmek istemese de dedesinin sözünden çıkamaz. Uygun gelini bulmak Halis Ağa’nın en büyük gelini İfakat’in görevidir. Gaziantep’te Korhan Ailesi’nin gelini olmaya uygun bulunan Suna’nın isteme töreninde yaşanacak sürpriz ise hem Suna’yı hem de kardeşi Seyran’ı etkileyecektir.</p>
<br></br>
<figure>
  <img src="https://cdn1.startv.com.tr/media/PhotoGalleries/11761/87153.jpg?v=44832,4222277778" alt="Yalı Çapkını dizi müzikleri - star tv " title="Sarı Gelin Türküsü" />
  <figcaption class="mt-2 text-sm text-gray-500 text-center ">Ferit ile Seyran </figcaption>
</figure>

<h2 className="text-2xl font-bold mt-8" id="id3">Yalı Çapkını Dizisi Nerede Çekiliyor?</h2> <br></br>
                <p>Çekimleri İstanbul ve Gaziantep’te yapılan dizinin bir bölümü 120 dakikadan oluşuyor. Dizinin Gaziantep çekimleri Millet Hanı’nda yapılıyorken, İstanbul sahneleri ise Beylerbeyi’nde bir yalıda çekilmektedir
Boğazın gözdeleri arasında yer alan Beylerbeyi Sakıp Sabancı Yalısı 600 bin liraya Star TV’de ekrana gelecek Yalı Çapkını dizisine kiralandı.  Dilek Sabancı kiralama gelirinin tümünü Engelli Çocuklar Derneğine bağışlayacak.
</p>

<h2 className="text-2xl font-bold mt-8" id="id4">Yalı Çapkını Dizisi Müziklerini Kim Yapıyor?</h2> <br></br>
                <p>Yalı Çapkını Dizisinin müziklerinin besteleri Mimar Sinan Güzel Sanatlar Üniversitesi mezunu Güldiyar Tanrıdağlı tarafından yapılıyor. Yalı Çapkını Dizi Müzikleri Müzik Bankası – Müzikotek tarafından da alınmakta.
</p>

<br></br>
<div className="flex flex-col items-center">
      <iframe
        src="https://open.spotify.com/embed/playlist/7mD2fn36FQtwdoDVmGX9Dv"
        width="100%"
        height="380"
        frameBorder="0"
        allowTransparency="true"
        allow="encrypted-media"
        title="Spotify Playlist"
        loading="lazy"
      ></iframe>
      <div className="mt-4">
        <a
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
          href="https://open.spotify.com/playlist/7mD2fn36FQtwdoDVmGX9Dv"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spotify'da Çal
        </a>
      </div>
    </div>

<h2 className="text-2xl font-bold mt-8" id="id5">Yalı Çapkını Hikayesi Gerçek Mi?</h2> <br></br>
                <p>Star’ın iddialı yapımı olan, oyuncu kadrosu ve hikayesiyle ilk bölümden itibaren beğenilen Yalı Çapkını dizisi, yayınlandığı günden bu yana merak konusu oldu.

Ünlü psikiyatr ve yazar Gülseren Budayıcıoğlu’nun kaleminden çıkan dizinin hikayesinin gerçek bir hikaye olduğu biliniyor. Gülseren Budayıcıoğlu dizinin konusuyla ilgili, ”Bu Yalı Çapkını’nın hikayesi benim çok sevdiğim bir hikaye. Tabii ki gerçek bir hikayeden alındı. Her karakterin ayrı bir hüznü ve sıkıntısı var. Bakalım siz hangi karakterlerle empati yapacaksınız. Ben en çok Seyran ile ablası Suna’yı kendime yakın görüyorum. Benim de kardeşimle ilişkim böyle yakındı. Halanın, evdeki anne Esme’nin, Suna’nın kaderi başka” Şeklinde açıklaması mevcut.
Dizi başlangıçlarında “Gerçek Bir Hikayeden Alınmıştır” şeklinde tanıtılınca Yalı Çapkını dizisinin kimin hikayesi olduğunu merak konusu oldu. Ancak Hikayenin gerçek sahipleri ise etik kuralları çerçevesinde paylaşılmıyor.</p>


              </div>
            </div>
            <FaqSection faqs={customFaqs} sectionTitle={sectionTitle} />

            <div className="mt-8">
              <Link to="/blog" className="text-purple-500 font-medium hover:text-purple-600">
                &larr; Blog
              </Link>
            </div>
          </div>
        </div>
      </section>
      <BlogSection1 />
      <ScrollIndicator />
      <Footer />
    </div>
  );
};

export default BlogPostPage;
