import React, { useState, useEffect } from 'react';

const BlogSection1 = () => {
  const blogPosts = [
    {
      id: 1,
      title: 'Rana Türkyılmaz Kimdir?',
      description: 'Rana Türkyılmaz kimdir? Türk rock müziğinin önemli isimlerinden Rana Türkyılmaz hakkında bilgiler.',
      image: 'https://cdn.konserbilet.com/assets/blogs/ranaturkyilmazblog.webp',
      url: '/blog/rana-turkyilmaz-kimdir',
      },
    {
    id: 2,
    title: 'Sarı Gelin Türküsünün Hikayesi',
    description: 'Türk halk müziğinin sevilen türkülerinden Sarı Gelinin hikayesini öğrenin.',
    image: 'https://cdn.konserbilet.com/assets/blogs/sarigelinturkusu.webp',
    url: '/blog/sari-gelin-turkusunun-hikayesi',
    },
    {
    id: 3,
    title: 'Mağusa Limanı Türküsünün Hikayesi',
    description: 'Türk halk müziğinin güzel türkülerinden Mağusa Limanının hikayesini öğrenin.',
    image: 'https://cdn.konserbilet.com/assets/blogs/magusalimanihikayesi.webp',
    url: '/blog/magusa-limani-turkusunun-hikayesi',
    },
    {
    id: 4,
    title: 'Yalı Çapkını Dizi Müzikleri',
    description: 'Yalı Çapkını dizisinin unutulmaz müzikleriyle keyifli bir yolculuğa çıkın!',
    image: 'https://cdn.konserbilet.com/assets/blogs/yalicapkinidizimuzikleri.webp',
    url: '/blog/yali-capkini-dizi-muzikleri',
    },
    {
    id: 5,
    title: 'İstanbul Anadolu Yakası En İyi Canlı Müzik Yapılan Restoranlar',
    description: 'İstanbul Anadolu Yakasıda canlı müzik eşliğinde keyifli bir akşam geçirebileceğiniz restoranları keşfedin.',
    image: 'https://cdn.konserbilet.com/assets/blogs/anadoluyakasicanlimuzik.webp',
    url: '/blog/anadolu-yakasi-canli-muzik',
    },
    {
    id: 6,
    title: 'Tribute Konser Ne Demek?',
    description: 'Tribute konserleri nedir ve ne anlama gelir? Bu yazımızda tribute konserlerini keşfedin.',
    image: 'https://cdn.konserbilet.com/assets/blogs/Tributekonser.webp',
    url: '/blog/tribute-konser-ne-demek',
    },
    { 
    id: 7,
    title: 'Coldplay Grubu ve Konserleri Hakkında',
    description: 'Neden Coldplay konserleri bu kadar popüler? Bu sorunun cevabını öğrenmek için okumaya devam edin!',
    image: 'https://cdn.konserbilet.com/assets/blogs/coldplay.webp',
    url: '/blog/coldplay-grubu-ve-konserleri-hakkinda',
    },
    { 
    id: 8,
    title: 'Arabayla Yolculuk Yaparken Dinleyebileceğiniz Hareketli Yabancı Şarkılar',
    description: 'Hareketli şarkılarla beraber sizin de enerjiniz artar ve böylece yolculuklar daha eğlenceli hale gelir. Arabayla Yolculuk Yaparken Dinleyebileceğiniz 10 Hareketli Yabancı Şarkılar listemizi siz Konserbilet.com okuyucuları için hazırladık.',
    image: 'https://cdn.konserbilet.com/assets/blogs/yolculuk.webp',
    url: '/blog/yolculuk-sarkilari',
    },
    { 
    id: 9,
    title: 'Kitap Okurken Ruhunuzu Dinlendirmenin Sihirli Anahtarı: Kitap Okuma Müzikleri',
    description: 'Sizin için en güzel kitap okuma müziklerini derledik! Bu sakin müziğin okuma deneyiminize nasıl bir dokunuş kattığını keşfedeceksiniz.',
    image: 'https://cdn.konserbilet.com/assets/blogs/kitapokumamuzikleri.webp',
    url: '/blog/kitap-okuma-muzikleri',
    },
    { 
    id: 10,
    title: 'Müziğin Sihirli Dünyası: Fiziksel ve Ruhsal Açıdan Müziğin Sağlığa Etkileri',
    description: 'Müzik, sadece kulaklarımıza değil, kalbimize de seslenir. Bu, müziği sadece bir ses dalgası değil, bir dost gibi düşünmemize neden olur. Bu arkadaşın sağlığımıza olan bu olumlu etkilerini birlikte keşfetmeye hazır mısınız?',
    image: 'https://cdn.konserbilet.com/assets/blogs/muziketkileri.webp',
    url: '/blog/muzik-etkileri',
      },
      { 
        id: 11,
        title: 'İstanbul Avrupa Yakası En İyi Canlı Müzik Yapılan Mekanlar',
        description: 'İstanbul Avrupa Yakasıda canlı müzik eşliğinde keyifli bir akşam geçirebileceğiniz mekanları keşfedin.?',
        image: 'https://cdn.konserbilet.com/assets/blogs/avrupayakasi.webp',
        url: '/blog/avrupa-yakasi-canli-muzik',
          },
    ];

  const [randomPosts, setRandomPosts] = useState([]);

  useEffect(() => {
    const getRandomPosts = () => {
      const shuffledPosts = blogPosts.sort(() => 0.5 - Math.random());
      return shuffledPosts.slice(0, 3);
    };

    setRandomPosts(getRandomPosts());
  }, []);

  const blogLink = '/blog';
  const ctaDescription = 'Yazarlarımız senin için konser camiasının en önemli yazılarını yazıyor. En son haberler, ipuçları ve güncellemeleri burada bulabilirsin. Keşfetmek için tıkla!';
  const ctaButton = 'Blog Yazılarını Görüntüle';

  return (
    <section className="bg-gradient-to-r from-purple-700 to-indigo-700 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl text-center text-yellow-400 font-bold mb-8">
          Bu Yazılar Dikkatini Çekebilir!
        </h2>
        <p className="text-center text-white mb-8">{ctaDescription}</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {randomPosts.map((post) => (
            <div key={post.id} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <a href={post.url}>
                <img src={post.image} alt="Blog Post" className="w-full h-48 object-cover" />
              </a>
              <div className="p-4">
                <h3 className="text-lg md:text-xl font-bold mb-2">{post.title}</h3>
                <p className="text-gray-600 mb-4">{post.description}</p>
                <a
                  href={post.url}
                  className="bg-purple-500 hover:bg-purple-600 text-white py-2 px-4 rounded-lg transition-colors duration-300 ease-in-out"
                >
                  Daha Fazlası
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-8">
          <a href={blogLink}
            className="bg-purple-600 hover:bg-purple-600 text-white font-medium py-2 px-4 rounded-md">
            {ctaButton}
          </a>
        </div>
      </div>
    </section>
  );
};

export default BlogSection1;
