import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';


const BlogPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);
  const [paginatedPosts, setPaginatedPosts] = useState([]);

  const blogPosts = [
    {
      id: 1,
      title: 'Rana Türkyılmaz Kimdir?',
      description: 'Rana Türkyılmaz kimdir? Türk rock müziğinin önemli isimlerinden Rana Türkyılmaz hakkında bilgiler.',
      image: 'https://cdn.konserbilet.com/assets/blogs/ranaturkyilmazblog.webp',
      url: '/blog/rana-turkyilmaz-kimdir',
      },
    {
    id: 2,
    title: 'Sarı Gelin Türküsünün Hikayesi',
    description: 'Türk halk müziğinin sevilen türkülerinden Sarı Gelinin hikayesini öğrenin.',
    image: 'https://cdn.konserbilet.com/assets/blogs/sarigelinturkusu.webp',
    url: '/blog/sari-gelin-turkusunun-hikayesi',
    },
    {
    id: 3,
    title: 'Mağusa Limanı Türküsünün Hikayesi',
    description: 'Türk halk müziğinin güzel türkülerinden Mağusa Limanının hikayesini öğrenin.',
    image: 'https://cdn.konserbilet.com/assets/blogs/magusalimanihikayesi.webp',
    url: '/blog/magusa-limani-turkusunun-hikayesi',
    },
    {
    id: 4,
    title: 'Yalı Çapkını Dizi Müzikleri',
    description: 'Yalı Çapkını dizisinin unutulmaz müzikleriyle keyifli bir yolculuğa çıkın!',
    image: 'https://cdn.konserbilet.com/assets/blogs/yalicapkinidizimuzikleri.webp',
    url: '/blog/yali-capkini-dizi-muzikleri',
    },
    {
    id: 5,
    title: 'İstanbul Anadolu Yakası En İyi Canlı Müzik Yapılan Restoranlar',
    description: 'İstanbul Anadolu Yakasıda canlı müzik eşliğinde keyifli bir akşam geçirebileceğiniz restoranları keşfedin.',
    image: 'https://cdn.konserbilet.com/assets/blogs/anadoluyakasicanlimuzik.webp',
    url: '/blog/anadolu-yakasi-canli-muzik',
    },
    {
    id: 6,
    title: 'Tribute Konser Ne Demek?',
    description: 'Tribute konserleri nedir ve ne anlama gelir? Bu yazımızda tribute konserlerini keşfedin.',
    image: 'https://cdn.konserbilet.com/assets/blogs/Tributekonser.webp',
    url: '/blog/tribute-konser-ne-demek',
    },
    { 
    id: 7,
    title: 'Coldplay Grubu ve Konserleri Hakkında',
    description: 'Neden Coldplay konserleri bu kadar popüler? Bu sorunun cevabını öğrenmek için okumaya devam edin!',
    image: 'https://cdn.konserbilet.com/assets/blogs/coldplay.webp',
    url: '/blog/coldplay-grubu-ve-konserleri-hakkinda',
    },
    { 
    id: 8,
    title: 'Arabayla Yolculuk Yaparken Dinleyebileceğiniz Hareketli Yabancı Şarkılar',
    description: 'Hareketli şarkılarla beraber sizin de enerjiniz artar ve böylece yolculuklar daha eğlenceli hale gelir. Arabayla Yolculuk Yaparken Dinleyebileceğiniz 10 Hareketli Yabancı Şarkılar listemizi siz Konserbilet.com okuyucuları için hazırladık.',
    image: 'https://cdn.konserbilet.com/assets/blogs/yolculuk.webp',
    url: '/blog/yolculuk-sarkilari',
    },
    { 
    id: 9,
    title: 'Kitap Okurken Ruhunuzu Dinlendirmenin Sihirli Anahtarı: Kitap Okuma Müzikleri',
    description: 'Sizin için en güzel kitap okuma müziklerini derledik! Bu sakin müziğin okuma deneyiminize nasıl bir dokunuş kattığını keşfedeceksiniz.',
    image: 'https://cdn.konserbilet.com/assets/blogs/kitapokumamuzikleri.webp',
    url: '/blog/kitap-okuma-muzikleri',
    },
    { 
    id: 10,
    title: 'Müziğin Sihirli Dünyası: Fiziksel ve Ruhsal Açıdan Müziğin Sağlığa Etkileri',
    description: 'Müzik, sadece kulaklarımıza değil, kalbimize de seslenir. Bu, müziği sadece bir ses dalgası değil, bir dost gibi düşünmemize neden olur. Bu arkadaşın sağlığımıza olan bu olumlu etkilerini birlikte keşfetmeye hazır mısınız?',
    image: 'https://cdn.konserbilet.com/assets/blogs/muziketkileri.webp',
    url: '/blog/muzik-etkileri',
      },
      { 
        id: 11,
        title: 'İstanbul Avrupa Yakası En İyi Canlı Müzik Yapılan Mekanlar',
        description: 'İstanbul Avrupa Yakasıda canlı müzik eşliğinde keyifli bir akşam geçirebileceğiniz mekanları keşfedin.',
        image: 'https://cdn.konserbilet.com/assets/blogs/avrupayakasi.webp',
        url: '/blog/avrupa-yakasi-canli-muzik',
          },
          { 
            id: 12,
            title: "Bebekler İçin En Güzel Şarkılar ve Sözleri",
            description: "Bebeklerinizin mutlu olmaları için onlara güzel müzikler sunmanın harika bir yolunu sizler için kolaylaştırdık. Bugün sizlere bu blog yazısında Bebekler İçin En Güzel Şarkılar ve Sözlerinden bahsedeceğiz.",
            image: "https://cdn.konserbilet.com/assets/blogs/bebeksarkilari.webp",
            url: "/blog/bebek-sarkilari",
              },
              { 
                id: 13,
                title: "En Güzel Yeni Yıl & 2024 Yılbaşı Şarkıları",
                description: "Yeni yıl & Yılbaşı öncesi size spesifik şarkı önerileri ve ülkelere göre bazı sevilen yeni yıl ve yılbaşı şarkılarını derledik!  Müziğin büyülü dünyasına adım atın ve bu özel anları unutulmaz kılın! ",
                image: "https://cdn.konserbilet.com/assets/blogs/yeniyil.webp",
                url: "/blog/yeniyil-yilbasi-muzikleri",
                  },
                  { 
                    id: 14,
                    title: "Ses Nasıl Güzelleştirilir? En Kolay Ses Açma Egzersizleri",
                    description: "Şan eğitimiyle sesin gelişir ve daha güzel bir hâl alır. Evde yapabileceğin ses açma egzersizlerini ve ses güzelleştirme tüyolarını Konserbilet Blog ile görüntüle.",
                    image: "https://cdn.konserbilet.com/assets/blogs/sesacma.webp",
                    url: "/blog/ses-guzellestirme-ses-acma-teknikleri",
                      },
                      { 
                        id: 15,
                        title: "Tüm Hafızalarda Yer Edinmiş Dizi Müzikleri Hangileridir?",
                        description: "Sevilen dizi müziklerini özgürce dinle diye senin için harika bir liste hazırladık. Konserbilet Blog ile yerli ve yabancı dizilerin şarkılarını keşfet!",
                        image: "https://cdn.konserbilet.com/assets/blogs/dizimuzikleri.webp",
                        url: "/blog/efsane-unutulmayan-dizi-muzikleri",
                          },
                          { 
                            id: 16,
                            title: "En Popüler Düğün Şarkıları Nelerdir?",
                            description: "Düğünleri unutulmaz kılan özel detaylar arasında ilk sırada müzikler yer alır. İşte son yılların en popüler, eğlenceli ve romantik düğün şarkıları!",
                            image: "https://cdn.konserbilet.com/assets/blogs/dugunmuzikleri.webp",
                            url: "/blog/dugun-muzikleri",
                              },
                              { 
                                id: 17,
                                title: "En Çok Tercih Edilen Kına Şarkıları ve Müzikleri",
                                description: "Kına şarkıları çoğunlukla yöresel ve popüler eserlerden seçilir.  Düğün sürecine renk katan eşsiz şarkıları Konserbilet ile oluşturabilir ve dinleyebilirsin!",
                                image: "https://cdn.konserbilet.com/assets/blogs/kinagecesi.webp",
                                url: "/blog/kina-gecesi-muzikleri",
                                  },
                                  { 
                                    id: 18,
                                    title: "En İyi Rusça Şarkılar: Efsane Rusça Aşk Şarkıları",
                                    description: "Rusça müzik dinleyin! En iyi rusça şarkıları, efsane şarkıları, rusça aşk şarkılarını ve daha fazlasını keşfedin. Rus şarkıcıların sevilen müziklerini keşfedin.",
                                    image: "https://cdn.konserbilet.com/assets/blogs/ruscasarkilar.webp",
                                    url: "/blog/rusca-sarkilar",
                                      },
                                      { 
                                        id: 19,
                                        title: "Barış Manço Kimdir? Hayatı ve Hakkında Bilgiler",
                                        description: "Tarihe adını altın harflerle yazdırmış Barış Manço hakkında daha fazla bilgi edinmek için yazımızı okuyun! ",
                                        image: "https://cdn.konserbilet.com/assets/blogs/barismanco.webp",
                                        url: "/blog/baris-manco-hayati-hakkinda",
                                          },
    ];
  

    useEffect(() => {
      const reversedPosts = [...blogPosts].reverse();    
      const indexOfLastPost = currentPage * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const paginatedPosts = reversedPosts.slice(indexOfFirstPost, indexOfLastPost);
      setPaginatedPosts(paginatedPosts);
    }, [currentPage, postsPerPage]);
    


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
    scrollToTop();
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    scrollToTop();
  };
  const pageTitle = 'Konser ve Sanatçı Camiasına Dair Tüm Blog Yazıları - Konserbilet.com';
  const pageDescription = 'Konserbilet Blog ile gündemde olan konuları takip edebilir ve keşfedebilirsin. Yazarlarımız senin için en detaylı yazıları hazırlıyor.';
  const canonicalUrl = 'https://konserbilet.com/blog'; 
  const imageUrl = 'https://konserbilet.s3.eu-central-1.amazonaws.com/bloggorseller/anasayfablogmasaustu.png';
  
  
  const postVariants = {
    hidden: {
      opacity: 0,
      scale: 0.9,
    },
    visible: {
      opacity: 1,
      scale: 1,
    },
  };

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={imageUrl} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>
      <Header />

      <section className="bg-gradient-to-r from-purple-700 to-indigo-700 py-12">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-5xl text-white font-bold mb-4">KonserBilet Blog</h1>
          <p className="text-lg md:text-xl text-white max-w-2xl mx-auto">
            Konserbilet Blog ile gündemde olan konuları takip edebilir ve keşfedebilirsin. 
            Yazarlarımız senin için en detaylı yazıları hazırlıyor. İşte öne çıkan blog yazıları!
          </p>
        </div>
      </section>

      <section className="bg-gradient-to-r from-purple-700 to-indigo-700 py-4">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {paginatedPosts.map((post) => (
            <motion.div
              key={post.id}
              variants={postVariants}
              initial="hidden"
              animate="visible"
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <Link to={post.url}>
                <img src={post.image} alt={post.title} className="w-full h-48 object-cover" />
              </Link>
              <div className="p-4">
                <Link to={post.url}>
                  <h2 className="text-black text-lg md:text-xl font-bold mb-2">{post.title}</h2>
                </Link>
                <p className="text-gray-600">{post.description}</p>
                <Link to={post.url}>
                  <span className="block mt-4 text-purple-500 font-medium hover:text-purple-600">
                    Devamını Oku
                  </span>
                </Link>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>


      <section className="bg-gradient-to-r from-purple-700 to-indigo-700 py-12">
        <div className="flex justify-center mt-8">
          <button
            className="bg-purple-500 hover:bg-purple-600 text-white font-medium py-2 px-4 rounded-md mr-2"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Önceki Sayfa
          </button>
          <button
            className="bg-purple-500 hover:bg-purple-600 text-white font-medium py-2 px-4 rounded-md"
            onClick={handleNextPage}
            disabled={currentPage === Math.ceil(blogPosts.length / postsPerPage)}
          >
            Sonraki Sayfa
          </button>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default BlogPage;