import React from 'react';

const BlogSection = () => {
  const blogPosts = [
    {
      id: 2,
      title: 'İstanbul Anadolu Yakası En İyi Canlı Müzik Yapılan Restoranlar',
      description: 'İstanbul Anadolu Yakasıda canlı müzik eşliğinde keyifli bir akşam geçirebileceğiniz restoranları keşfedin.',
      image: 'https://cdn.konserbilet.com/assets/blogs/anadoluyakasicanlimuzik.webp',
      url: '/blog/anadolu-yakasi-canli-muzik',
    },
    {
      id: 1,
      title: 'Tribute Konser Ne Demek?',
      description: 'Tribute konserleri nedir ve ne anlama gelir? Bu yazımızda tribute konserlerini keşfedin.',
      image: 'https://cdn.konserbilet.com/assets/blogs/Tributekonser.webp',
      url: '/blog/tribute-konser-ne-demek',
    },
    {
      id: 3,
      title: 'İstanbul Avrupa Yakası En İyi Canlı Müzik Yapılan Restoranlar',
      description: 'İstanbul Avrupa Yakasıda canlı müzik eşliğinde keyifli bir akşam geçirebileceğiniz restoranları keşfedin.',
      image: 'https://cdn.konserbilet.com/assets/blogs/avrupayakasi.webp',
      url: '/blog/avrupa-yakasi-canli-muzik',
    },
  ];
  const blogLink = '/blog';
  const ctaDescription = 'Yazarlarımız senin için konser camiasının en önemli yazılarını yazıyor. En son haberler, ipuçları ve güncellemeleri burada bulabilirsin. Keşfetmek için tıkla!';
  const ctaButton = 'Blog Yazılarını Görüntüle';

  return (
    <section className="bg-gradient-to-r from-purple-700 to-indigo-700 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl text-center font-bold mb-8">
          <a href={blogLink} className="text-yellow-400 underline hover:no-underline">Blog</a>
        </h2>
        <p className="text-white text-center mb-8">{ctaDescription}</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {blogPosts.map((post) => (
            <div key={post.id} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <a href={post.url}>
                <img src={post.image} alt="Blog Post" className="w-full h-48 object-cover" />
              </a>
              <div className="p-4">
                <h3 className="text-lg md:text-xl font-bold mb-2">{post.title}</h3>
                <p className="text-gray-600 mb-4">{post.description}</p>
                <a
                  href={post.url}
                  className="bg-purple-500 hover:bg-purple-600 text-white py-2 px-4 rounded-lg transition-colors duration-300 ease-in-out"
                >
                  Daha Fazlası
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-8">
          <a href={blogLink}
            className="bg-purple-600 hover:bg-purple-600 text-white font-medium py-2 px-4 rounded-md">
            {ctaButton}
          </a>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
