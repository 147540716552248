import React from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ScrollIndicator from '../../components/ScrollIndicator';
import BlogSection1 from '../../components/BlogSection1';
import FaqSection from '../../components/FaqSection';

const BlogPostPage = () => {
  const pageTitle = 'Rana Türkyılmaz Kimdir? - KonserBilet';
  const pageDescription = 'Konserbilet Keşfet Yazılarının ilk konuğu Rana Türkyılmaz. Genç Rock Yıldızı adayı kaç yaşında? Hakkında ki tüm bilgileri keşfet.';
  const canonicalUrl = 'https://konserbilet.com/blog/rana-turkyilmaz-kimdir';
  const imageUrl = 'https://cdn.konserbilet.com/assets/blogs/ranaturkyilmazblog.webp';
  const author = 'Kaan Tabak';
  const publishDate = 'May 30, 2022';
  const tableOfContents = [
    { id: 'alamet', title: 'İlk Teklisi: Alamet' },
    { id: 'melek', title: 'İkinci Teklisi: Melek' },
    { id: 'yas', title: 'Rana Türkyılmaz Kaç Yaşında?' },
    { id: 'tiktok', title: "Rana Türkyılmaz ve TikTok" },
    { id: 'hedefler', title: 'Rana Türkyılmazın Hedefler büyük' }
  ];

  const customFaqs = [
    {
      question: 'Rana Türkyılmaz Kaç Yaşında?',
      answer: '22 Haziran 2000de Eskişehirde dünyaya gelen sanatçı 23 yaşında.'
    },
    {
      question: 'Rana Türkyılmaz hangi şarkı ile meşhur oldu?',
      answer: 'Rana Türkyılmaz, "Alamet" isimli şarkısı ile meşhur oldu. Bu şarkı, Rana Türkyılmazın profesyonel müzik hayatına adım attığı ilk eser olmuştur. Şarkının söz ve müziği Rana Türkyılmaz, Egemen Akkol ve Haktan Kabahel tarafından yapılmıştır ve düzenlemesi Sezer Dinç tarafından gerçekleştirilmiştir. Şarkının klibi, yayınlandığı günden itibaren büyük ilgi görmüş ve trend videolar listesinde yerini almıştır'
    },
    // Diğer soru-cevapları buraya ekleyin
  ];

  const sectionTitle = 'Rana Türkyılmaz Hakkında';


  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Header />
      <section className="py-16 bg-white">
  <div className="container mx-auto px-4">
    <div className="max-w-3xl mx-auto">

            <h1 className="text-3xl md:text-4xl font-bold mb-4">Yeni bir Rock Yıldızı, Güçlü Kadın Vokal: Rana Türkyılmaz Kimdir?</h1>
            <p className="text-gray-500 text-sm mb-4"> {author} tarafından {publishDate} tarihinde yayınlandı</p>
            <img src={imageUrl} alt={pageTitle} className="w-full h-auto mb-8" />

            <div className="row">
              <div className="w-5/5 pr-8">
                <div className="w-5/5">
                  <h2 className="text-lg font-bold mb-4">İçindekiler</h2>
                  <ul className="space-y-2">
                    {tableOfContents.map((item) => (
                      <li key={item.id}>
<ScrollLink to={item.id} smooth={true} duration={500} offset={-20} className="text-purple-500 hover:text-purple-600">
  {item.title}
</ScrollLink>

                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="container mx-auto py-8">
              <div className="prose max-w-none">
                <p>İleride çok başarılı, çok tanınacağına inandığımız genç sanatçı Rana Türkyılmaz hakkında bilgileri derledik.   Kariyerinin henüz başında olan Rana Türkyılmaz 11-12 Haziran 2022'de Maximum Uniq Açıkhava sahnesinde ilk konserlerinden birini verdi ve dinleyicileri etkiledi. Geçtiğimiz günlerde İstanbul Festivalinde sahne alan sanatçı Nilüfer – Son Arzum şarkısı ile beğeni topladı.</p>
                <h2 className="text-2xl font-bold mt-8" id="alamet">Rana Türkyılmaz'ın İlk Teklisi: Alamet</h2> <br></br>
                <p>  Rana ALL Dergisinden Olcay Bağır’a verdiği röportajda Alamet şarkısını yakın arkadaşı Haktan ile “biz neden şarkı yazmıyoruz” diyip aklına geldiği ilk kelimeler ile bestelemeye başladığını söylüyor.</p>

                <h2 className="text-2xl font-bold mt-8" id="melek">Rana Türkyılmaz'ın İkinci Teklisi: Melek</h2> <br></br>
                <p>  Sahilde oturuken yazılan şarkı tamamen spontane gelişiyor. Tutsak ve Melek kelimelerinden ortaya çıkan şarkı tamamen ve doğaçlama. Rana Türkyılmaz şarkının 10 Dakika içerisinde tamamlandığını belirtiyor. Kendi tabiri ile İmkansızlık, çaresizlik ve biraz da hüzün barındırıyor Melek.</p>

                <h2 className="text-2xl font-bold mt-8" id="yas">Rana Türkyılmaz Kaç Yaşında?</h2> <br></br>
                <p>  Genç sanatçı Rana Türkyılmaz henüz 18 Yaşında. </p>

                <h2 className="text-2xl font-bold mt-8" id="tiktok">Rana Türkyılmaz ve TikTok</h2> <br></br>
                <p>  Özgün, sesi güzel solist Rana Türkyılmaz şarkı söylemesinin, beste yapmasının yanında da sosyal medyayı aktif olarak kullanıyor. 
                  TikTok hesabında 2022 Ağustos itibari ile 500 binden fazla takipçisi mevcut.</p>

                <h2 className="text-2xl font-bold mt-8" id="hedefler">Rana Türkyılmaz'ın Hedefleri Büyük</h2> <br></br>
                <p>  Öncelikli hedefleri arasında konservatuara gitmek olan Sanatçı çok çalıştığını belirtiyor, umarım yaptığım şeylerin karşılığını gelecekte alırım diye ekliyor.</p>
              
                <h2 className="text-2xl font-bold mt-8">Sertab Erener Ve Rana Türkyılmaz</h2> <br></br>
                <p>  2022 Mart ayında TikTok’tan canlı yayınlanan Güçlü Kadınlar konser serisinde Türkiye’nin en başarılı kadın yorumcu, söz yazarı ve bestecilerinden Sertab Erener’in konserine Rana Türkyılmaz konuk sanatçı olarak katıldı.
                Sertab Erener’in sahnesine konuk olarak üç şarkıya eşlik eden Rana Türkyılmaz, canlı yayın esnasında ve sonrasında izleyicilerden olumlu tepkiler aldı.
                Yayın sonrası Sertab Erener ile hatıra fotoğrafı çektirerek sosyal medya hesabından paylaşan genç sanatçı Rana Türkyılmaz, paylaşımının altına şu notu düştü: ”Çok ilham verici bir buluşmaydı. Beraber şarkılar söylemek, mucizevi sesi canlı canlı duymak harikaydı."</p>
              </div>
            </div>
            <FaqSection faqs={customFaqs} sectionTitle={sectionTitle} />

            <div className="mt-8">
              <Link to="/blog" className="text-purple-500 font-medium hover:text-purple-600">
                &larr; Blog
              </Link>
            </div>
          </div>
        </div>
      </section>
      <BlogSection1 />
      <ScrollIndicator />
      <Footer />
    </div>
  );
};

export default BlogPostPage;
