import React, {useState} from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import FaqSection from '../../components/FaqSection';
import Breadcrumb from '../../components/Breadcrumb';
import SocialMedia from '../../components/SocialMedia';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TwitterShareButton, WhatsappShareButton } from 'react-share';
import { faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom'; 

const apiKey = process.env.REACT_APP_RAPIDAPI_KEY;


const HeyDouglas = () => {
  const [displayedConcerts, setDisplayedConcerts] = useState([]);
  const [concerts, setConcerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalConcerts, setTotalConcerts] = useState(0);  
  const ITEMS_PER_PAGE = 10;
  const [artistName] = useState('Hey Douglas!');
  const [infoMessage, setInfoMessage] = useState(null);

  const seoData = {
    title: `${artistName} Konser Takvimi & Hakkında - KonserBilet`,
    description: `Popüler Şarkıcı ${artistName} konser takvimini hemen görüntüle. ${artistName} hakkında bilinmeyen ve merak edilen soruların yanıtlarını konserbilet'te incele.`,
     ogUrl: "https://konserbilet.com/sanatcilar/hey-douglas",
    ogImage: "https://cdn.konserbilet.com/assets/artist/heydouglas.webp"
  };
  

  const breadcrumb = [
    { name: 'Ana Sayfa', link: '/' },
    { name: 'Sanatçılar', link: '/sanatcilar' },
    { name: `${artistName}`, link: null } 
  ];

  const [unknowns] = useState([
    {
      id: 1,
      content: "Hey! Douglas, aslında bir mahlas ve aynı zamanda bir proje adı. İstanbul’un eşsiz mimarisi, bu projenin yaratılmasında derin bir etki bıraktı, adeta şehrin Frankenstein’ı oldu."
    },
    {
      id: 2,
      content: "70’lerin müziğini modern elektronik ile harmanlayan Hey! Douglas, bu dönemin kuru reverb'lerini ve az karışan seslerini günümüze taşıyor. Bu, onun için anlamlı bir köprü kurma yöntemi."
    },
    {
      id: 3,
      content: "Hey! Douglas'ın müziği ilk ortaya çıktığında, insanlar bu özgün tarzı merakla karşıladı. Ekipmanlara bakarak bu sesin nereden geldiğini anlamaya çalıştılar, bir cihaz aradılar, ama bu sadece Hey! Douglas'ın sihirli dokunuşuydu."
    },
    {
      id: 4,
      content: "Müzik seçiminde, ilk zamanlar sevdiği şarkılara yer veren Hey! Douglas, sonrasında iyi sample verenlere ve dinleyicinin tepkilerine göre hareket etti. Bu süreç, onu Türk müziğinin daha derinlerine götürdü."
    },
    {
      id: 5,
      content: "Hey! Douglas, önümüzdeki dönemlerde 'Bim Bam Bom' gibi sevilen parçalarıyla karşımıza çıkacak. Ayrıca orijinal bestelerin olduğu EP’ler de yolda. Müzikseverler, sürprizlerle dolu bu yolculuğa hazır olsun!"
    },
    {
      id: 6,
      content: "Günümüz müzik dünyasını eleştiren Hey! Douglas, telif haklarının daha şeffaf olması gerektiğine inanıyor. Arşivlerde yatan şarkıların bu ülkenin değeri olduğunu ve bu değerleri gün yüzüne çıkaracak projelere destek verilmesi gerektiğini vurguluyor."
    },
    {
      id: 7,
      content: "Eğer yepyeni bir dünya kurma şansı olsa, Hey! Douglas, iyi bir liderin önemini vurguluyor ve Atatürk’ü geri çağırmak istediğini belirtiyor. Bu yeni dünyada deniz, ağaç, hayvan bol, insan az olurdu."
    },
    {
      id: 8,
      content: "Hey! Douglas'ın müziğinde 70'lerin funk ve soul ruhunu hissedersiniz. İlk albümü 'Marşandiz', ismini 'yük treni' anlamından alıyor, bu da sanatçının müziğindeki güçlü ve hareketli temayı yansıtıyor."
    },
    {
      id: 9,
      content: "Performansları sırasında, Hey! Douglas, sadece DJ seti kullanmaz; aynı zamanda seyircileriyle etkileşimde bulunur, onların enerjisini hisseder ve bu enerjiyi müziğine yansıtır."
    },
    {
      id: 10,
      content: "Müzikteki başarısının sırrı genç kalmak olan Hey! Douglas, başkalarını heyecanlandırabilen her insanın genç olduğuna inanır. O, müziğiyle insanlara umut verir ve onları heyecanlandırır."
    },
    {
      id: 11,
      content: "Müzik ve sanatın, özellikle zor zamanlarda, insanlara yaşama sevinci verdiğine inanan Hey! Douglas, İstanbul’un kültürel zenginliğini dünyaya tanıtmak için çabalıyor."
    },
    {
      id: 12,
      content: "Hey! Douglas, müziğin zaman ötesi olduğuna inanır. Sesin dilden önce geldiğini ve dile geldiğinde zamansız olduğunu düşünür. Onun için müzik, evrenselliği ve sonsuzluğu yakalama sanatıdır."
    },
    {
      id: 13,
      content: "Kriz dönemlerinde bile, Hey! Douglas, müziğin toplum için birleştirici bir güç olduğunu savunur. O, müziğin sadece eğlence olmadığını, aynı zamanda insanların bir araya gelmesini, öğrenmesini ve birbirleriyle empati kurmasını sağladığına inanır."
    },
    {
      id: 14,
      content: "Hey! Douglas, genç sanatçılara, sektördeki değişikliklere açık olmalarını ve değişimden korkmamalarını tavsiye eder. Ona göre, değişim sadece kaçınılmaz değil, aynı zamanda heyecan verici ve yenileyicidir."
    }
  ]);
  
  const formatDate = (dateStr) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    const date = new Date(dateStr);
    return date.toLocaleDateString('tr-TR', options).replace(',', ' -');
  };

  const getCurrentDateFormatted = (daysOffset = 0) => {
    const date = new Date();
    date.setDate(date.getDate() + daysOffset);
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
  const minDate = getCurrentDateFormatted(-1);
  const maxDate = getCurrentDateFormatted(240);

  const fetchConcerts = async () => {
    setLoading(true);
    setInfoMessage(null);  // bilgilendirme mesajını sıfırlayın
    const options = {
      method: 'GET',
      url: 'https://concerts-artists-events-tracker.p.rapidapi.com/artist',
      params: {
        name: 'Hey Douglas',
        minDate: minDate,
        maxDate: maxDate
      },
      headers: {
        'X-RapidAPI-Key': apiKey,
        'X-RapidAPI-Host': 'concerts-artists-events-tracker.p.rapidapi.com'
      }
    };
    try {
      const response = await axios.request(options);
      if (response.data.data.length === 0) {
setInfoMessage(`Maalesef, ${artistName} isimli sanatçının yaklaşmakta olan konser bilgisi bulunamadı.\nLütfen başka bir sanatçı için deneyin veya başka bir zaman yine gelin.`);
      } else {
          setConcerts(response.data.data);
          setDisplayedConcerts(response.data.data.slice(0, ITEMS_PER_PAGE));
          setTotalConcerts(response.data.data.length);
      }
  } catch (error) {
      console.error(error);
  }

  setLoading(false);
};

  const handleLoadMore = () => {
    const newDisplayedConcerts = concerts.slice(0, displayedConcerts.length + ITEMS_PER_PAGE);
    setDisplayedConcerts(newDisplayedConcerts);
  };


  const customFaqs = [
    {
      question: `${artistName} kaç yaşında?`,
      answer: `Emin Yasin Vural, bilinen sahne adıyla ${artistName}, 17 Kasım 1984 doğumlu ve şu anda 39 yaşında! Hala müziğe ve sanata olan tutkusuyla genç kalmayı başarıyor! 🎵`
    },
    {
      question: `${artistName} ne burcu?`,
      answer: `Gizemli ve kararlı bir akrep burcu olan ${artistName}, müziğindeki derinlik ve yoğunluğu burcunun bu özelliklerine borçlu olabilir! 🦂✨`
    },
    {
      question: `${artistName} Instagram hesabı nedir?`,
      answer: `${artistName}'ın Instagram kullanıcı adını merak ediyor musunuz? Onu @heydouglas hesabında bulabilir ve sanatsal yolculuğunun bir parçası olabilirsiniz! 📸`
    },
    {
      question: `${artistName} Twitter hesabı nedir?`,
      answer: `${artistName} Twitter'da da aktif! Onu @heydouglas kullanıcı adıyla bulabilir ve en son güncellemeleri, düşünceleri doğrudan ondan alabilirsiniz! 🐦`
    },
    {
      question: `${artistName} ne tür müzik yapıyor?`,
      answer: `${artistName}, pop, elektronik ve hip hop'un muhteşem bir karışımını sunar. 70'lerin psychedelic, funk ve soul şarkılarını modern dokunuşlarla yeniden hayat bulduruyor. Kesinlikle dinlemelisiniz! 🎶`
    },

    {
      question: `${artistName}’in en popüler şarkıları nelerdir?`,
      answer: `${artistName}'ın popüler şarkıları arasında 'Duruyor Dünya', 'Deterjan', 'Ayva Çiçek Açmış' ve 'Bim Bam Bom' gibi hitler yer alıyor. Ayrıca 2023'te çıkardığı 'Koma Baby', 'Bosstan', 'Sarıl Bana' ve diğerleri de oldukça sevildi. Hadi, bu ritimlere kendinizi bırakın ve ${artistName}'ın müziğinin tadını çıkarın! 🥳🎵`
    }
];


  
  const sectionTitle = `${artistName} Hakkında`;

  

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
<Helmet>
  <title>{seoData.title}</title>
  <meta name="description" content={seoData.description} />
  <meta property="og:title" content={seoData.title} />
  <meta property="og:description" content={seoData.description} />
  <meta property="og:type" content="website" />
  <meta property="og:url" content={seoData.ogUrl} />
  <meta property="og:image" content={seoData.ogImage} />
  <link rel="canonical" href={seoData.ogUrl} />
</Helmet>

      <Header />

      <Breadcrumb breadcrumb={breadcrumb} />
      <div className="bg-gradient-to-r from-purple-700 to-indigo-700">
  <div className="container mx-auto px-10">
    
    <h1 className="text-center text-yellow-400 text-4xl font-extrabold mb-4 pt-4 leading-tight tracking-wider">
    <strong>{artistName}</strong> Konser Takvimi
    </h1>
    <div className="flex flex-col md:flex-row items-center justify-between mb-6 mr-5">
      <div className="md:w-1/2 mb-4 md:mb-0">
      <img 
  src={seoData.ogImage} 
  alt={`${artistName} Hakkında`} 
  title={`${artistName} Yaklaşan Konserleri`}
  className="rounded-lg shadow-lg w-full mx-auto lg:max-w-lg" 
/>

      </div>
      <div className="md:w-1/2 flex items-center">
        <p className="text-white text-lg font-medium mx-auto leading-relaxed ml-5">
        Son zamanların popüler sanatçısı <strong>{artistName}</strong> konserlerini <strong>{artistName} konser takviminden</strong> göz atabilirsiniz. <br></br><br></br> Sahnede parlayan <strong>{artistName}</strong> isimli sanatçıya dair merak ettiğiniz soruların cevaplarını aşağıda bulabilirsiniz. <strong>{artistName}</strong> hakkında bilinmeyen bilgileri ve yaklaşmakta olan konserleri konserbilet ile anında görüntüle! <br></br> <br></br>Gelin, onun büyülü dünyasına birlikte adım atalım!</p>    
      </div>
    </div>

<br></br>
<h2 className="text-center text-yellow-400 text-4xl font-extrabold mb-4 leading-tight tracking-wider">Yaklaşmakta Olan <strong>{artistName}</strong> Konserleri</h2>
{infoMessage && (
    <div className="text-center text-white m-4">
        {infoMessage}
    </div>
)}
{!infoMessage && (  // infoMessage null veya undefined ise bu bloğu render et
    !loading && displayedConcerts.length === 0 ? (
        <div className="flex justify-center my-4">
          <button 
              className="bg-yellow-500 m-7 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded inline-flex items-center"
              onClick={fetchConcerts}
          >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5 4a1 1 0 011.993.117L7 4v8.904l4.804-.964a1 1 0 01.521.07l.084.04 4.1 2.05a1 1 0 01.014 1.68l-.014.008L7.416 18a1.001 1.001 0 01-1.03-1.674L11 12.63V5a1 1 0 011-1 1 1 0 011 1v8.528l3.728-.764-3.516-1.76a1 1 0 01-.52-.07l-.082-.041L6 5.871V5a1 1 0 01-1-1z" clipRule="evenodd" />
              </svg>
              Konserleri Görüntüle
          </button>
        </div>
      ) : (
        <>
          {loading ? (
            <div className="flex flex-col justify-center items-center mt-10">
              <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-yellow-300 mb-4" />
              <p className="text-yellow-100 mt-3 font-bold"><strong>{artistName}</strong> Konserleri yükleniyor...</p>
            </div>
          ) : (
            <div className="">
            <p className="text-center text-white m-4 leading-tight tracking-wider">
            <strong className="text-yellow-400">{artistName}</strong> isimli sanatçının yaklaşmakta olan <strong className="text-yellow-400">{totalConcerts} Adet</strong> konseri var! Dilediğin konseri seç, hemen arkadaşın ile paylaş!</p>
           <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 pt-9 pb-5">

            {displayedConcerts.map((concert, index) => (

  <motion.div 
    key={concert.startDate} 
    className="bg-white rounded-lg shadow-md hover:shadow-xl transition-scale duration-300 transform hover:scale-105 relative"  // 'relative' class'ını ekledim
    initial={{ opacity: 0, y: 50 }}  // Başlangıçta görünmez ve biraz aşağıda olacak
    animate={{ opacity: 1, y: 0 }}  // Animasyon sonunda görünür ve doğru konumda olacak
    transition={{ delay: index * 0.1 }}  // Her bir kartın animasyonu bir öncekinden 0.1 saniye sonra başlayacak
  >
    <div className="relative">
        <img 
            src={concert.image || 'https://cdn.konserbilet.com/assets/venue/anasayfasanatcilarmasaustu.png.webp'} 
            alt={concert.name} 
            className="w-full h-50 object-cover rounded-md mb-2 hover:scale-115 transition-scale duration-300" 
        />
        <div className="absolute top-0 left-0 w-full h-40 bg-black opacity-0  rounded-md transition-scale duration-300 "></div>
    </div>
    <h3 className="text-xl font-bold m-2">{concert.name}</h3>
    <p className="text-gray-700 m-2">{concert.location.name}</p>
    <p className="text-gray-700 m-2">{concert.location.address.addressLocality}</p>
    <p className="text-gray-500 m-2">{formatDate(concert.startDate)}</p>
    <div className="mb-2 flex space-x-6 justify-center">
  <TwitterShareButton
    url={window.location.href}
    title={`${concert.name} konseri,  - ${formatDate(concert.startDate)} - ${concert.location.name} sahnesinde! konserbilet.com ile  hemen görüntüle!`}
  >
    <div className="bg-blue-500 text-white p-1 rounded">
      <FontAwesomeIcon icon={faTwitter} />
    </div>
  </TwitterShareButton>
  <WhatsappShareButton
    url={window.location.href}
    title={`${concert.name} konseri,  - ${formatDate(concert.startDate)} - ${concert.location.name} sahnesinde! konserbilet.com ile  hemen görüntüle!`}
  >
    <div className="bg-green-500 text-white p-1 rounded">
      <FontAwesomeIcon icon={faWhatsapp} />
    </div>
  </WhatsappShareButton>
</div>


  </motion.div>
))}
                            </div>

              </div>
            )}
            {displayedConcerts.length < concerts.length && (
              <div className="flex justify-center m-4">
                <button className="bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded " onClick={handleLoadMore}>Daha Fazlası</button>
              </div>
            )}
    </> 
          )
          
    )}
    

<br></br>



<div className="p-8">
      <h3 className="text-yellow-400 text-3xl font-extrabold mb-4 tracking-wider text-xl mb-4"><strong>{artistName}</strong> Hakkında Bilinmeyen Şeyler</h3>
      <div className="flex flex-col">
        {unknowns.map(unknown => (
          <div key={unknown.id} className="flex items-start mb-8">
            <div className="flex-shrink-0">
              <div className="bg-yellow-400 text-white text-xl font-bold rounded-full w-12 h-12 flex items-center justify-center">
                {unknown.id}
              </div>
            </div>
            <p className="ml-6 text-white">{unknown.content}</p>
          </div>
        ))}
      </div>
    </div>

    <figure>
  <img 
    className="rounded-lg shadow-lg w-full mx-auto lg:max-w-lg" 
    src="https://kultur.istanbul/gorsel/2023/09/hey-douglas-pproje.jpg" 
    alt={artistName + " Hakkında"} // artistName değişkenini bir string ile birleştirerek kullanıyoruz
    title={artistName + " Konserleri"} // aynı şekilde burada da
    loading="lazy"
  />
  <figcaption className="mt-2 text-sm text-white-500 text-center">{artistName}</figcaption>
</figure>

      <FaqSection faqs={customFaqs} sectionTitle={sectionTitle} />
     
      <div className="mt-8">
              <Link to="/sanatcilar" className="text-blue-500 font-bold font-xl hover:text-blue-600">
                &larr; Sanatçılar
              </Link>
            </div>
     
      <div>      <SocialMedia />   </div>
        </div>
      </div>
      <Footer />
      </motion.div>
  );
};

export default HeyDouglas;
