import React from 'react';
import ConcertImage from '../assets/concert.png';
import { Link } from 'react-router-dom';

const HeroSection = () => {
  return (
    <section className="relative bg-gradient-to-r from-purple-700 to-indigo-700 overflow-hidden">
      <div className="container mx-auto px-4 py-16 md:py-24 lg:py-32">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div className="text-white space-y-8">
            <h1 className="text-5xl md:text-7xl font-extrabold mb-4 tracking-tight">
              Türkiye'nin Konser Ansiklopedisi
            </h1>
            <p className="text-xl md:text-2xl mb-8">
              Canlı müziğin büyüsünü keşfedin. En iyi konserleri, sanatçıları ve mekanları burada bulun.
            </p>
            <div className="flex space-x-6">
              <Link to="/takvim" className="bg-yellow-400 text-purple-800 rounded-full py-3 px-8 hover:bg-yellow-500 transition-colors duration-300">
                Takvim
              </Link>
              <Link to="/sanatcilar" className="bg-white text-purple-800 rounded-full py-3 px-8 hover:bg-gray-100 transition-colors duration-300">
                Sanatçıları Keşfet
              </Link>
            </div>
          </div>
          <div className="relative">
            <img
              src={ConcertImage}
              alt="Konser"
              className="w-full rounded-lg shadow-2xl transition-transform duration-500 hover:scale-110"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
