import React, { useRef } from 'react';
import { motion } from 'framer-motion';

const CitiesHomePageSection = () => {
  const scrollContainer = useRef(null);

  const scroll = (direction) => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollBy({
        top: 0,
        left: direction === 'left' ? -250 : 250, // Bu değeri değiştirerek kaydırma miktarını ayarlayabilirsiniz
        behavior: 'smooth',
      });
    }
  };

  const ctaDescription = 'Keşfetmek için İstanbul, Ankara ve İzmir şehirlerindeki konser etkinliklerini incele. Biletlerini hemen satın al!';
  const cities = [
    {
      id: 1,
      image: 'https://cdn.pixabay.com/photo/2020/02/23/12/22/river-4873198_640.jpg',
      title: 'İstanbul',
      description: 'Muhteşem konserlerin düzenlendiği bir şehir.',
      link: '/sehirler/istanbul',
    },
    {
      id: 2,
      image: 'https://cdn2.enuygun.com/media/lib/1290x430/uploads/image/ankara-40843.webp',
      title: 'Ankara',
      description: 'Eğlence dolu konserlerin yaşandığı bir şehir.',
      link: '/sehirler/ankara',
    },
    {
      id: 3,
      image: 'https://travelshelper.com/wp-content/uploads/2021/11/Izmir-Travel-Guide-Travel-S-Helper.jpg',
      title: 'İzmir',
      description: 'Unutulmaz anıların yaşandığı bir şehir.',
      link: '/sehirler/izmir',
    },
    {
      id: 4,
      image: 'https://iasbh.tmgrup.com.tr/d4e32d/0/0/0/0/0/0?u=https://isbh.tmgrup.com.tr/sb/album/2021/03/15/adana-hangi-risk-grubunda-ne-renk-turkiye-koronavirus-risk-haritasinda-adananin-risk-durumu-nedir-ve-degisti-mi-1615836645040.jpeg&mw=600&l=1', // Örnek bir bağlantı, gerçek bir resim URL'siyle değiştirin.
      title: 'Adana',
      description: 'Sıcak atmosferiyle bilinen, harika etkinliklerin gerçekleştiği bir şehir.',
      link: '/sehirler/adana',
    },
    {
      id: 5,
      image: 'https://samsun.bel.tr/thumb.php?p=uploads/sayfalar/8af59.png&w=1300', // Örnek bir bağlantı, gerçek bir resim URL'siyle değiştirin.
      title: 'Samsun',
      description: 'Yeşilin ve mavinin buluştuğu, müzikle birleşen bir şehir.',
      link: '/sehirler/samsun',
    },
    {
      id: 6,
      image: 'https://static.wixstatic.com/media/cc2edd_a5be73b8ca10436e89f3724699b71f3b~mv2.jpg/v1/fill/w_640,h_342,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/cc2edd_a5be73b8ca10436e89f3724699b71f3b~mv2.jpg', // Örnek bir bağlantı, gerçek bir resim URL'siyle değiştirin.
      title: 'Denizli',
      description: 'Eşsiz doğası ve özgün etkinlikleriyle ünlü bir şehir.',
      link: '/sehirler/denizli',
    },
    {
      id: 7,
      image: 'https://st3.depositphotos.com/30352990/33257/i/450/depositphotos_332579032-stock-photo-edirneturkey-6-may-2018-a.jpg', // Örnek bir bağlantı, gerçek bir resim URL'siyle değiştirin.
      title: 'Edirne',
      description: 'Tarihle iç içe geçen unutulmaz konserlere tanık olabileceğiniz bir şehir.',
      link: '/sehirler/edirne',
    },
  ];
  
  return (
    <section className="py-16 bg-gray-100 relative">
      <div className="container mx-auto px-12">
        <h2 className="text-3xl md:text-4xl text-center font-bold mb-8">Şehirler</h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-gray-700 text-center mb-4"
        >
          {ctaDescription}
        </motion.p>
        <div className="flex overflow-x-scroll hide-scroll-bar" ref={scrollContainer}>
          {cities.concat(cities).map((city, index) => (
            <motion.div
              key={index}
              className="bg-white rounded-lg shadow-lg overflow-hidden flex-none flex-shrink-0 m-6 w-60"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0 }}
              transition={{ delay: (index % cities.length + 1) * 0.2 }}
            >
              <a href={city.link}>
                <img
                  src={city.image}
                  alt={city.title}
                  className="w-full h-48 object-cover object-center"
                  loading={index <= 2 ? 'eager' : 'lazy'}
                />
                <div className="p-4">
                  <h3 className="text-lg md:text-xl font-bold mb-2 text-purple-500 hover:text-purple-600">{city.title}</h3>
                  <p className="text-gray-600 mb-4">{city.description}</p>
                  <span className="bg-purple-500 hover:bg-purple-600 text-white py-2 px-4 rounded-lg transition-colors duration-300 ease-in-out">
                    Konserleri İncele
                  </span>
                </div>
              </a>
            </motion.div>
          ))}
        </div>
        <button 
            className="absolute top-1/2 left-2 transform -translate-y-1/6 z-10 bg-purple-500 hover:bg-purple-600 text-white p-3 rounded-full shadow-md transition-colors duration-300 ease-in-out" 
            onClick={() => scroll('left')}>
            &#8592;
        </button>
        <button 
            className="absolute top-1/2 right-2 transform -translate-y-1/6 z-10 bg-purple-500 hover:bg-purple-600 text-white p-3 rounded-full shadow-md transition-colors duration-300 ease-in-out" 
            onClick={() => scroll('right')}>
            &#8594;
        </button>
      </div>
    </section>
    
  );
};

export default CitiesHomePageSection;