import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import SocialMedia from '../components/SocialMedia';

const NotFoundPage = () => {

  const pageTitle = 'Oops! Sayfa Bulunamadı - Konserbilet.com';
  const pageDescription = 'Aradığınız sayfa şu an konserde olabilir. Bu sayfada da bir şey yokki. Ana sayfaya dönmek için aşağıya tıklayın!';
  const canonicalUrl = 'https://konserbilet.com/404'; 
  const imageUrl = 'https://cdn.konserbilet.com/assets/other/notfound1.webp';

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
        <meta name="robots" content="noindex, follow" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={imageUrl} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>
      <Header />

      <section className="bg-gradient-to-r from-purple-700 to-indigo-700 py-12">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-5xl text-white font-bold mb-4">404 - Dat Dat! Sayfa Bulunamadı</h1>
    
          <img src={imageUrl} alt="Konser sahnesinde kaybolmuş sayfa" className="mx-auto my-8 w-1/2 md:w-1/3" />
          <p className="text-lg md:text-xl text-white max-w-2xl mx-auto ">
            Aradığınız sayfa şu an konserde olabilir ya da en sevdiği şarkıyı dinliyor olabilir. 🎶
          </p>
          <p className="text-lg md:text-xl text-white max-w-2xl mx-auto mb-8">
            Ana sayfaya dönmek için <a href="/" className="underline text-blue-300">buraya tıklayın</a>.
          </p>

          <div className="text-white mt-8">
            <p className="mb-4">
              Ya da bizi sosyal medya üzerinden takip edin! 🎸
            </p>
          </div>

          <div>
            <SocialMedia />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default NotFoundPage;
