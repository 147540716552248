import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import SocialMedia from '../components/SocialMedia';

const ContactPage = () => {
  const pageTitle = 'İletişim - Konserbilet.com';
  const pageDescription = 'Konserbilet ile iletişime geçmek için bu sayfayı kullanabilirsiniz.';
  const canonicalUrl = 'https://konserbilet.com/iletisim';
  const imageUrl = 'https://cdn.konserbilet.com/assets/other/iletisim.webp';

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={imageUrl} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>
      <Header />

      <section className="bg-gradient-to-r from-purple-700 to-indigo-700 py-12">
      <h1 className="text-4xl md-text-5xl text-white font-bold mb-6 text-center">İletişim</h1>
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="text-center md:text-center">
            <p className="text-lg md-text-xl text-white max-w-2xl mx-auto mb-6">
              Bize aşağıdaki iletişim bilgilerinden ulaşabilirsiniz.
            </p>
            <div className="text-white">
              <p className="mb-6">
                📞 Telefon: <a href="tel:05414609003" className="underline">0541 460 9003</a>
              </p>
              <p className="mb-6">
                ✉️ E-posta: <a href="mailto:kaantbk@gmail.com" className="underline">kaantbk@gmail.com</a>
              </p>
              <p className="mb-6">
                📸 Instagram: <a href="https://instagram.com/konserbiletcom" target="_blank" rel="noreferrer" className="underline">konserbiletcom</a>
              </p>
              <p className="mb-6">
                🌐 LinkedIn: <a href="https://linkedin.com/in/kaantabak" target="_blank" rel="noreferrer" className="underline">linkedin/kaantabak</a>
              </p>
            </div>
          </div>
          <div className="text-center">
            <img
              src={imageUrl}
              alt="İletişim"
              className="max-w-full h-auto rounded-lg"
            />
          </div>
        </div>
        <div>
          <SocialMedia />
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default ContactPage;
