import React, { useState } from 'react';
import { motion } from 'framer-motion';

const ArtistSection = () => {
  const ctaDescription =
    'Keşfetmek için sevdiğin sanatçıların konser etkinliklerini incele. Biletlerini hemen satın al!';

    const artists = [
      {
        id: 1,
        image: 'https://cdn.konserbilet.com/assets/artist/melikesahin.webp',
        title: 'Melike Şahin',
        description: "Türk müziğinin eşsiz seslerinden Melike Şahin, sahnede enerjisi ve içten performansıyla büyülüyor! Melike Şahin konserlerinin tarihlerini inceleyerek, hayalinizdeki geceye adım atmak için yerinizi ayırtın.",
        link: '/sanatcilar/melike-sahin',
      },
      {
        id: 2,
        image: 'https://cdn.konserbilet.com/assets/artist/sertaberener.webp',
        title: 'Sertab Erener',
        description: "Sertab Erener'in büyüleyici müziğiyle dolu unutulmaz bir gece için hazır olun! Konser programını keşfedin ve Sertab Erener'in muhteşem performansını yakından deneyimlemek için biletinizi şimdi alın!",
        link: '/sanatcilar/sertab-erener',
      },
      {
        id: 3,
        image: 'https://cdn.konserbilet.com/assets/artist/gunes.webp',
        title: 'Güneş',
        description: "Güneş'in enerjik sahne performansını kaçırmayın! Konser tarihlerini öğrenin ve bu eşsiz deneyimi yaşamak için biletinizi alın.",
        link: '/sanatcilar/gunes',
      },
      {
        id: 4,
        image: 'https://cdn.konserbilet.com/assets/artist/gazapizm.webp',
        title: 'Gazapizm',
        description: "Rap müziğin başarılı ismi Gazapizm, dinamik sahne performansıyla hayranlarını etkiliyor. Gazapizm konserlerini kaçırmamak için biletlerinizi hemen alın.",
        link: '/sanatcilar/gazapizm',
      },
      {
        id: 5,
        image: 'https://cdn.konserbilet.com/assets/artist/mahmutorhan.webp',
        title: 'Mahmut Orhan',
        description: "Elektronik müziğin yükselen ismi Mahmut Orhan, DJ setleriyle geceye renk katıyor. Konser tarihlerini öğrenin, kaçırmayın.",
        link: '/sanatcilar/mahmut-orhan',
      },
      {
        id: 6,
        image: 'https://cdn.konserbilet.com/assets/artist/heydouglas.webp',
        title: 'Hey Douglas!',
        description: "Elektronik müziğin özgün ismi Hey Douglas! ile müzik dolu bir geceye davetlisiniz. Konser bilgilerini kontrol edin ve biletinizi ayırtın.",
        link: '/sanatcilar/hey-douglas',
      },
      {
        id: 7,
        image: 'https://cdn.konserbilet.com/assets/artist/ceza.webp',
        title: 'Ceza',
        description: "Türk rap müziğinin öncülerinden Ceza'nın enerjisiyle dolu konserine hazır mısınız? Biletler hızla tükeniyor, hemen yerinizi ayırtın!",
        link: '/sanatcilar/ceza',
      },
      {
        id: 8,
        image: 'https://cdn.konserbilet.com/assets/artist/yildiztilbe.webp',
        title: 'Yıldız Tilbe',
        description: "Türk pop müziğinin duayen isimlerinden Yıldız Tilbe'nin unutulmaz şarkılarıyla dolu bir gece sizi bekliyor. Biletlerinizi hemen alın!",
        link: '/sanatcilar/yildiz-tilbe',
      },
      {
        id: 9,
        image: 'https://cdn.konserbilet.com/assets/artist/kofn.webp',
        title: 'KÖFN',
        description: "Alternatif müziğin yeni seslerinden KÖFN'ün canlı performansına tanıklık etmek için konser takvimine göz atın ve biletinizi alın.",
        link: '/sanatcilar/kofn',
      },
      {
        id: 10,
        image: 'https://cdn.konserbilet.com/assets/artist/altingun.webp',
        title: 'Altın Gün',
        description: "Psychedelic rock'ın Türkiye'deki temsilcilerinden Altın Gün'ün enerjisiyle dolu konserini kaçırmayın.",
        link: '/sanatcilar/altin-gun',
      },
      {
        id: 11,
        image: 'https://cdn.konserbilet.com/assets/artist/yyk.webp',
        title: 'Yüzyüzeyken Konuşuruz',
        description: "Indie müziğin sevilen gruplarından Yüzyüzeyken Konuşuruz Konser detaylarını inceleyin ve biletinizi alın.",
        link: '/sanatcilar/yuzyuzeyken-konusuruz',
      },
      {
        id: 12,
        image: 'https://cdn.konserbilet.com/assets/artist/madrigal.webp',
        title: 'Madrigal',
        description: "Madrigal'ın özgün müzik tarzı ve etkileyici sahne performansı sizi bekliyor. Konser tarihlerini öğrenin ve bu deneyimi kaçırmayın.",
        link: '/sanatcilar/madrigal',
      },
      {
        id: 13,
        image: 'https://cdn.konserbilet.com/assets/artist/mabelmatiz.webp',
        title: 'Mabel Matiz',
        description:
          "Mabel Matiz hayranlarına müjde! En sevilen Türk pop şarkıcısı Mabel Matiz'in canlı performanslarına tanıklık etmek için konser takvimine göz atın ve yerinizi hemen ayırtın!",
        link: '/sanatcilar/mabel-matiz',
      },
      {
        id: 14,
        image: 'https://cdn.konserbilet.com/assets/artist/mertdemir.jpeg',
        title: 'Mert Demir',
        description:
          "Mert Demir hayranlarına müjde! Antideprasan şarkısı ile gönüllere giren Mert Demir'in canlı performanslarına tanıklık etmek için konser takvimine göz atın ve yerinizi hemen ayırtın!",
        link: '/sanatcilar/mert-demir',
      },
  ];

  const [searchText, setSearchText] = useState('');

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const filteredArtists = artists.filter((artist) => {
    const lowerCaseSearchText = searchText.toLowerCase();
    const isTitleMatch = artist.title.toLowerCase().includes(lowerCaseSearchText);

    return isTitleMatch || searchText === '';
  });
  


  return (
    <section className="py-4 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl text-center font-bold mb-8">Sanatçılar</h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-gray-700 text-center mb-4"
        >
          {ctaDescription}
        </motion.p>

        <div className="flex justify-center mb-8">
          {/* Search bar */}
          <input
            type="text"
            placeholder="Sanatçı ara..."
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            className="p-2 rounded-lg border border-gray-300"
          />
        </div>

        <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 lg:grid-cols-4">
          {filteredArtists.map((artist) => (
            <motion.div
              key={artist.id}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <a href={artist.link}>
                <img
                  src={artist.image}
                  alt={artist.title}
                  className="w-full flex flex-col object-cover object-center"
                />
              </a>
              <div className="p-4 justify-between h-full">
                <h3 className="text-lg md:text-xl font-bold mb-2">
                  <a href={artist.link} className="text-yellow-400 hover:text-yellow-700">
                    {artist.title}
                  </a>
                </h3>
                <p className="text-gray-600 mb-4 line-clamp-4">{artist.description}</p>
                <a
                  href={artist.link}
                  className="bg-purple-500 hover:bg-purple-600 text-white py-2 px-4 rounded-lg transition-colors duration-300 ease-in-out block text-center"
                >
                  Konserleri İncele
                </a>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ArtistSection;