// HomePage.js
import React, { Suspense } from 'react';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import Carousels from '../components/Carousels.js';
import FaqSection from '../components/FaqSection.js';
import Footer from '../components/Footer';
import BlogSection from '../components/BlogSection.js';
import CitiesHomePageSection from '../components/CityHomePage.js';
import { Helmet } from 'react-helmet';


const HomePage = () => {
  const customFaqs = [
    {
      question: 'Konserbilet Nedir?',
      answer: "Konserbilet sitesi kullanıcıların aradığı konserleri bulmasını kolaylaştırmak amacı ile KB Digital tarafından geliştirilmiştir. Tamamen ücretsiz bir platformdur."
    },
    {
      question: "Konserbilet Nasıl Çalışır?",
      answer: "İstanbul – İzmir – Ankara. Her bir şehrin tüm mekanları ve sanatçılarının sayfası mevcuttur. Bu sayfalar ile yaklaşan konserler görüntülenebilmektedir. Editörler tarafından günlük olarak konserler takvime eklenmektedir. "
    },
    {
      question: "Konserbilet Avantajları Nelerdir?",
      answer: " Hızlı ve kolay arayüzü sayesinde onlarca site arasında kaybolmaktan kurtarır. Tek bir sayfada tüm yaklaşan konserleri görüntüler. "
    },
    
  ];

  const pageTitle = 'Türkiyenin Konser Ansiklopedisi - Konserbilet';
  const pageDescription = 'Dilediğin sanatçıyı, şehri, mekanı seç, yaklaşmakta olan konserleri görüntüle. Yüzlerce konser arasından aradığın konseri kolayca bul.';
  const canonicalUrl = 'https://konserbilet.com'; 
  const imageUrl = 'https://konserbilet.s3.eu-central-1.amazonaws.com/faq.png';
  

  return (
    <div>

              <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={imageUrl} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>      

      <Header />
      <HeroSection />
      <Suspense fallback={<div>Yükleniyor...</div>}>
        <Carousels />
        <FaqSection faqs={customFaqs} />
        <CitiesHomePageSection />
        <BlogSection />
      </Suspense>
      <Footer />
    </div>
  );
};

export default HomePage;
