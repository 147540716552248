import React, {useState} from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import FaqSection from '../../components/FaqSection';
import Breadcrumb from '../../components/Breadcrumb';
import SocialMedia from '../../components/SocialMedia';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TwitterShareButton, WhatsappShareButton } from 'react-share';
import { faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom'; 

const apiKey = process.env.REACT_APP_RAPIDAPI_KEY;


const Edirne = () => {
  const [displayedConcerts, setDisplayedConcerts] = useState([]);
  const [concerts, setConcerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalConcerts, setTotalConcerts] = useState(0);  
  const ITEMS_PER_PAGE = 10;
  const [locationName] = useState('Edirne');
  const [infoMessage, setInfoMessage] = useState(null);

  const seoData = {
    title: `${locationName} Yaklaşmakta Olan Konser Takvimi - KonserBilet`,
    description: ` ${locationName} konser takvimini hemen görüntüle. ${locationName} şehrindeki konserler hakkında bilinmeyen ve merak edilen soruların yanıtlarını konserbilet'te hemen incele.`,
     ogUrl: "https://konserbilet.com/sehirler/edirne",
    ogImage: "https://cdn.konserbilet.com/assets/location/edirne.webp"
  };
  

  const breadcrumb = [
    { name: 'Ana Sayfa', link: '/' },
    { name: 'Şehirler', link: '/sehirler' },
    { name: `${locationName}`, link: null } 
  ];

  
  const formatDate = (dateStr) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    const date = new Date(dateStr);
    return date.toLocaleDateString('tr-TR', options).replace(',', ' -');
  };

  const getCurrentDateFormatted = (daysOffset = 0) => {
    const date = new Date();
    date.setDate(date.getDate() + daysOffset);
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
  const minDate = getCurrentDateFormatted(-1);
  const maxDate = getCurrentDateFormatted(240);

  const fetchConcerts = async () => {
    setLoading(true);
    setInfoMessage(null);  // bilgilendirme mesajını sıfırlayın
    const options = {
      method: 'GET',
      url: 'https://concerts-artists-events-tracker.p.rapidapi.com/location',
      params: {
        name: 'Edirne',
        minDate: minDate,
        maxDate: maxDate
      },
      headers: {
        'X-RapidAPI-Key': apiKey,
        'X-RapidAPI-Host': 'concerts-artists-events-tracker.p.rapidapi.com'
      }
    };
    try {
      const response = await axios.request(options);
      if (response.data.data.length === 0) {
setInfoMessage(`Maalesef, ${locationName} isimli şehirde yaklaşmakta olan konser bilgisi bulunamadı.\nLütfen başka bir şehir için deneyin veya başka bir zaman yine gelin.`);
      } else {
          setConcerts(response.data.data);
          setDisplayedConcerts(response.data.data.slice(0, ITEMS_PER_PAGE));
          setTotalConcerts(response.data.data.length);
      }
  } catch (error) {
      console.error(error);
  }

  setLoading(false);
};

  const handleLoadMore = () => {
    const newDisplayedConcerts = concerts.slice(0, displayedConcerts.length + ITEMS_PER_PAGE);
    setDisplayedConcerts(newDisplayedConcerts);
  };



  const customFaqs = [
    {
        question: "Edirne'de en popüler konser mekanları neler?",
        answer: "Edirne'de konser vermek için birkaç harika mekan var! Saraçlar Caddesi, muhteşem atmosferi ile bilinir ve genelde yerel müzisyenlerin konserlerine ev sahipliği yapar. Edirne Kültür ve Sanat Merkezi ise çeşitli etkinlikler ve konserler için mükemmel bir seçenektir. Unutmayın, Edirne'nin müzik sahnesi her zaman canlı ve heyecan vericidir, o yüzden yeni ve yetenekli sanatçıları keşfetmek için kulaklarınızı açık tutun!"
    },
    {
        question: "Edirne konserlerinde park yeri bulabilir miyim?",
        answer: "Edirne konser mekanlarının bazıları kendi otopark alanlarını sunarken, bazıları için park yeri bulmak biraz daha zor olabilir. Erken gelmek her zaman iyi bir fikirdir. Eğer şansınıza güveniyorsanız, bir park yeri kapabilirsiniz, ya da toplu taşıma veya taksi seçeneklerini düşünebilirsiniz. Hey, belki de Edirne'nin güzelliklerini yürüyerek keşfetmek istersiniz, kim bilir?"
    },
    {
        question: "Edirne konser mekanlarında yemek ve içecek seçeneği var mı?",
        answer: "Edirne'nin konser mekanları genelde atıştırmalıklar ve içecekler sunar, ama ciddi bir açlık için yan taraftaki restoranlara ve kafelere göz atmanızı öneririz. Dışarıdan yiyecek ve içecek getirmek genellikle kabul edilmez, o yüzden cebinizdeki sandviçi evde bırakın ve yerel lezzetleri deneyin. Edirne'nin lezzetli ciğerini denemeden dönmeyin!"
    },
    {
        question: "Edirne Konserleri Nerede Sahneleniyor?",
        answer: "Edirne konserleri genellikle Saraçlar Caddesi veya Edirne Kültür ve Sanat Merkezi gibi mekanlarda sahnelenir. Şehir, tarihi ve kültürel atmosferi ile bilinir, bu yüzden konserler genellikle bu tür mekanlarda gerçekleşir. Ve tabii ki, Edirne'nin canlı müzik sahnesi sizi hayal kırıklığına uğratmayacak!"
    },
    {
        question: "Edirne Konserlerine Ulaşım Nasıl Sağlanıyor?",
        answer: "Edirne şehir merkezinden toplu taşıma veya taksi ile konser mekanlarına kolayca ulaşabilirsiniz. Eğer biraz daha maceraperestseniz, Edirne'nin dar ve tarihi sokaklarını bisikletle keşfetmek harika bir fikir olabilir. Yolculuğunuzun tadını çıkarın ve Edirne'nin büyüleyici atmosferini soluyarak konserinize ulaşın!"
    },
    {
        question: "Edirne Konserleri Yazın Nerelerde Sahneleniyor?",
        answer: "Yaz aylarında Edirne konserleri genellikle açık hava mekanlarında, özellikle Saraçlar Caddesi'nde sahnelenir. Yaz akşamlarının serinliğinde, yıldızların altında canlı müzik dinlemenin keyfini çıkarın. Ve belki bir yıldız kayarken dilek tutmayı unutmayın!"
    },
    {
        question: "Edirne Konserleri Kışın Nerelerde Sahneleniyor?",
        answer: "Kış aylarında, Edirne Kültür ve Sanat Merkezi gibi kapalı mekanlar konserler için tercih edilir. Soğuk kış günlerinde sıcacık bir konser salonunda canlı müzik dinlemenin keyfini çıkarın. Ve belki kar taneleri eşliğinde bir konser deneyimi yaşayabilirsiniz, kim bilir?"
    },
    {
        question: "Edirne Konserleri Süresince Nerede Konaklayabilirim?",
        answer: "Edirne'de konserler süresince konaklayabileceğiniz birçok sevimli otel, pansiyon ve tarihi konak bulabilirsiniz. Müziğin ve konforun tadını çıkarmak için ideal bir durak olan Edirne, sizi bekliyor. Rezervasyonunuzu erken yapın ve Edirne'nin misafirperverliğinin tadını çıkarın!"
    }
];


  

  
  const sectionTitle = `${locationName} Konserleri Hakkında`;

  

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
<Helmet>
  <title>{seoData.title}</title>
  <meta name="description" content={seoData.description} />
  <meta property="og:title" content={seoData.title} />
  <meta property="og:description" content={seoData.description} />
  <meta property="og:type" content="website" />
  <meta property="og:url" content={seoData.ogUrl} />
  <meta property="og:image" content={seoData.ogImage} />
  <link rel="canonical" href={seoData.ogUrl} />
</Helmet>

      <Header />

      <Breadcrumb breadcrumb={breadcrumb} />
      <div className="bg-gradient-to-r from-purple-700 to-indigo-700">
  <div className="container mx-auto px-10">
    
    <h1 className="text-center text-yellow-400 text-4xl font-extrabold mb-4 pt-4 leading-tight tracking-wider">
    <strong>{locationName}</strong> Konser Takvimi
    </h1>
    <div className="flex flex-col md:flex-row items-center justify-between mb-6 mr-5">
      <div className="md:w-1/2 mb-4 md:mb-0">
      <img 
  src={seoData.ogImage} 
  alt={`${locationName} Hakkında`} 
  title={`${locationName} Yaklaşan Konserleri`}
  className="rounded-lg shadow-lg w-full mx-auto lg:max-w-lg" 
/>

      </div>
      <div className="md:w-1/2 flex items-center">
    <p className="text-white text-lg font-medium mx-auto leading-relaxed ml-5">
        {locationName}'nin benzersiz doğal güzellikleri ve sıcakkanlı insanları, {locationName} şehrini ziyaret edenler için unutulmaz anlar yaratıyor.<br></br><br></br>  Yaklaşmakta olan {locationName} konserleri, {locationName} şehrinin enerjisinin ve kültürel çeşitliliğinin bir göstergesi.  {locationName} konser takvimi ile planlarınızı önceden yapabilir ve biletlerinizi kolayca satın alabilir, arkadaşlarınız ile paylaşabilirsiniz. <br></br><br></br> Konserbilet üzerinden {locationName} şehrindeki yaklaşmakta olan konserleri ve etkinlikleri görüntüleyebilir, böylece bu müzikal şölenin bir parçası olabilirsiniz. Gelin, bu etkinlikler vasıtasıyla {locationName} şehrinin büyülü dünyasını keşfedin ve şehrin ruhunu birlikte hissedelim! 🎉
    </p>
</div>
</div>



<br></br>
<h2 className="text-center text-yellow-400 text-4xl font-extrabold mb-4 leading-tight tracking-wider">Yaklaşmakta Olan <strong>{locationName}</strong> Konserleri</h2>
{infoMessage && (
    <div className="text-center text-white m-4">
        {infoMessage}
    </div>
)}
{!infoMessage && (  // infoMessage null veya undefined ise bu bloğu render et
    !loading && displayedConcerts.length === 0 ? (
        <div className="flex justify-center my-4">
          <button 
              className="bg-yellow-500 m-7 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded inline-flex items-center"
              onClick={fetchConcerts}
          >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5 4a1 1 0 011.993.117L7 4v8.904l4.804-.964a1 1 0 01.521.07l.084.04 4.1 2.05a1 1 0 01.014 1.68l-.014.008L7.416 18a1.001 1.001 0 01-1.03-1.674L11 12.63V5a1 1 0 011-1 1 1 0 011 1v8.528l3.728-.764-3.516-1.76a1 1 0 01-.52-.07l-.082-.041L6 5.871V5a1 1 0 01-1-1z" clipRule="evenodd" />
              </svg>
              Konserleri Görüntüle
          </button>
        </div>
      ) : (
        <>
          {loading ? (
            <div className="flex flex-col justify-center items-center mt-10">
              <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-yellow-300 mb-4" />
              <p className="text-yellow-100 mt-3 font-bold"><strong>{locationName}</strong> Konserleri yükleniyor...</p>
            </div>
          ) : (
            <div className="">
            <p className="text-center text-white m-4 leading-tight tracking-wider">
            <strong className="text-yellow-400">{locationName}</strong> şehrinde yaklaşmakta olan <strong className="text-yellow-400">{totalConcerts} Adet</strong> konseri var! Dilediğin konseri seç, hemen arkadaşın ile paylaş!</p>
           <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 pt-9 pb-5">

            {displayedConcerts.map((concert, index) => (

  <motion.div 
    key={concert.startDate} 
    className="bg-white rounded-lg shadow-md hover:shadow-xl transition-scale duration-300 transform hover:scale-105 relative"  // 'relative' class'ını ekledim
    initial={{ opacity: 0, y: 50 }}  // Başlangıçta görünmez ve biraz aşağıda olacak
    animate={{ opacity: 1, y: 0 }}  // Animasyon sonunda görünür ve doğru konumda olacak
    transition={{ delay: index * 0.1 }}  // Her bir kartın animasyonu bir öncekinden 0.1 saniye sonra başlayacak
  >
    <div className="relative">
        <img 
            src={concert.image || 'https://cdn.konserbilet.com/assets/venue/anasayfasanatcilarmasaustu.png.webp'} 
            alt={concert.name} 
            className="w-full h-50 object-cover rounded-md mb-2 hover:scale-115 transition-scale duration-300" 
        />
        <div className="absolute top-0 left-0 w-full h-40 bg-black opacity-0  rounded-md transition-scale duration-300 "></div>
    </div>
    <h3 className="text-xl font-bold m-2">{concert.name}</h3>
    <p className="text-gray-700 m-2">{concert.location.name}</p>
    <p className="text-gray-700 m-2">{concert.location.address.addressLocality}</p>
    <p className="text-gray-500 m-2">{formatDate(concert.startDate)}</p>
    <div className="mb-2 flex space-x-6 justify-center">
  <TwitterShareButton
    url={window.location.href}
    title={`${concert.name} konseri,  - ${formatDate(concert.startDate)} - ${concert.location.name} sahnesinde! konserbilet.com ile  hemen görüntüle!`}
  >
    <div className="bg-blue-500 text-white p-1 rounded">
      <FontAwesomeIcon icon={faTwitter} />
    </div>
  </TwitterShareButton>
  <WhatsappShareButton
    url={window.location.href}
    title={`${concert.name} konseri,  - ${formatDate(concert.startDate)} - ${concert.location.name} sahnesinde! konserbilet.com ile  hemen görüntüle!`}
  >
    <div className="bg-green-500 text-white p-1 rounded">
      <FontAwesomeIcon icon={faWhatsapp} />
    </div>
  </WhatsappShareButton>
</div>


  </motion.div>
))}
                            </div>

              </div>
            )}
            {displayedConcerts.length < concerts.length && (
              <div className="flex justify-center m-4">
                <button className="bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded " onClick={handleLoadMore}>Daha Fazlası</button>
              </div>
            )}
    </> 
          )
          
    )}
    

<br></br>


    <figure>
  <img 
    className="rounded-lg shadow-lg w-full mx-auto lg:max-w-lg" 
    src="https://static.wixstatic.com/media/cc2edd_a5be73b8ca10436e89f3724699b71f3b~mv2.jpg/v1/fill/w_640,h_342,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/cc2edd_a5be73b8ca10436e89f3724699b71f3b~mv2.jpg" 
    alt={locationName + " Hakkında"} // locationName değişkenini bir string ile birleştirerek kullanıyoruz
    title={locationName + " Konserleri"} // aynı şekilde burada da
    loading="lazy"
  />
  <figcaption className="mt-2 text-sm text-white-500 text-center">{locationName}</figcaption>
</figure>

      <FaqSection faqs={customFaqs} sectionTitle={sectionTitle} />
     
      <div className="mt-8">
              <Link to="/sehirler" className="text-blue-500 font-bold font-xl hover:text-blue-600">
                &larr; Şehirler
              </Link>
            </div>
     
      <div>      <SocialMedia />   </div>
        </div>
      </div>
      <Footer />
      </motion.div>
  );
};

export default Edirne;
