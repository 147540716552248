import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios'; 
import Blog from './pages/Blog';
import HomePage from './pages/HomePage';
import RanaTurkyilmaz from './pages/blog/rana-turkyilmaz-kimdir';
import AnadoluYakasi from './pages/blog/istanbul-anadolu-yakasi-en-iyi-canli-muzik';
import AvrupaYakasi from './pages/blog/istanbul-avrupa-yakasi-en-iyi-canli-muzik';
import MagusaLimani from './pages/blog/magusa-limani-turkusunun-hikayesi';
import SariGelin from './pages/blog/sari-gelin-turkusunun-hikayesi';
import TributeKonser from './pages/blog/tribute-konser-ne-demek';
import YaliCapkini from './pages/blog/yali-capkini-muzikleri';
import ColdplayBlog from './pages/blog/coldplayblog';
import YolculukSarkilari from './pages/blog/yolculuksarkilari';
import MuzikEtkileri from './pages/blog/muziketkileri.js';
import BarisManco from './pages/blog/barismanco.js';
import BebekMuzikleri from './pages/blog/bebekmuzikleri.js';
import DiziMuzikleri from './pages/blog/dizimuzikleri.js';
import DugunMuzikleri from './pages/blog/dugunmuzikleri.js';
import KinaMuzikleri from './pages/blog/kinamuzikleri.js';
import RuscaSarkilar from './pages/blog/ruscasarkilar.js';
import SesGuzellestirme from './pages/blog/sesguzellestirme.js';
import YeniYilMuzikleri from './pages/blog/yeniyilmuzikleri.js';
import KitapOkuma from './pages/blog/kitapokumamuzikleri.js';
import ScrollToTopButton from './components/ScrollToTopButton';
import Takvim from './pages/takvim';
import Sehirler from './pages/sehirler';
import Sahneler from './pages/sahneler';
import Sanatcilar from './pages/sanatcilar';
import SSS from './pages/SSS.js';
import MaximumUniq from './pages/sahneler/maksimum-uniq.js';
import HarbiyeAcikhava from './pages/sahneler/harbiye-acikhava.js';
import Istanbul from './pages/sehirler/istanbul.js';
import Ankara from './pages/sehirler/ankara.js';
import Izmir from './pages/sehirler/izmir.js';
import Samsun from './pages/sehirler/samsun.js';
import Edirne from './pages/sehirler/edirne.js';
import Adana from './pages/sehirler/adana.js';
import Denizli from './pages/sehirler/denizli.js';
import MelikeSahin from './pages/sanatcilar/melike-sahin.js';
import MabelMatiz from './pages/sanatcilar/mabel-matiz.js';
import SertabErener from './pages/sanatcilar/sertab-erener.js';
import AltinGun from './pages/sanatcilar/altin-gun.js';
import MertDemir from './pages/sanatcilar/mert-demir.js';
import Ceza from './pages/sanatcilar/ceza.js';
import Gazapizm from './pages/sanatcilar/gazapizm.js';
import Gunes from './pages/sanatcilar/gunes.js';
import HeyDouglas from './pages/sanatcilar/hey-douglas.js';
import KOFN from './pages/sanatcilar/kofn.js';
import Madrigal from './pages/sanatcilar/madrigal.js';
import MahmutOrhan from './pages/sanatcilar/mahmut-orhan.js';
import YildizTilbe from './pages/sanatcilar/yildiz-tilbe.js';
import YuzyuzeykenKonusuruz from './pages/sanatcilar/yuzyuzeyken-konusuruz.js';
import JollyJokerKartal from './pages/sahneler/jolly-joker-kartal.js';
import CesmeAcikhavaTiyatrosu from './pages/sahneler/cesme-acikhava-tiyatrosu.js';
import HayalKahvesiAlacati from './pages/sahneler/hayal-kahvesi-alacati.js';
import IFArmadaAVMAcikhava from './pages/sahneler/if-armada-avm-acikhava.js';
import IFPerformanceHallBesiktas from './pages/sahneler/if-performance-hall-besiktas.js';
import IzmirArena from './pages/sahneler/izmir-arena.js';
import JollyJokerAlacati from './pages/sahneler/jolly-joker-alacati.js';
import KurucesmeParaf from './pages/sahneler/kurucesme-paraf.js';
import ManoDelSolCesme from './pages/sahneler/mano-del-sol-cesme.js';
import MilyonPerformanceHall from './pages/sahneler/milyon-performance-hall.js';
import ODTÜMDVisnelik from './pages/sahneler/odtu-md-visnelik.js';
import TurkcellVadi from './pages/sahneler/turkcell-vadi.js';
import ZorluPSMTurkcell from './pages/sahneler/zorlu-psm-turkcell.js';
import KKKavaklidere from './pages/sahneler/645kk-kavaklidere.js';
import ScrollToTop from './components/ScrollToTop.js';
import ContactPage from './pages/iletisim';
import NotFound from './pages/notfound';


export const routes = [
  { path: "/", component: HomePage },
  { path: "/blog", component: Blog },
  { path: "/takvim", component: Takvim },
  { path: "/sehirler", component: Sehirler },
  { path: "/sahneler", component: Sahneler },
  { path: "/sanatcilar", component: Sanatcilar },
  { path: "/sss", component: SSS },
  { path: "/iletisim", component: ContactPage },
  { path: "/blog/rana-turkyilmaz-kimdir", component: RanaTurkyilmaz },
  { path: "/blog/anadolu-yakasi-canli-muzik", component: AnadoluYakasi },
  { path: "/blog/avrupa-yakasi-canli-muzik", component: AvrupaYakasi },
  { path: "/blog/magusa-limani-turkusunun-hikayesi", component: MagusaLimani },
  { path: "/blog/sari-gelin-turkusunun-hikayesi", component: SariGelin },
  { path: "/blog/tribute-konser-ne-demek", component: TributeKonser },
  { path: "/blog/yali-capkini-dizi-muzikleri", component: YaliCapkini },
  { path: "/blog/coldplay-grubu-ve-konserleri-hakkinda", component: ColdplayBlog },
  { path: "/blog/yolculuk-sarkilari", component: YolculukSarkilari },
  { path: "/blog/muzik-etkileri", component: MuzikEtkileri },
  { path: "/blog/baris-manco-hayati-hakkinda", component: BarisManco },
  { path: "/blog/bebek-sarkilari", component: BebekMuzikleri },
  { path: "/blog/efsane-unutulmayan-dizi-muzikleri", component: DiziMuzikleri },
  { path: "/blog/kina-gecesi-muzikleri", component: KinaMuzikleri },
  { path: "/blog/dugun-muzikleri", component: DugunMuzikleri },
  { path: "/blog/rusca-sarkilar", component: RuscaSarkilar },
  { path: "/blog/ses-guzellestirme-ses-acma-teknikleri", component: SesGuzellestirme },
  { path: "/blog/yeniyil-yilbasi-muzikleri", component: YeniYilMuzikleri },
  { path: "/blog/kitap-okuma-muzikleri", component: KitapOkuma },
  { path: "/istanbul/maksimum-uniq", component: MaximumUniq },
  { path: "/istanbul/harbiye-acikhava", component: HarbiyeAcikhava },
  { path: "/sehirler/istanbul", component: Istanbul },
  { path: "/sehirler/ankara", component: Ankara },
  { path: "/sehirler/izmir", component: Izmir },
  { path: "/sehirler/denizli", component: Denizli },
  { path: "/sehirler/adana", component: Adana },
  { path: "/sehirler/edirne", component: Edirne },
  { path: "/sehirler/samsun", component: Samsun },
  { path: "/sanatcilar/melike-sahin", component: MelikeSahin },
  { path: "/sanatcilar/sertab-erener", component: SertabErener },
  { path: "/sanatcilar/mabel-matiz", component: MabelMatiz },
  { path: "/sanatcilar/altin-gun", component: AltinGun },
  { path: "/sanatcilar/ceza", component: Ceza },
  { path: "/sanatcilar/gazapizm", component: Gazapizm },
  { path: "/sanatcilar/gunes", component: Gunes },
  { path: "/sanatcilar/hey-douglas", component: HeyDouglas },
  { path: "/sanatcilar/kofn", component: KOFN },
  { path: "/sanatcilar/madrigal", component: Madrigal },
  { path: "/sanatcilar/mahmut-orhan", component: MahmutOrhan },
  { path: "/sanatcilar/yildiz-tilbe", component: YildizTilbe },
  { path: "/sanatcilar/mert-demir", component: MertDemir },
  { path: "/sanatcilar/yuzyuzeyken-konusuruz", component: YuzyuzeykenKonusuruz },
  { path: "/istanbul/jolly-joker-kartal", component: JollyJokerKartal },
  { path: "/ankara/645kk-kavaklidere", component: KKKavaklidere },
  { path: "/istanbul/zorlu-psm-turkcell", component: ZorluPSMTurkcell },
  { path: "/izmir/jolly-joker-alacati", component: JollyJokerAlacati },
  { path: "/istanbul/turkcell-vadi", component: TurkcellVadi },
  { path: "/ankara/odtu-md-visnelik", component: ODTÜMDVisnelik },
  { path: "/ankara/milyon-performance-hall", component: MilyonPerformanceHall },
  { path: "/izmir/mano-del-sol-cesme", component: ManoDelSolCesme },
  { path: "/izmir/izmir-arena", component: IzmirArena },
  { path: "/izmir/cesme-acikhava-tiyatrosu", component: CesmeAcikhavaTiyatrosu },
  { path: "/istanbul/if-performance-hall-besiktas", component: IFPerformanceHallBesiktas },
  { path: "/ankara/if-armada-avm-acikhava", component: IFArmadaAVMAcikhava },
  { path: "/istanbul/kurucesme-paraf", component: KurucesmeParaf },
  { path: "/izmir/hayal-kahvesi-alacati", component: HayalKahvesiAlacati },
];


const App = () => {
  return (
    <div>
      <ScrollToTopButton />
      <ScrollToTop />
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={<route.component />} />
        ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;

