// components/SocialMedia.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

const SocialMedia = () => {
    return (
        <div className="py-4 flex justify-center space-x-4 bg-gradient-to-r from-purple-700 to-indigo-700 rounded-lg">
            <a href="https://www.twitter.com/konserbiletcom" target="_blank" rel="noopener noreferrer" aria-label="Twitter" className="text-yellow-300 hover:text-yellow-100 transition duration-300">
                <FontAwesomeIcon icon={faTwitter} size="2x" />
            </a>
            <a href="https://www.instagram.com/konserbiletcom" target="_blank" rel="noopener noreferrer" aria-label="Instagram" className="text-yellow-300 hover:text-yellow-100 transition duration-300">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
        </div>
    );
};

export default SocialMedia;
