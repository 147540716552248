// Breadcrumb.js
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";

const Breadcrumb = ({ breadcrumb }) => {
  const itemVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: { opacity: 1, x: 0 },
  };

  const listVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  return (
    <div className="bg-gradient-to-r from-purple-700 to-indigo-700 p-3 rounded">
      <motion.nav
        initial="hidden"
        animate="visible"
        variants={listVariants}
      >
        <ul className="flex">
          {breadcrumb.map((item, index) => (
            <motion.li 
              key={index} 
              className="text-yellow-400"
              variants={itemVariants}
            >
              {item.link ? (
                <Link to={item.link} className="hover:text-yellow-700">
                  {item.name}
                </Link>
              ) : (
                item.name
              )}
              {index < breadcrumb.length - 1 && <span className="mx-2">/</span>}
            </motion.li>
          ))}
        </ul>
      </motion.nav>
    </div>
  );
};

export default Breadcrumb;
