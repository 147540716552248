import React, { useEffect, useState } from 'react';

const ScrollIndicator = () => {
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const progress = (scrollTop / scrollHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const progressBarStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: `${scrollProgress}%`,
    height: '5px',
    backgroundColor: 'purple',
    transition: 'width 0.3s ease-out',
    zIndex: 9999,
  };

  return (
    <div style={{ display: scrollProgress > 0 ? 'block' : 'none' }}>
      <div style={{ width: '100%', height: '5px' }}></div>
      <div style={progressBarStyle}></div>
    </div>
  );
};

export default ScrollIndicator;
