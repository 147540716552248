import React, {useState} from 'react';
import axios from 'axios';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import FaqSection from '../components/FaqSection';
import Breadcrumb from '../components/Breadcrumb';
import SocialMedia from '../components/SocialMedia';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TwitterShareButton, WhatsappShareButton } from 'react-share';
import { faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom'; 

const apiKey = process.env.REACT_APP_RAPIDAPI_KEY;




const Takvim = () => {
  const [displayedConcerts, setDisplayedConcerts] = useState([]);
  const [concerts, setConcerts] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const ITEMS_PER_PAGE = 10;
  const [artistName] = useState('Takvim');
  const [infoMessage, setInfoMessage] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchType, setSearchType] = useState('artist');  // Başlangıç değeri 'artist' olarak ayarlandı
  const [searchLabel, setSearchLabel] = useState('Sanatçı');  // Başlangıç değeri 'Sanatçı' olarak ayarlandı  
  const urlBase = 'https://concerts-artists-events-tracker.p.rapidapi.com';
  const url = `${urlBase}/${searchType}`;  // searchType: artist, location veya venue olacak
  const [concertCountMessage, setConcertCountMessage] = useState('');  // Bu satırı eklediniz
  


  const handleButtonClick = (type) => {
    let typeLabel;
    setSearchType(type);  // Orijinal değeriyle güncelleme
    setConcertCountMessage('');  // concertCountMessage'yi boş bir string'e ayarla
    switch(type) {
      case 'artist':
        typeLabel = 'sanatçı';
        break;
      case 'location':
        typeLabel = 'şehir';
        break;
      case 'venue':
        typeLabel = 'mekan';
        break;
      default:
        typeLabel = '';
    }
    setSearchLabel(typeLabel);  // Yeni durum değişkenini güncelleme
  };
 
  const handleLoadMore = () => {
    const newDisplayedConcerts = concerts.slice(0, displayedConcerts.length + ITEMS_PER_PAGE);
    setDisplayedConcerts(newDisplayedConcerts);
  };

  const seoData = {
    title: `${artistName} Konser Takvimi & Hakkında - KonserBilet`,
    description: `Popüler Şarkıcı ${artistName} konser takvimini hemen görüntüle. ${artistName} hakkında bilinmeyen ve merak edilen soruların yanıtlarını konserbilet'te incele.`,
    ogUrl: "https://konserbilet.com/takvim",
    ogImage: "https://cdn.konserbilet.com/assets/other/anasayfaucretsizkonserlermasaustu.png.webp"
  };
  

  const breadcrumb = [
    { name: 'Ana Sayfa', link: '/' },
    { name: `${artistName}`, link: null } 
  ];


  const formatDate = (dateStr) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    const date = new Date(dateStr);
    return date.toLocaleDateString('tr-TR', options).replace(',', ' -');
  };

  const getCurrentDateFormatted = (daysOffset = 0) => {
    const date = new Date();
    date.setDate(date.getDate() + daysOffset);
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
  const minDate = getCurrentDateFormatted(-1);
  const maxDate = getCurrentDateFormatted(240);

  const fetchConcerts = async () => {
    setConcertCountMessage('');  
    setConcerts([]);
    setDisplayedConcerts([]);
    setInfoMessage(null);  
    setIsSearching(true);  
    
    const options = {
      method: 'GET',
      url: url,
      params: {
        name: searchQuery,  
        minDate: minDate,
        maxDate: maxDate
      },
      headers: {
        'X-RapidAPI-Key': apiKey,
        'X-RapidAPI-Host': 'concerts-artists-events-tracker.p.rapidapi.com'
      }
    };
    
    try {
      const response = await axios.request(options);
      if (response.data.data.length === 0) {
        setInfoMessage(`Maalesef, ${searchQuery} isimli ${searchLabel} için hiçbir konser bulunamadı. Lütfen farklı bir arama yapmayı deneyin.`);
      } else {
        setConcerts(response.data.data);
        setDisplayedConcerts(response.data.data.slice(0, ITEMS_PER_PAGE));
        setConcertCountMessage(`${searchQuery} isimli ${searchLabel} araması için toplamda ${response.data.data.length} adet konser bulundu`);
      }
    } catch (error) {
      console.error(error);
    }
    
    setIsSearching(false);  // Arama bittiğinde
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();  // Formun sayfayı yenilemesini engeller
    fetchConcerts();  // Enter'a basıldığında arama işlemini başlatır
  };

  const customFaqs = [
    {
        question: `Nasıl sanatçı ararım?`,
        answer: `Üst kısımdaki butonlardan 'Sanatçı' seçeneğini tıklayın, ardından arama kutusuna sanatçı ismini yazın ve arama butonuna tıklayın. En iyi arama sonuçlarını almak için sanatçı ismini ve soyismini tam, eksiksiz, hatasız bir şekilde yazmaya özen göstermek faydalı olabilir. `
    },
    {
        question: `Şehre göre nasıl arama yapabilirim?`,
        answer: `Üst kısımdaki butonlardan 'Şehir' seçeneğini tıklayın, ardından arama kutusuna şehir ismini yazın ve arama butonuna tıklayın.`
    },
    {
        question: `Mekana göre nasıl arama yapabilirim?`,
        answer: `Üst kısımdaki butonlardan 'Mekan' seçeneğini tıklayın, ardından arama kutusuna mekan ismini yazın ve arama butonuna tıklayın.`
    },
    {
        question: `Konserler ne zaman gösterilir?`,
        answer: `Arama yaptıktan sonra, eşleşen konserler aşağıdaki bölümde listelenir. Her konserin adı, tarihi ve mekanı gösterilir.`
    },
    {
        question: `Daha fazla konseri nasıl görebilirim?`,
        answer: `Eğer daha fazla konser varsa, sayfanın alt kısmında 'Daha Fazlası' butonu belirecektir. Bu butona tıklayarak diğer konserleri görebilirsiniz.`
    }
];

  const sectionTitle = `Takvim Sayfası Hakkında`;
  
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
<Helmet>
  <title>{seoData.title}</title>
  <meta name="description" content={seoData.description} />
  <meta property="og:title" content={seoData.title} />
  <meta property="og:description" content={seoData.description} />
  <meta property="og:type" content="website" />
  <meta property="og:url" content={seoData.ogUrl} />
  <meta property="og:image" content={seoData.ogImage} />
  <link rel="canonical" href={seoData.ogUrl} />
</Helmet>

      <Header />

      <Breadcrumb breadcrumb={breadcrumb} />
      <div className="bg-gradient-to-r from-purple-700 to-indigo-700">
      <div className="container mx-auto px-4 md:px-10">
    
    <h1 className="text-center text-yellow-400 text-4xl font-extrabold mb-4 pt-4 leading-tight tracking-wider">
    Yaklaşmakta Olan  Konserler
    </h1>
    <div className="flex flex-col md:flex-row items-center justify-between mb-6 mr-5">
      <div className="md:w-1/2 mb-4 md:mb-0">
      <img 
  src={seoData.ogImage} 
  alt={`${artistName} Hakkında`} 
  title={`${artistName} Yaklaşan Konserleri`}
  className="rounded-lg shadow-lg w-full mx-auto lg:max-w-lg" 
/>

      </div>
      <div className="md:w-1/2 flex items-center">
        <p className="text-center text-white text-lg font-medium mx-auto leading-relaxed ml-5">
Bu sayfa ile dilediğin gibi sanatçı, sahne veya şehir belirleyip aradığın konseri bulabilirsin. Kolayca aradığını bulmak için öncelikle ne aradığını belirle!</p>    
      </div>
    </div>
    <div className="flex flex-col items-center mb-6">
    <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0 mb-4">
    <button
  onClick={() => handleButtonClick('artist')}
  className={`py-2 px-4 rounded-full text-xl text-white font-bold transition duration-300 ${searchType === 'artist' ? 'shadow-lg ring-2 ring-yellow-500' : ''}`}
>
  Sanatçı
</button>

<button
  onClick={() => handleButtonClick('location')}
  className={`py-2 px-4 rounded-full text-xl text-white font-bold transition duration-300 ${searchType === 'location' ? 'shadow-lg ring-2 ring-yellow-500' : ''}`}
>
  Şehir
</button>

<button
  onClick={() => handleButtonClick('venue')}
  className={`py-2 px-4 rounded-full text-xl text-white font-bold transition duration-300 ${searchType === 'venue' ? 'shadow-lg ring-2 ring-yellow-500' : ''}`}
>
  Mekan
</button>
      </div>
      <div className="flex w-full justify-center mt-4">
    <form onSubmit={handleFormSubmit} className="flex relative flex-grow items-center outline-none transition duration-300">
      <input 
        type="text" 
        value={searchQuery} 
        onChange={(e) => setSearchQuery(e.target.value)} 
        placeholder={`Dilediğin gibi ${searchLabel} ara`} 
        className="py-6 pl-6 pr-10 w-full rounded-r-full text-lg focus:outline-none flex-grow"  // padding ve font-size değerlerini ayarladım
      />

      <button 
        type="submit"  // Bu buton, formu gönderir
        className="absolute right-0 bg-yellow-500 hover:bg-yellow-600 text-white py-6 px-4 rounded-r-full transition duration-300 text-lg"  // padding ve font-size değerlerini ayarladım
      >
        <i className="fas fa-search"></i>
      </button>
    </form>
  </div>




</div>
<div className="text-center text-white m-4 p-4">
{concertCountMessage}
  {isSearching ? (
    <div>
      <FontAwesomeIcon icon={faSpinner} spin className="p-4"size="5x" /> 
      <p>{`Konserbilet senin için ${searchQuery} isimli ${searchLabel} konserleri arıyor...`}</p>
    </div>
  ) : (
    infoMessage
  )}
</div>
<div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 pt-9 pb-5">

            {displayedConcerts.map((concert, index) => (
              
  <motion.div 
    key={concert.startDate} 
    className="bg-white rounded-lg shadow-md hover:shadow-xl transition-scale duration-300 transform hover:scale-105 relative"  // 'relative' class'ını ekledim
    initial={{ opacity: 0, y: 50 }}  // Başlangıçta görünmez ve biraz aşağıda olacak
    animate={{ opacity: 1, y: 0 }}  // Animasyon sonunda görünür ve doğru konumda olacak
    transition={{ delay: index * 0.1 }}  // Her bir kartın animasyonu bir öncekinden 0.1 saniye sonra başlayacak
  >
    <div className="relative">
        <img 
            src={concert.image || 'https://cdn.konserbilet.com/assets/venue/anasayfasanatcilarmasaustu.png.webp'} 
            alt={concert.name} 
            className="w-full h-50 object-cover rounded-md mb-2 hover:scale-115 transition-scale duration-300" 
        />
        <div className="absolute top-0 left-0 w-full h-40 bg-black opacity-0  rounded-md transition-scale duration-300 "></div>
    </div>
    <h3 className="text-xl font-bold m-2">{concert.name}</h3>
    <p className="text-gray-700 m-2">{concert.location.name}</p>
    <p className="text-gray-700 m-2">{concert.location.address.addressLocality}</p>
    <p className="text-gray-500 m-2">{formatDate(concert.startDate)}</p>
    <div className="mb-2 flex space-x-6 justify-center">
  <TwitterShareButton
    url={window.location.href}
    title={`${concert.name} konseri,  - ${formatDate(concert.startDate)} - ${concert.location.name} sahnesinde! konserbilet.com ile  hemen görüntüle!`}
  >
    <div className="bg-blue-500 text-white p-1 m-1 rounded">
      <FontAwesomeIcon icon={faTwitter} />
    </div>
  </TwitterShareButton>
  <WhatsappShareButton
    url={window.location.href}
    title={`${concert.name} konseri,  - ${formatDate(concert.startDate)} - ${concert.location.name} sahnesinde! konserbilet.com ile  hemen görüntüle!`}
  >
    <div className="bg-green-500 text-white p-1 m-1 rounded">
      <FontAwesomeIcon icon={faWhatsapp} />
    </div>
  </WhatsappShareButton>
</div>
  </motion.div>

          ))}
        </div>

{displayedConcerts.length < concerts.length && (
  <div className="flex justify-center my-4">
    <button className="bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded" onClick={handleLoadMore}>Daha Fazlası</button>
  </div>
)}
        <FaqSection faqs={customFaqs} sectionTitle={sectionTitle} />
        <div className="mt-8">
          <Link to="/" className="text-blue-500 font-bold font-xl hover:text-blue-600">
            &larr; Anasayfa
          </Link>
        </div>
        </div>

        <div>
          <SocialMedia />
        </div>
        </div>
        <Footer />
      </motion.div>
    );
  };

export default Takvim;
