import React from 'react';
import { motion } from 'framer-motion';

const Carousel = () => {
  const benefits = [
    {
      title: 'Dilediğiniz Mekandaki Yaklaşan Tüm Konserler',
      description: 'Konserbilet.com, dilediğiniz mekandaki yaklaşan tüm konserleri tek bir yerde sunar.',
      icon: '🎵', // Örnek emoji ikon
    },
    {
      title: 'Bir Şehrin Yaklaşan Tüm Konserleri',
      description: 'Konserbilet.com, kullanıcılara bir şehirdeki yaklaşan tüm konserleri görüntüleme imkanı sunar.',
      icon: '🎸', // Örnek emoji ikon
    },
    {
      title: 'Özel Konser Fırsatları',
      description: 'Sadece Konserbilet.com kullanıcılarına özel konser fırsatları ve indirimleri.',
      icon: '🎫', // Örnek emoji ikon
    },
    {
      title: 'VIP Konser Deneyimi',
      description: 'VIP kullanıcılara özel konser deneyimi ve ayrıcalıklı hizmetler.',
      icon: '🎤', // Örnek emoji ikon
    },
  ];

  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl text-center font-bold mb-8">
          Konserbilet.com ile Neler Yapabilirsiniz?
        </h2>
        <div className="flex overflow-x-auto whitespace-nowrap md:grid md:grid-cols-4 gap-8">
          {benefits.map((benefit, index) => (
            <div key={index} className="flex-none flex flex-col items-center text-center whitespace-normal md:block mb-3 md:mb-0 mx-auto max-w-xs">
              <motion.div
                className="bg-white rounded-full mx-auto p-4 mb-4 shadow-lg flex justify-center items-center"
                style={{ width: '80px', height: '80px' }} // Icon boyutunu ayarlamak için
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ duration: 0.3 }}
              >
                <span className="text-3xl">{benefit.icon}</span>
              </motion.div>
              <h3 className="text-lg md:text-xl font-bold mb-2">{benefit.title}</h3>
              <p className="text-base md:text-lg text-gray-600">{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Carousel;
