import React from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ScrollIndicator from '../../components/ScrollIndicator';
import BlogSection1 from '../../components/BlogSection1';

const BlogPostPage = () => {
  const pageTitle = 'Barış Manço Kimdir? Hayatı ve Hakkında Bilgiler - KonserBilet';
  const pageDescription = "Tarihe adını altın harflerle yazdırmış Barış Manço ve Barış Manço'nun hayatı hakkında daha fazla bilgi edinmek için yazımızı okuyun!";
  const canonicalUrl = 'https://konserbilet.com/blog/baris-manco-hayati-hakkinda';
  const imageUrl = 'https://cdn.konserbilet.com/assets/blogs/barismanco.webp';
  const author = 'Kaan Tabak';
  const publishDate = '22 Eylül 2023';
  const tableOfContents = [
    { id: 'id4', title: "Barış Manço Nereli? Hayatı ve Kariyer Özeti" },
    { id: 'id5', title: "Alla Beni Pulla Beni ve Diğer Unutulmaz Şarkıları" },
    { id: 'id6', title: "Ayı, Nane Limon Kabuğu ve Diğer Nostaljik Şarkılar" },
    { id: 'id7', title: "Can Bedenden Çıkmayınca ve Diğer Duygusal Şarkılar" },
    { id: 'id8', title: "Bugün Bayram ve Diğer Eğlenceli Şarkılar" },
    { id: 'id9', title: "Halil İbrahim Sofrası ve Diğer Aşk Şarkıları" },
    { id: 'id10', title: "Barış Manço Türkiye'de ve Dünyada Unutulmaz Bir Müzisyen Olarak Anılıyor" },
    { id: 'id11', title: "Barış Manço'nun Ölümünden Sonraki Etkisi ve Anısı" },
    { id: 'id12', title: "Barış Manço'nun Ölümünden Önce Yaptığı Son Çalışmalar ve Projeler" },
    { id: 'id13', title: "Barış Manço: Müziğe ve Hayatına Dair İlginç Gerçekler" },
    { id: 'id14', title: "Barış Manço Ne Zaman Nasıl Öldü?" },
  ];

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Header />
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-3xl md:text-4xl font-bold mb-4">Barış Manço Kimdir? Hayatı ve Hakkında Bilgiler</h1>
            <p className="text-gray-500 text-sm mb-4"> {author} tarafından {publishDate} tarihinde yayınlandı</p>
            <img src={imageUrl} alt={pageTitle} className="w-full h-auto mb-8" />

            <div className="row">
              <div className="w-5/5 pr-8">
                <div className="w-5/5">
                  <h2 className="text-lg font-bold mb-4">İçindekiler</h2>
                  <ul className="space-y-2">
                    {tableOfContents.map((item) => (
                      <li key={item.id}>
<ScrollLink to={item.id} smooth={true} duration={500} offset={-20} className="text-purple-500 hover:text-purple-600">
  {item.title}
</ScrollLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="container mx-auto py-8">
              <div className="prose max-w-none">
<p>Barış Manço, 1999 yılında hayatını kaybetmiş olsa da, hala Türkiye müzik sahnesinin en sevilen isimlerinden biri olarak anılmaktadır. Ölümünden sonra da yayınlanan şarkıları ve sinema filmlerindeki rolleriyle dinleyicilerine ulaşmaya devam etmiştir. Barış Manço hakkında daha fazla bilgi edinmek için yazımızı okuyun!
<br></br>
Barış Manço, Türkiye müzik sahnesinin en sevilen isimlerinden biri olarak anılmaktadır. Ölümünden sonra da etkisini ve anısını koruyan müzisyen, "Gülpembe", "Arkadaşım Eşşek", "Lambaya Püf De" gibi unutulmaz şarkılarıyla tanınır. Barış Manço hakkında daha fazla bilgiye ulaşmak için yazımızı okuyun!
</p>
<br></br>

<h2 className="text-2xl font-bold mt-8" id="id4">Barış Manço Nereli? Hayatı ve Kariyer Özeti</h2> <br></br>
<p>Manço, İstanbul'da doğmuş ve burada da ilk müzik eğitimini almıştır. Özellikle rock müzik ve blues tarzındaki yabancı şarkıları çok sevmiş ve bu tarzlardan ilham alarak kendi şarkılarını yazmaya başlamıştır. Daha sonra, Türk halk müziği ve Türk sanat müziği gibi etnik müzik türlerine de ilgi duymuş ve bu tarzlardan da yararlanarak özgün şarkılar yazmıştır.

1970'lerin popüler müzik gruplarından Kurtalan Ekspres'in vokalisti olarak başladığı müzik kariyeri, solo albümleriyle ve hit şarkılarıyla devam etmiştir. Manço, "Lambaya Püf De" ve "Güzel Bir Gün" gibi unutulmaz şarkıların yazarı ve söz yazarıdır. Ayrıca, sıradışı müzik tarzı ve özgün sözleriyle de dikkat çekmiştir.

</p><br></br>

<br></br>
<div className="flex flex-col items-center">
<iframe
  src="https://open.spotify.com/embed/playlist/37i9dQZF1E4ApXHkOLioUN?utm_source=generator"
  width="100%"
  height="380"
  frameBorder="0"
  allow="encrypted-media"
  title="Spotify Playlist"
  loading="lazy"
></iframe>

      <div className="mt-4">
        <a
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
          href="https://open.spotify.com/embed/playlist/37i9dQZF1E4ApXHkOLioUN?utm_source=generator"
          target="_blank"
          rel="noopener noreferrer"
        >
          Spotify'da Çal
        </a>
      </div>
    </div>
<h2 className="text-2xl font-bold mt-8" id="id5">Alla Beni Pulla Beni ve Diğer Unutulmaz Şarkıları</h2> <br></br>
<p> Özellikle "Alla Beni Pulla Beni" gibi şarkıları, Manço'nun sıradışı müzik tarzını ve özgün sözlerini yansıtmaktadır.

"Alla Beni Pulla Beni", Manço'nun 1974 yılında yayınlanan "2023" adlı albümünde yer alan bir şarkıdır. Bu şarkı, Manço'nun eşsiz müzik tarzını yansıtmaktadır ve güçlü vokalleriyle dinleyicilere unutulmaz bir deneyim sunmaktadır. "Alla Beni Pulla Beni" şarkısı, Türkiye'de ve yurt dışında da büyük bir popülerlik kazanmış ve Manço'nun en sevilen şarkılarından biri olmuştur.

Manço'nun diğer unutulmaz şarkıları arasında ise "Gülpembe", "Arkadaşım Eşşek", "Lambaya Püf De", "Can Bedenden Çıkmayınca", "Bugün Bayram", "Halil İbrahim Sofrası" gibi şarkılar da yer almaktadır. Bu şarkılar, Manço'nun müziğine olan aşkını ve düşüncelerini yansıtmaktadır ve Türkiye müzik sahnesinde unutulmaz bir yer edinmişlerdir. Barış Manço, müziğiyle birlikte sosyal sorumluluk projelerine de önem vermiş ve Türkiye müzik sahnesinde önemli bir yer edinmiştir. Günümüzde de hala yeni nesillere ilham vermeye devam etmektedir.
</p>

<h2 className="text-2xl font-bold mt-8" id="id6">Ayı, Nane Limon Kabuğu ve Diğer Nostaljik Şarkılar</h2> <br></br>
<p>"Ayı", "Nane Limon Kabuğu" gibi nostaljik şarkıları, Manço'nun sıradışı müzik tarzını ve özgün sözlerini yansıtmaktadır. "Ayı", Manço'nun 1979 yılında yayınlanan "Barış Manço" adlı albümünde yer alan bir şarkıdır. Bu şarkı, Manço'nun eşsiz müzik tarzını yansıtmaktadır ve güçlü vokalleriyle dinleyicilere unutulmaz bir deneyim sunmaktadır. "Ayı" şarkısı, Türkiye'de ve yurt dışında da büyük bir popülerlik kazanmış ve Manço'nun en sevilen şarkılarından biri olmuştur.

"Nane Limon Kabuğu" ise Manço'nun 1982 yılında yayınlanan "Barış Manço ve Manço" adlı albümünde yer alan bir şarkıdır. Bu şarkı, Manço'nun müziğine olan aşkını ve düşüncelerini yansıtmaktadır ve Türkiye müzik sahnesinde unutulmaz bir yer edinmiştir.
</p>
<h2 className="text-2xl font-bold mt-8" id="id7">Can Bedenden Çıkmayınca ve Diğer Duygusal Şarkılar</h2> <br></br>
<p>"Can Bedenden Çıkmayınca" şarkısı, Türkiye'de ve yurt dışında da büyük bir popülerlik kazanmış ve Manço'nun en sevilen şarkılarından biri olmuştur. Bu şarkı, Manço'nun müziğine olan aşkını ve düşüncelerini yansıtmaktadır ve Türkiye müzik sahnesinde unutulmaz bir yer edinmiştir.
</p>
<h2 className="text-2xl font-bold mt-8" id="id8">Bugün Bayram ve Diğer Eğlenceli Şarkılar</h2> <br></br>
<p>"Bugün Bayram", Manço'nun 1986 yılında yayınlanan bir şarkıdır. Bu şarkı, Manço'nun eşsiz müzik tarzını yansıtmaktadır ve güçlü vokalleriyle dinleyicilere unutulmaz bir deneyim sunmaktadır. "Bugün Bayram" şarkısı, Türkiye'de ve yurt dışında da büyük bir popülerlik kazanmış ve Manço'nun en sevilen şarkılarından biri olmuştur.

Rahmetli barış manço’nun bayramlarımızın milli şarkısı haline gelen bugün bayram şarkısının temel içeriği hüzünlü bir hikayedir. Şarkının sözlerini incelersek nakarattan önce adam kaybettiği eşine içini döküyor, ona olan özlemini haykırıyor.
“sen gittin gideli
içimde öyle bir sızı var ki
yalnız sen anlarsın
sen şimdi uzakta
cennette meleklerle
bizi düşler ağlarsın...

sen yaz geceleri
yıldızlar içinde ara sıra
bize göz kırparsın
sen soğuk günlerde
kalbimi ısıtan en sıcak anısın...”

nakarata geldiğimizde de adamın bayram için çocuklarını hazırladığını, onları annelerinin mezarına ziyarete götüreceğini, annelerinin mutlu olacağını anlatmaya çalıştığını görüyoruz.

“bugün bayram
erken kalkın cocuklar
giyelim en güzel giysileri
elimizde taze kır çiçekleri
üzmeyelim bugün annemizi

bugün bayram
çabuk olun çocuklar
annemiz bugün bizi bekler
bayramlarda hüzünlenir melekler
gönül alır bu güzel çiçekler...”

</p>
<h2 className="text-2xl font-bold mt-8" id="id9">Halil İbrahim Sofrası ve Diğer Aşk Şarkıları</h2> <br></br>
<p>Ne Haddimize! Albüm, kapağında Kurtalan Ekspres ibaresinin olduğu son Barış Manço albümüdür. Albüm yine "Halil İbrahim Sofrası", "Bal Sultan" ile halk deyişlerine değinen, "Aman Yavaş Aheste" ile funk müziğe yaklaşan, "Kol Düğmeleri" klasiğini barındıran bir albümdür.
</p>

<h2 className="text-2xl font-bold mt-8" id="id10">Barış Manço Türkiye'de ve Dünyada Unutulmaz Bir Müzisyen Olarak Anılıyor</h2> <br></br>
<p>Barış Manço, Türkiye müzik sahnesinde önemli bir yer edinmiş ve birçok unutulmaz şarkıyı bize kazandırmıştır. "Gülpembe", "Arkadaşım Eşşek", "Lambaya Püf De", "Can Bedenden Çıkmayınca", "Bugün Bayram", "Halil İbrahim Sofrası" gibi şarkılarıyla Türkiye'de ve dünyada unutulmaz bir müzisyen olarak anılmaktadır.

Türkiye müzik sahnesine ilk olarak 1960'lı yıllarda adım atmış ve kısa sürede büyük bir başarı elde etmiştir. Özellikle "Gülpembe" şarkısı, Türkiye'de ve yurt dışında büyük bir popülerlik kazanmış ve Manço'nun en sevilen şarkılarından biri olmuştur.
</p>


<h2 className="text-2xl font-bold mt-8" id="id11">Barış Manço'nun Ölümünden Sonraki Etkisi ve Anısı</h2> <br></br>
<p>Manço, 1999 yılında hayatını kaybetmiş olmasına rağmen, ölümünden sonra da etkisini ve anısını koruyan bir müzisyen olmuştur. Ölümünden sonra da yayınlanan şarkıları ve sinema filmlerindeki rolleriyle dinleyicilerine ulaşmaya devam etmiştir.
</p>

<h2 className="text-2xl font-bold mt-8" id="id12">Barış Manço'nun Ölümünden Önce Yaptığı Son Çalışmalar ve Projeler</h2> <br></br>
<p>Efsane sanatçı ölümünden önce müziğe devam etmiş ve birçok şarkı yayınlamıştır. Örneğin, "Halil İbrahim Sofrası" adlı albümü ölümünden önce yayınlanmıştır. Aynı zamanda, sinema filmlerinde de rol almış ve "Yahşi Batı" adlı filmde oynamıştır.

Hayatı boyunca sosyal sorumluluk projelerine önem vermiş ve eğitim ve sağlık alanlarında birçok projeye imza atmıştır. Ölümünden önce de müzik programları yapmış ve dinleyicilerine unutulmaz bir müzik deneyimi yaşatmıştır.

</p>
<br></br>
<figure>
  <img src="https://pbs.twimg.com/media/EdrOK74XkAAGFsD.jpg" alt="Barış Manço" title="Barış Manço Fotoğrafı" />
</figure>

<h2 className="text-2xl font-bold mt-8" id="id13">Barış Manço: Müziğe ve Hayatına Dair İlginç Gerçekler</h2> <br></br>
<p>Manço, çocukluğundan beri müziğe ilgi duymuş ve piyano çalmıştır. Ancak ilk olarak gitar çalmış ve bu sayede müziğe adım atmıştır.

Manço, sadece Türkçe değil, aynı zamanda İngilizce de şarkı söylemiştir. Örneğin, "Alla Beni Pulla Beni" adlı şarkısı İngilizce olarak da yayınlanmıştır.

</p>

<h2 className="text-2xl font-bold mt-8" id="id14">Barış Manço Ne Zaman Nasıl Öldü?</h2> <br></br>
<p>Barış Manço, 1999 yılında hayatını kaybetmiştir. Ölümü, müzisyenin sağlık sorunları nedeniyle olmuştur. 
</p>

              </div>
            </div>
            <div className="mt-8">
              <Link to="/blog" className="text-purple-500 font-medium hover:text-purple-600">
                &larr; Blog
              </Link>
            </div>
          </div>
        </div>
      </section>
      <BlogSection1 />
      <ScrollIndicator />
      <Footer />
    </div>
  );
};

export default BlogPostPage;
