import React, {useState} from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import FaqSection from '../../components/FaqSection';
import Breadcrumb from '../../components/Breadcrumb';
import SocialMedia from '../../components/SocialMedia';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TwitterShareButton, WhatsappShareButton } from 'react-share';
import { faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom'; 

const apiKey = process.env.REACT_APP_RAPIDAPI_KEY;


const Samsun = () => {
  const [displayedConcerts, setDisplayedConcerts] = useState([]);
  const [concerts, setConcerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalConcerts, setTotalConcerts] = useState(0);  
  const ITEMS_PER_PAGE = 10;
  const [locationName] = useState('Samsun');
  const [infoMessage, setInfoMessage] = useState(null);

  const seoData = {
    title: `${locationName} Yaklaşmakta Olan Konser Takvimi - KonserBilet`,
    description: ` ${locationName} konser takvimini hemen görüntüle. ${locationName} şehrindeki konserler hakkında bilinmeyen ve merak edilen soruların yanıtlarını konserbilet'te hemen incele.`,
     ogUrl: "https://konserbilet.com/sehirler/samsun",
    ogImage: "https://cdn.konserbilet.com/assets/location/samsun.webp"
  };
  

  const breadcrumb = [
    { name: 'Ana Sayfa', link: '/' },
    { name: 'Şehirler', link: '/sehirler' },
    { name: `${locationName}`, link: null } 
  ];

  
  const formatDate = (dateStr) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    const date = new Date(dateStr);
    return date.toLocaleDateString('tr-TR', options).replace(',', ' -');
  };

  const getCurrentDateFormatted = (daysOffset = 0) => {
    const date = new Date();
    date.setDate(date.getDate() + daysOffset);
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
  const minDate = getCurrentDateFormatted(-1);
  const maxDate = getCurrentDateFormatted(240);

  const fetchConcerts = async () => {
    setLoading(true);
    setInfoMessage(null);  // bilgilendirme mesajını sıfırlayın
    const options = {
      method: 'GET',
      url: 'https://concerts-artists-events-tracker.p.rapidapi.com/location',
      params: {
        name: 'Samsun',
        minDate: minDate,
        maxDate: maxDate
      },
      headers: {
        'X-RapidAPI-Key': apiKey,
        'X-RapidAPI-Host': 'concerts-artists-events-tracker.p.rapidapi.com'
      }
    };
    try {
      const response = await axios.request(options);
      if (response.data.data.length === 0) {
setInfoMessage(`Maalesef, ${locationName} isimli şehirde yaklaşmakta olan konser bilgisi bulunamadı.\nLütfen başka bir şehir için deneyin veya başka bir zaman yine gelin.`);
      } else {
          setConcerts(response.data.data);
          setDisplayedConcerts(response.data.data.slice(0, ITEMS_PER_PAGE));
          setTotalConcerts(response.data.data.length);
      }
  } catch (error) {
      console.error(error);
  }

  setLoading(false);
};

  const handleLoadMore = () => {
    const newDisplayedConcerts = concerts.slice(0, displayedConcerts.length + ITEMS_PER_PAGE);
    setDisplayedConcerts(newDisplayedConcerts);
  };



  const customFaqs = [
    {
        question: "Samsun'da en popüler konser mekanları neler?",
        answer: "Samsun'da konser mekanı denilince akla ilk olarak Atatürk Kültür Merkezi, Samsun Büyükşehir Belediyesi Sanat Merkezi ve Batı Park Amfitiyatro gelir. Bu mekanlar hem modern teknik altyapıları hem de eşsiz atmosferleriyle unutulmaz konser deneyimleri sunar. Bir sonraki konser için biletlerinizi önceden almayı unutmayın!"
    },
    {
        question: "Samsun konserlerinde park yeri bulabilir miyim?",
        answer: "Samsun'un konser mekanlarında park yeri bulmak genelde kolaydır, fakat popüler etkinliklerde durum değişebilir. Erken gitmek ve iyi bir park yeri kapmak, ya da Samsun'un efektif toplu taşıma sistemini kullanarak trafik stresinden kurtulmak iyi bir fikir olabilir. Kim bilir, belki yolda yeni arkadaşlar edinirsiniz!"
    },
    {
        question: "Samsun konser mekanlarında yemek ve içecek seçeneği var mı?",
        answer: "Samsun konser mekanları, lezzetli atıştırmalıklar ve ferahlatıcı içeceklerle sizi memnun edecek kiosklar ve standlar sunar. Konser öncesi veya sonrası, yakınlardaki restoran ve kafelerde Samsun'un lezzetli mutfağının tadını çıkarabilirsiniz. Ancak, dışarıdan yiyecek ve içecek getirmek genellikle hoş karşılanmaz, bu yüzden yanınızda çay poşeti taşımak yerine konser mekanındaki seçeneklere bir göz atın!"
    },
    {
        question: "Samsun Konserleri Nerede Sahneleniyor?",
        answer: "Samsun konserleri genellikle Atatürk Kültür Merkezi, Batı Park Amfitiyatro gibi yerlerde sahnelenir. Yazın serin akşamlarda açık hava mekanlarında, kışın ise kapalı mekanlarda müziğin ritmine kapılabilirsiniz. Her mevsim Samsun'da müziğin keyfini çıkarabilir, ve belki de bir sonraki favori sanatçınızı keşfedebilirsiniz!"
    },
    {
        question: "Samsun Konserlerine Ulaşım Nasıl Sağlanıyor?",
        answer: "Samsun'un konser mekanlarına ulaşım oldukça kolay ve çeşitli seçenekler sunar. Şehir merkezinden toplu taşıma veya taksi kullanarak konser mekanlarına rahatça ulaşabilir, hatta belki biraz macera arıyorsanız, Samsun'un pitoresk sokaklarında bisiklet turu atabilirsiniz. Müziğin peşinden giderken Samsun'un güzelliklerini keşfedin!"
    },
    {
        question: "Samsun Konserleri Yazın Nerelerde Sahneleniyor?",
        answer: "Yaz aylarında Samsun konserleri genellikle Batı Park Amfitiyatro gibi açık hava mekanlarında sahnelenir. Tarihi ve doğal güzellikleriyle çevrili bu mekanlar, yazın serin akşamlarında müziğin ve doğanın bir araya geldiği eşsiz konserler için idealdir. Güneş battığında müziğin ve Samsun'un eşsiz manzaralarının keyfini çıkarın!"
    },
    {
        question: "Samsun Konserleri Kışın Nerelerde Sahneleniyor?",
        answer: "Kış aylarında, konserler genellikle Atatürk Kültür Merkezi ve Samsun Büyükşehir Belediyesi Sanat Merkezi gibi kapalı mekanlarda sahnelenir. Soğuk kış gecelerinde sıcak bir müzik deneyimi yaşayarak Samsun'un kültürel hayatının tadını çıkarabilir, ve belki de karın altında bir konser deneyimi yaşayabilirsiniz!"
    },
    {
        question: "Samsun Konserleri Süresince Nerede Konaklayabilirim?",
        answer: "Samsun'de konserler süresince pek çok konaklama seçeneği mevcut. Şehir merkezinde uygun fiyatlı oteller, konforlu pansiyonlar ve hatta lüks konaklama seçenekleri sizleri bekliyor. Müziğin ritmine kapıldıktan sonra konforlu bir yatakta dinlenmek için rezervasyonunuzu önceden yapmayı unutmayın. Samsun, hem müziğin hem de konforun tadını çıkarmak için harika bir durak!"
    }
];

  

  
  const sectionTitle = `${locationName} Konserleri Hakkında`;

  

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
<Helmet>
  <title>{seoData.title}</title>
  <meta name="description" content={seoData.description} />
  <meta property="og:title" content={seoData.title} />
  <meta property="og:description" content={seoData.description} />
  <meta property="og:type" content="website" />
  <meta property="og:url" content={seoData.ogUrl} />
  <meta property="og:image" content={seoData.ogImage} />
  <link rel="canonical" href={seoData.ogUrl} />
</Helmet>

      <Header />

      <Breadcrumb breadcrumb={breadcrumb} />
      <div className="bg-gradient-to-r from-purple-700 to-indigo-700">
  <div className="container mx-auto px-10">
    
    <h1 className="text-center text-yellow-400 text-4xl font-extrabold mb-4 pt-4 leading-tight tracking-wider">
    <strong>{locationName}</strong> Konser Takvimi
    </h1>
    <div className="flex flex-col md:flex-row items-center justify-between mb-6 mr-5">
      <div className="md:w-1/2 mb-4 md:mb-0">
      <img 
  src={seoData.ogImage} 
  alt={`${locationName} Hakkında`} 
  title={`${locationName} Yaklaşan Konserleri`}
  className="rounded-lg shadow-lg w-full mx-auto lg:max-w-lg" 
/>

      </div>
      <div className="md:w-1/2 flex items-center">
    <p className="text-white text-lg font-medium mx-auto leading-relaxed ml-5">
        {locationName}'nin benzersiz doğal güzellikleri ve sıcakkanlı insanları, {locationName} şehrini ziyaret edenler için unutulmaz anlar yaratıyor.<br></br><br></br>  Yaklaşmakta olan {locationName} konserleri, {locationName} şehrinin enerjisinin ve kültürel çeşitliliğinin bir göstergesi.  {locationName} konser takvimi ile planlarınızı önceden yapabilir ve biletlerinizi kolayca satın alabilir, arkadaşlarınız ile paylaşabilirsiniz. <br></br><br></br> Konserbilet üzerinden {locationName} şehrindeki yaklaşmakta olan konserleri ve etkinlikleri görüntüleyebilir, böylece bu müzikal şölenin bir parçası olabilirsiniz. Gelin, bu etkinlikler vasıtasıyla {locationName} şehrinin büyülü dünyasını keşfedin ve şehrin ruhunu birlikte hissedelim! 🎉
    </p>
</div>
</div>



<br></br>
<h2 className="text-center text-yellow-400 text-4xl font-extrabold mb-4 leading-tight tracking-wider">Yaklaşmakta Olan <strong>{locationName}</strong> Konserleri</h2>
{infoMessage && (
    <div className="text-center text-white m-4">
        {infoMessage}
    </div>
)}
{!infoMessage && (  // infoMessage null veya undefined ise bu bloğu render et
    !loading && displayedConcerts.length === 0 ? (
        <div className="flex justify-center my-4">
          <button 
              className="bg-yellow-500 m-7 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded inline-flex items-center"
              onClick={fetchConcerts}
          >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5 4a1 1 0 011.993.117L7 4v8.904l4.804-.964a1 1 0 01.521.07l.084.04 4.1 2.05a1 1 0 01.014 1.68l-.014.008L7.416 18a1.001 1.001 0 01-1.03-1.674L11 12.63V5a1 1 0 011-1 1 1 0 011 1v8.528l3.728-.764-3.516-1.76a1 1 0 01-.52-.07l-.082-.041L6 5.871V5a1 1 0 01-1-1z" clipRule="evenodd" />
              </svg>
              Konserleri Görüntüle
          </button>
        </div>
      ) : (
        <>
          {loading ? (
            <div className="flex flex-col justify-center items-center mt-10">
              <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-yellow-300 mb-4" />
              <p className="text-yellow-100 mt-3 font-bold"><strong>{locationName}</strong> Konserleri yükleniyor...</p>
            </div>
          ) : (
            <div className="">
            <p className="text-center text-white m-4 leading-tight tracking-wider">
            <strong className="text-yellow-400">{locationName}</strong> şehrinde yaklaşmakta olan <strong className="text-yellow-400">{totalConcerts} Adet</strong> konseri var! Dilediğin konseri seç, hemen arkadaşın ile paylaş!</p>
           <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 pt-9 pb-5">

            {displayedConcerts.map((concert, index) => (

  <motion.div 
    key={concert.startDate} 
    className="bg-white rounded-lg shadow-md hover:shadow-xl transition-scale duration-300 transform hover:scale-105 relative"  // 'relative' class'ını ekledim
    initial={{ opacity: 0, y: 50 }}  // Başlangıçta görünmez ve biraz aşağıda olacak
    animate={{ opacity: 1, y: 0 }}  // Animasyon sonunda görünür ve doğru konumda olacak
    transition={{ delay: index * 0.1 }}  // Her bir kartın animasyonu bir öncekinden 0.1 saniye sonra başlayacak
  >
    <div className="relative">
        <img 
            src={concert.image || 'https://cdn.konserbilet.com/assets/venue/anasayfasanatcilarmasaustu.png.webp'} 
            alt={concert.name} 
            className="w-full h-50 object-cover rounded-md mb-2 hover:scale-115 transition-scale duration-300" 
        />
        <div className="absolute top-0 left-0 w-full h-40 bg-black opacity-0  rounded-md transition-scale duration-300 "></div>
    </div>
    <h3 className="text-xl font-bold m-2">{concert.name}</h3>
    <p className="text-gray-700 m-2">{concert.location.name}</p>
    <p className="text-gray-700 m-2">{concert.location.address.addressLocality}</p>
    <p className="text-gray-500 m-2">{formatDate(concert.startDate)}</p>
    <div className="mb-2 flex space-x-6 justify-center">
  <TwitterShareButton
    url={window.location.href}
    title={`${concert.name} konseri,  - ${formatDate(concert.startDate)} - ${concert.location.name} sahnesinde! konserbilet.com ile  hemen görüntüle!`}
  >
    <div className="bg-blue-500 text-white p-1 rounded">
      <FontAwesomeIcon icon={faTwitter} />
    </div>
  </TwitterShareButton>
  <WhatsappShareButton
    url={window.location.href}
    title={`${concert.name} konseri,  - ${formatDate(concert.startDate)} - ${concert.location.name} sahnesinde! konserbilet.com ile  hemen görüntüle!`}
  >
    <div className="bg-green-500 text-white p-1 rounded">
      <FontAwesomeIcon icon={faWhatsapp} />
    </div>
  </WhatsappShareButton>
</div>


  </motion.div>
))}
                            </div>

              </div>
            )}
            {displayedConcerts.length < concerts.length && (
              <div className="flex justify-center m-4">
                <button className="bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded " onClick={handleLoadMore}>Daha Fazlası</button>
              </div>
            )}
    </> 
          )
          
    )}
    

<br></br>


    <figure>
  <img 
    className="rounded-lg shadow-lg w-full mx-auto lg:max-w-lg" 
    src="https://iaysr.tmgrup.com.tr/5c0960/780/411/0/125/1024/663?u=https://iysr.tmgrup.com.tr/2022/09/14/karadenizin-incisi-samsun-1663171659842.jpeg" 
    alt={locationName + " Hakkında"} // locationName değişkenini bir string ile birleştirerek kullanıyoruz
    title={locationName + " Konserleri"} // aynı şekilde burada da
    loading="lazy"
  />
  <figcaption className="mt-2 text-sm text-white text-center">{locationName}</figcaption>
</figure>

      <FaqSection faqs={customFaqs} sectionTitle={sectionTitle} />
     
      <div className="mt-8">
              <Link to="/sehirler" className="text-blue-500 font-bold font-xl hover:text-blue-600">
                &larr; Şehirler
              </Link>
            </div>
     
      <div>      <SocialMedia />   </div>
        </div>
      </div>
      <Footer />
      </motion.div>
  );
};

export default Samsun;
