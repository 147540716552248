import React from 'react';
import { Link } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';

const Footer = () => {
  const categories = [
    {
      id: 1,
      title: 'Konserler',
      url: '/takvim', 
      links: [
        { id: 1, title: 'Takvim', url: '/takvim' },
        { id: 2, title: 'Sahneler', url: '/sahneler' },
        { id: 3, title: 'Sanatçılar', url: '/sanatcilar' },
      ],
    },
    {
      id: 2,
      title: 'Şehirler',
      url: '/sehirler', 
      links: [
        { id: 1, title: 'İstanbul', url: '/sehirler/istanbul' },
        { id: 2, title: 'Ankara', url: '/sehirler/ankara' },
        { id: 3, title: 'İzmir', url: '/sehirler/izmir' },
      ],
    },
    {
      id: 3,
      title: 'Blog',
      url: '/blog', 
      links: [
        { id: 1, title: 'Rana Türkyılmaz', url: '/blog/rana-turkyilmaz-kimdir' },
        { id: 2, title: 'Tribute Konser', url: '/blog/tribute-konser-ne-demek' },
        { id: 3, title: 'Sarı Gelin Türküsü', url: '/blog/sari-gelin-turkusunun-hikayesi' },
      ],
    },
    {
      id: 4,
      title: 'Sahneler',
      url: '/sahneler', 
      links: [
        { id: 1, title: 'Harbiye Açıkhava', url: '/istanbul/harbiye-acikhava' },
        { id: 2, title: 'ODTU MD Vişnelik', url: '/ankara/odtu-md-visnelik' },
        { id: 3, title: 'İzmir Arena', url: '/izmir/izmir-arena' },
      ],
    },
    {
      id: 5,
      title: 'Diğer',
      url: '/', 
      links: [
        { id: 1, title: 'SSS', url: '/SSS' },
        { id: 2, title: 'İletişim', url: '/iletisim' },
        { id: 3, title: 'Sitemap', url: '/sitemap.xml' },
      ],
    },
    {
      id: 6,
      title: 'Sosyal Medya',
      url: '/iletisim', 
      links: [
        { id: 1, title: 'Instagram', url: 'https://www.instagram.com/konserbiletcom/' },
        { id: 2, title: 'Twitter', url: 'https://twitter.com/konserbiletcom' },
        { id: 3, title: 'TikTok', url: 'https://www.tiktok.com/@konserbilet' },
      ],
    },
  ];

  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto px-2">
        <div className="grid grid-cols-2 md:grid-cols-6 gap-4">
          {categories.map((category) => (
            <div key={category.id}>
              <h3 className="text-xl font-bold mb-5">
                <Link to={category.url} className="text-white">{category.title}</Link>
              </h3>
              <ul>
                {category.links.map((link) => (
                  <li key={link.id}>
                    <Link to={link.url} className="text-gray-400 hover:text-white transition-colors mb-4 duration-300 ease-in-out">
                      {link.title}
                      <FiChevronRight className="inline-block ml-1 m-2" />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="text-center mt-8">
          <p className="text-gray-400 mb-4">© 2023 Konserbilet - All rights reserved.</p>
          <p>
            Made with <span role="img" aria-label="Love">❤️</span> by KM
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;




