import React, {useState} from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import FaqSection from '../../components/FaqSection';
import Breadcrumb from '../../components/Breadcrumb';
import SocialMedia from '../../components/SocialMedia';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TwitterShareButton, WhatsappShareButton } from 'react-share';
import { faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom'; 

const apiKey = process.env.REACT_APP_RAPIDAPI_KEY;


const Ceza = () => {
  const [displayedConcerts, setDisplayedConcerts] = useState([]);
  const [concerts, setConcerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalConcerts, setTotalConcerts] = useState(0);  
  const ITEMS_PER_PAGE = 10;
  const [artistName] = useState('Ceza');
  const [infoMessage, setInfoMessage] = useState(null);

  const seoData = {
    title: `${artistName} Konser Takvimi & Hakkında - KonserBilet`,
    description: `Popüler Şarkıcı ${artistName} konser takvimini hemen görüntüle. ${artistName} hakkında bilinmeyen ve merak edilen soruların yanıtlarını konserbilet'te incele.`,
     ogUrl: "https://konserbilet.com/sanatcilar/ceza",
    ogImage: "https://cdn.konserbilet.com/assets/artist/ceza.webp"
  };
  

  const breadcrumb = [
    { name: 'Ana Sayfa', link: '/' },
    { name: 'Sanatçılar', link: '/sanatcilar' },
    { name: `${artistName}`, link: null } 
  ];


  const [unknowns] = useState([
    {
      id: 1,
      content: "Ceza aslında müthiş bir doğa tutkunudur! Karayoluyla şehirler arası yolculuklar yapmayı tercih eder çünkü bu ona gittiği yerleri daha iyi tanıma ve yol boyunca çeşitli müzikler dinleme fırsatı verir. Kim bilir, belki bir sonraki hit şarkısına ilham veren manzaraları bu yolculuklarda keşfediyor!"
    },
    {
      id: 2,
      content: "Ceza'nın müzik zevki sadece rap ile sınırlı değil, rock'tan elektroniğe, indie'den etnik müziğe kadar geniş bir yelpazede seyahat playlist'leri var. Bu çeşitlilik, onun şarkılarındaki zengin lirik dokuların ve akıcı ritimlerin sırrı olabilir mi ne dersiniz?"
    },
    {
      id: 3,
      content: "Herkesin onu sert rap şarkılarıyla tanıdığı doğru ama Ceza sahneden inince sakin bir tarih meraklısına dönüşüyor. Programı uygun olduğunda, bulunduğu bölgenin tarihi yerlerini gezmeyi ihmal etmiyor. Belki de bu kültürel birikim, şarkı sözlerine yansıyan derin anlamların kaynağıdır."
    },
    {
      id: 4,
      content: "Ceza, müzik sektöründeki dinlenme sayılarına aldanmıyor. Onun için asıl önemli olan, müziğin kalitesi ve dinleyicinin samimiyeti. İyi müzik, gerçek hayranlar tarafından desteklendiğinde, sayılar ne olursa olsun, Ceza için başarı zaten garantilenmiş demektir."
    },
    {
      id: 5,
      content: "Rap'in sadece bir müzik türü olmadığını, aynı zamanda bir hareket ve tutum olduğunu savunan Ceza, şarkılarında toplumsal konulara dokunmayı ihmal etmiyor. Ancak her şarkısında eleştiri yapmak zorunda olmadığını, yaratıcılığının da özgür olması gerektiğini düşünüyor. Belki de bu yüzden her Ceza şarkısı, dinleyenleri farklı bir yolculuğa çıkarıyor."
    }
  ]);
  

  const formatDate = (dateStr) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    const date = new Date(dateStr);
    return date.toLocaleDateString('tr-TR', options).replace(',', ' -');
  };

  const getCurrentDateFormatted = (daysOffset = 0) => {
    const date = new Date();
    date.setDate(date.getDate() + daysOffset);
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
  const minDate = getCurrentDateFormatted(-1);
  const maxDate = getCurrentDateFormatted(240);

  const fetchConcerts = async () => {
    setLoading(true);
    setInfoMessage(null);  // bilgilendirme mesajını sıfırlayın
    const options = {
      method: 'GET',
      url: 'https://concerts-artists-events-tracker.p.rapidapi.com/artist',
      params: {
        name: 'Ceza',
        minDate: minDate,
        maxDate: maxDate
      },
      headers: {
        'X-RapidAPI-Key': apiKey,
        'X-RapidAPI-Host': 'concerts-artists-events-tracker.p.rapidapi.com'
      }
    };
    try {
      const response = await axios.request(options);
      if (response.data.data.length === 0) {
setInfoMessage(`Maalesef, ${artistName} isimli sanatçının yaklaşmakta olan konser bilgisi bulunamadı.\nLütfen başka bir sanatçı için deneyin veya başka bir zaman yine gelin.`);
      } else {
          setConcerts(response.data.data);
          setDisplayedConcerts(response.data.data.slice(0, ITEMS_PER_PAGE));
          setTotalConcerts(response.data.data.length);
      }
  } catch (error) {
      console.error(error);
  }

  setLoading(false);
};

  const handleLoadMore = () => {
    const newDisplayedConcerts = concerts.slice(0, displayedConcerts.length + ITEMS_PER_PAGE);
    setDisplayedConcerts(newDisplayedConcerts);
  };

  const customFaqs = [
    {
      question: `${artistName} kaç yaşında?`,
      answer: `Ceza, yani gerçek adıyla Bilgin Özçalkan, 31 Aralık 1976 doğumlu ve şu anda 46 yaşında! Hala sahnelerin tozunu attırıyor! 🎤✨`
    },
    {
      question: `${artistName} ne burcu?`,
      answer: `Bizim Ceza, tam bir oğlak burcu! Disiplinli, kararlı ve her daim müziğine odaklı! 🐐🎵`
    },
    {
      question: `${artistName} Instagram hesabı nedir?`,
      answer: `Ceza'nın Instagram kullanıcı adı henüz bilinmiyor ama onu sosyal medyada bulup takip etmek istersen, bir arama yaparak en son paylaşımlarını keşfedebilirsin! 📱🔍`
    },
    {
      question: `${artistName} Twitter hesabı nedir?`,
      answer: `Ceza'nın Twitter kullanıcı adı henüz bilinmiyor ama bir arama yaparak onun en ateşli tweetlerini bulabilirsin! 🐦🔥`
    },
    {
      question: `${artistName} ne tür müzik yapıyor?`,
      answer: `Ceza, rap ve hip hop'un Türkiye'deki efsane isimlerinden! Sözleriyle düşündürüp, ritmiyle coşturuyor! 🎧💥`
    },
    {
      question: `${artistName} hangi bölümden mezun olmuştur?`,
      answer: `Ceza, resmi bir üniversite eğitimi almamış olabilir ama müzikteki başarısıyla, hayatın bizzat kendisinden mezun bir dahi! 🎓👊`
    },
    {
      question: `${artistName}’in en popüler şarkıları nelerdir?`,
      answer: `Ceza'nın "Suspus", "Holocaust" ve "Yerli Plaka" gibi parçaları adeta listeleri salladı, hala da sallamaya devam ediyor! Müzik ziyafeti için bu şarkıları dinlemelisin! 🚀🎶`
    },
];


  
  const sectionTitle = `${artistName} Hakkında`;

  

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
<Helmet>
  <title>{seoData.title}</title>
  <meta name="description" content={seoData.description} />
  <meta property="og:title" content={seoData.title} />
  <meta property="og:description" content={seoData.description} />
  <meta property="og:type" content="website" />
  <meta property="og:url" content={seoData.ogUrl} />
  <meta property="og:image" content={seoData.ogImage} />
  <link rel="canonical" href={seoData.ogUrl} />
</Helmet>

      <Header />

      <Breadcrumb breadcrumb={breadcrumb} />
      <div className="bg-gradient-to-r from-purple-700 to-indigo-700">
  <div className="container mx-auto px-10">
    
    <h1 className="text-center text-yellow-400 text-4xl font-extrabold mb-4 pt-4 leading-tight tracking-wider">
    <strong>{artistName}</strong> Konser Takvimi
    </h1>
    <div className="flex flex-col md:flex-row items-center justify-between mb-6 mr-5">
      <div className="md:w-1/2 mb-4 md:mb-0">
      <img 
  src={seoData.ogImage} 
  alt={`${artistName} Hakkında`} 
  title={`${artistName} Yaklaşan Konserleri`}
  className="rounded-lg shadow-lg w-full mx-auto lg:max-w-lg" 
/>

      </div>
      <div className="md:w-1/2 flex items-center">
        <p className="text-white text-lg font-medium mx-auto leading-relaxed ml-5">
        Son zamanların popüler sanatçısı <strong>{artistName}</strong> konserlerini <strong>{artistName} konser takviminden</strong> göz atabilirsiniz. <br></br><br></br> Sahnede parlayan <strong>{artistName}</strong> isimli sanatçıya dair merak ettiğiniz soruların cevaplarını aşağıda bulabilirsiniz. <strong>{artistName}</strong> hakkında bilinmeyen bilgileri ve yaklaşmakta olan konserleri konserbilet ile anında görüntüle! <br></br> <br></br>Gelin, onun büyülü dünyasına birlikte adım atalım!</p>    
      </div>
    </div>

<br></br>
<h2 className="text-center text-yellow-400 text-4xl font-extrabold mb-4 leading-tight tracking-wider">Yaklaşmakta Olan <strong>{artistName}</strong> Konserleri</h2>
{infoMessage && (
    <div className="text-center text-white m-4">
        {infoMessage}
    </div>
)}
{!infoMessage && (  // infoMessage null veya undefined ise bu bloğu render et
    !loading && displayedConcerts.length === 0 ? (
        <div className="flex justify-center my-4">
          <button 
              className="bg-yellow-500 m-7 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded inline-flex items-center"
              onClick={fetchConcerts}
          >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5 4a1 1 0 011.993.117L7 4v8.904l4.804-.964a1 1 0 01.521.07l.084.04 4.1 2.05a1 1 0 01.014 1.68l-.014.008L7.416 18a1.001 1.001 0 01-1.03-1.674L11 12.63V5a1 1 0 011-1 1 1 0 011 1v8.528l3.728-.764-3.516-1.76a1 1 0 01-.52-.07l-.082-.041L6 5.871V5a1 1 0 01-1-1z" clipRule="evenodd" />
              </svg>
              Konserleri Görüntüle
          </button>
        </div>
      ) : (
        <>
          {loading ? (
            <div className="flex flex-col justify-center items-center mt-10">
              <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-yellow-300 mb-4" />
              <p className="text-yellow-100 mt-3 font-bold"><strong>{artistName}</strong> Konserleri yükleniyor...</p>
            </div>
          ) : (
            <div className="">
            <p className="text-center text-white m-4 leading-tight tracking-wider">
            <strong className="text-yellow-400">{artistName}</strong> isimli sanatçının yaklaşmakta olan <strong className="text-yellow-400">{totalConcerts} Adet</strong> konseri var! Dilediğin konseri seç, hemen arkadaşın ile paylaş!</p>
           <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 pt-9 pb-5">

            {displayedConcerts.map((concert, index) => (

  <motion.div 
    key={concert.startDate} 
    className="bg-white rounded-lg shadow-md hover:shadow-xl transition-scale duration-300 transform hover:scale-105 relative"  // 'relative' class'ını ekledim
    initial={{ opacity: 0, y: 50 }}  // Başlangıçta görünmez ve biraz aşağıda olacak
    animate={{ opacity: 1, y: 0 }}  // Animasyon sonunda görünür ve doğru konumda olacak
    transition={{ delay: index * 0.1 }}  // Her bir kartın animasyonu bir öncekinden 0.1 saniye sonra başlayacak
  >
    <div className="relative">
        <img 
            src={concert.image || 'https://cdn.konserbilet.com/assets/venue/anasayfasanatcilarmasaustu.png.webp'} 
            alt={concert.name} 
            className="w-full h-50 object-cover rounded-md mb-2 hover:scale-115 transition-scale duration-300" 
        />
        <div className="absolute top-0 left-0 w-full h-40 bg-black opacity-0  rounded-md transition-scale duration-300 "></div>
    </div>
    <h3 className="text-xl font-bold m-2">{concert.name}</h3>
    <p className="text-gray-700 m-2">{concert.location.name}</p>
    <p className="text-gray-700 m-2">{concert.location.address.addressLocality}</p>
    <p className="text-gray-500 m-2">{formatDate(concert.startDate)}</p>
    <div className="mb-2 flex space-x-6 justify-center">
  <TwitterShareButton
    url={window.location.href}
    title={`${concert.name} konseri,  - ${formatDate(concert.startDate)} - ${concert.location.name} sahnesinde! konserbilet.com ile  hemen görüntüle!`}
  >
    <div className="bg-blue-500 text-white p-1 rounded">
      <FontAwesomeIcon icon={faTwitter} />
    </div>
  </TwitterShareButton>
  <WhatsappShareButton
    url={window.location.href}
    title={`${concert.name} konseri,  - ${formatDate(concert.startDate)} - ${concert.location.name} sahnesinde! konserbilet.com ile  hemen görüntüle!`}
  >
    <div className="bg-green-500 text-white p-1 rounded">
      <FontAwesomeIcon icon={faWhatsapp} />
    </div>
  </WhatsappShareButton>
</div>


  </motion.div>
))}
                            </div>

              </div>
            )}
            {displayedConcerts.length < concerts.length && (
              <div className="flex justify-center m-4">
                <button className="bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded " onClick={handleLoadMore}>Daha Fazlası</button>
              </div>
            )}
    </> 
          )
          
    )}
    

<br></br>



<div className="p-8">
      <h3 className="text-yellow-400 text-3xl font-extrabold mb-4 tracking-wider text-xl mb-4"><strong>{artistName}</strong> Hakkında Bilinmeyen Şeyler</h3>
      <div className="flex flex-col">
        {unknowns.map(unknown => (
          <div key={unknown.id} className="flex items-start mb-8">
            <div className="flex-shrink-0">
              <div className="bg-yellow-400 text-white text-xl font-bold rounded-full w-12 h-12 flex items-center justify-center">
                {unknown.id}
              </div>
            </div>
            <p className="ml-6 text-white">{unknown.content}</p>
          </div>
        ))}
      </div>
    </div>

    <figure>
  <img 
    className="rounded-lg shadow-lg w-full mx-auto lg:max-w-lg" 
    src="https://pbs.twimg.com/media/F7qzcbFWwAAOCSZ.jpg" 
    alt={artistName + " Hakkında"} // artistName değişkenini bir string ile birleştirerek kullanıyoruz
    title={artistName + " Konserleri"} // aynı şekilde burada da
    loading="lazy"
  />
  <figcaption className="mt-2 text-sm text-white-500 text-center">{artistName}</figcaption>
</figure>

      <FaqSection faqs={customFaqs} sectionTitle={sectionTitle} />
     
      <div className="mt-8">
              <Link to="/sanatcilar" className="text-blue-500 font-bold font-xl hover:text-blue-600">
                &larr; Sanatçılar
              </Link>
            </div>
     
      <div>      <SocialMedia />   </div>
        </div>
      </div>
      <Footer />
      </motion.div>
  );
};

export default Ceza;
