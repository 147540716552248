import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo.png';
import { motion } from 'framer-motion';

const sidebarVariants = {
    open: { x: 0 },
    closed: { x: '100%' },
};

const pages = [
  { name: 'Blog', path: '/blog' },
  { name: 'Sahneler', path: '/sahneler' },
  { name: 'Sanatçılar', path: '/sanatcilar' },
  { name: 'Şehirler', path: '/sehirler' },
  { name: 'Takvim', path: '/takvim' }
];

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const sidebarRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!sidebarRef.current.contains(event.target)) {
                if (isOpen) setIsOpen(false);
            }
        };

        const handleSwipe = (event) => {
            const touch = event.changedTouches[0];
            if (touch.pageX > window.innerWidth / 2) {
                if (isOpen) setIsOpen(false);
            }
        };

        window.addEventListener('touchend', handleSwipe);
        window.addEventListener('mousedown', handleOutsideClick);

        return () => {
            window.removeEventListener('touchend', handleSwipe);
            window.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);

    return (
        <div className="header-container">
        <header className="bg-gradient-to-r from-purple-700 to-indigo-700 text-white py-4">
            <div className="container mx-auto flex items-center justify-between">
                <div className="flex items-center justify-between w-full">
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <NavLink to="/">
                            <img src={logo} alt="Logo" className="w-50 h-23" />
                        </NavLink>
                    </motion.div>
                    <div className="block lg:hidden"> 
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="text-white focus:outline-none p-2 rounded hover:bg-indigo-600 ml-auto">
                            <svg
                                    className={`w-6 h-6 transition-transform transform ${isOpen ? 'rotate-90' : ''}`}
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M4 6h16M4 12h16m-7 6h7"
                                    />
                                </svg>
                            </button>
                        </div>
                        <nav className="hidden lg:flex space-x-4">
                            {pages.map((page, index) => (
                                <NavLink
                                    key={index}
                                    to={page.path}
                                    activeClassName="bg-indigo-500"
                                    className="py-2 px-4 rounded-lg hover:bg-indigo-600"
                                >
                                    {page.name}
                                </NavLink>
                            ))}
                        </nav>
                    </div>
                </div>
            </header>
            <motion.nav
                ref={sidebarRef}
                className="fixed top-0 right-0 w-2/5 max-w-md bg-indigo-700 text-white h-full shadow-lg lg:hidden flex flex-col z-50"
                initial="closed"
                animate={isOpen ? "open" : "closed"}
                variants={sidebarVariants}
                transition={{ type: 'spring', stiffness: 300 }}
            >
                <button
                    onClick={() => setIsOpen(false)}
                    className="text-white absolute top-6 left-4">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
                </button>
                <ul className="flex flex-col text-right  space-y-4 p-4 flex-grow items-end">
    {pages.map((page, index) => (
        <motion.li
            key={index}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ duration: 0.3 }}
            className="py-2 px-4 rounded-lg hover:bg-indigo-600 text-right"
        >
            <NavLink 
                to={page.path}  // Manually specified path is used here
                activeClassName="bg-indigo-500"
            >
                <button>
                    {page.name}
                </button>
            </NavLink>
        </motion.li>
    ))}
</ul>

            </motion.nav>
            <div className="border-t-4 border-yellow-500 shadow-md "></div>
        </div>
    );
};

export default Header;
