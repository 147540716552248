import React, { useState } from 'react';

const FaqSection = (props) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section className="w-full">
      <div className="p-8 w-full">
        <h2 className="text-3xl md:text-4xl text-center font-bold text-yellow-400 mb-8">{props.sectionTitle}</h2>
        <div className="space-y-6">
          {props.faqs.map((faq, index) => (
            <div
              key={index}
              className={`bg-white rounded-lg shadow-2xl overflow-hidden ${
                activeIndex === index ? 'border-l-8 border-purple-600' : 'border-l-8 border-purple-500'
              }`}
            >
              <button
                className={`flex items-center justify-between w-full p-4 focus:outline-none transition-colors duration-300 ease-in-out ${
                  activeIndex === index ? 'bg-purple-600 text-white' : 'text-black'
                }`}
                onClick={() => toggleAccordion(index)}
              >
                <h3
                  className="text-lg md:text-xl font-bold"
                  style={{ width: '90%', textAlign: 'left' }}
                >
                  {faq.question}
                </h3>
                <svg
                  className={`w-5 h-5 transform ${
                    activeIndex === index ? 'rotate-180' : 'rotate-0'
                  } transition-transform duration-300 ease-in-out`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </button>
              {activeIndex === index && (
                <div className="p-4">
                  <p className="text-base md:text-lg text-gray-700">{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
